import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { EmailService } from 'src/app/services/email.service';

@Component({
  selector: 'app-fax',
  templateUrl: './fax.component.html',
  styleUrls: ['./fax.component.css'],
})
export class FaxComponent implements OnInit {
  isSuccess = false;
  FaxImage: string;

  constructor(private fb: FormBuilder, private emailService: EmailService) {}
  ngOnInit(): void {}

  Frm = this.fb.group({
    FaxNumber: ['', Validators.required],
    Subject: [''],
    Template: [''],
  });

  get FaxNumber() {
    return this.Frm.get('FaxNumber');
  }

  get Subject() {
    return this.Frm.get('Subject');
  }

  get Template() {
    return this.Frm.get('Template');
  }

  image(e) {
    let formData = new FormData();
    let files = e.target.files;
    this.FaxImage = files[0].name;
    // formData.append('file', files[0], files[0].name);
  }
  formSubmit() {
    let subject = this.Frm.value['Subject'];
    let faxNumber = this.Frm.value['FaxNumber'];
    let template = this.Frm.value['Template'];

    let FaxNumberPayload = {
      Subject: subject,
      FaxNumber: faxNumber,
      Template: template ? template : this.FaxImage,
    };
    this.emailService.sendGenericFax(FaxNumberPayload).subscribe((res) => {
      this.Frm.reset();
      this.isSuccess = true;
    });
  }
}
