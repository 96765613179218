<div class="container mt-5">
    <h2>Import Patients</h2>

    <div class="col-lg regFrm mat-elevation-z8">
        <div class="row">
            <div class="col-lg-10">
                <div class="d-flex justify-content-end m-2">
                    <form (ngSubmit)="uploadFile()">
                        <input type="file" class="formControl" (change)="onFileSelected($event)"
                            accept="application/json">
                        <button type="submit btn btn-primary" mat-raised-button color="primary">Upload Patients</button>
                    </form>

                </div>
            </div>
        </div>
    </div>
</div>
<hr>
<h2>Test</h2>
<div>
    <input type="file" (change)="onFileChange($event)" accept=".xlsx, .xls">
</div>

<div *ngIf="excelData">
    <h3>Uploaded Data:</h3>
    <pre>{{ excelData | json }}</pre>
</div>
<hr>
<div class="container mt-5">
    <h2>Import Doctors</h2>

    <div class="col-lg regFrm mat-elevation-z8">
        <div class="row">

            <div class="col-lg-10">
                <div class="d-flex justify-content-end m-2">
                    <form (ngSubmit)="uploadFileDoctors()">
                        <input type="file" class="formControl" (change)="selectedDoctorFile($event)"
                            accept="application/json">
                        <button type="submit btn btn-primary" mat-raised-button color="primary">Upload Doctors</button>
                    </form>

                </div>
            </div>
        </div>
    </div>
</div>
<hr>



<div class="container mt-5">
    <h2>Import Radiology Members</h2>

    <div class="col-lg regFrm mat-elevation-z8">
        <div class="row">

            <div class="col-lg-10">
                <div class="d-flex justify-content-end m-2">
                    <form (ngSubmit)="uploadFileRadilogyMembers()">
                        <input type="file" class="formControl" (change)=" selectedRadiologyMemberFile($event)"
                            accept="application/json">
                        <button type="submit btn btn-primary" mat-raised-button color="primary">Upload
                            Radiology Members</button>
                    </form>

                </div>
            </div>
        </div>
    </div>
</div>
<hr>
<div class="container mt-5">
    <h2>Import PatientVisits</h2>

    <div class="col-lg regFrm mat-elevation-z8">
        <div class="row">

            <div class="col-lg-10">
                <div class="d-flex justify-content-end m-2">
                    <form (ngSubmit)="uploadPatientVisit()">
                        <input type="file" class="formControl" (change)=" selectedPatientVisitFile($event)"
                            accept="application/json">
                        <button type="submit btn btn-primary" mat-raised-button color="primary">Upload
                            PatientVisits</button>
                    </form>

                </div>
            </div>
        </div>
    </div>
</div>
<hr>