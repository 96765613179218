import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-new-orders',
  templateUrl: './new-orders.component.html',
  styleUrls: ['./new-orders.component.css'],
})
export class NewOrdersComponent implements OnInit {
  id = 0;

  constructor(private route: ActivatedRoute, private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
  }
  labFrm = this.fb.group({});

  logIndex(e) {}
}
