import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Subject } from 'rxjs';
import { Doctor } from 'src/app/Models/doctor.model';
import { DoctorReport } from 'src/app/Models/doctorreport.model';
import { Patient } from 'src/app/Models/patient.model';
import { DoctorReportService } from 'src/app/services/doctor-report.service';
import { DoctorService } from 'src/app/services/doctor.service';
import { PatientService } from 'src/app/services/patient.service';
import { VoiceRecognitionService } from 'src/app/services/voice-recognition.service';

@Component({
  selector: 'app-update-doctor-report',
  templateUrl: './update-doctor-report.component.html',
  styleUrls: ['./update-doctor-report.component.css'],
})
export class UpdateDoctorReportComponent implements OnInit {
  id = 0;
  reportList: DoctorReport[] = [];
  isSuccess = false;
  helper = new JwtHelperService();
  myToken = window.localStorage.getItem('token');
  UserId: number = 0;
  doctor: Doctor;
  listening = false;
  patient: Patient;
  patientId: any;

  constructor(
    private fb: UntypedFormBuilder,
    private doctorReportService: DoctorReportService,
    private route: ActivatedRoute,
    public service: VoiceRecognitionService,
    private doctorService: DoctorService,
    private patientService: PatientService,
    private router: Router
  ) {
    this.service.init();
  }
  private unsubscribe = new Subject<void>();
  ngOnInit(): void {
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.UserId = parseInt(decodedToken.nameid);

    this.route.params.subscribe((res) => {
      this.id = res['id'];
      this.patientId = res['PatientId'];
    });

    this.doctorReportService.getDoctorReportById(this.id).subscribe((res) => {
      this.Frm.patchValue(res);
    });
    // this.getPatient(this.patientId);
    this.getDoctor(this.UserId);
    if (window.close) {
      this.formSubmit();
    }
  }
  Frm = this.fb.group({
    ReportName: ['', Validators.required],
    ReportDescription: ['', Validators.required],
  });
  getReportName() {
    return this.Frm.get('ReportName');
  }

  getReportDescription() {
    return this.Frm.get('ReportDescription');
  }

  tabChanged(event: MatTabChangeEvent): void {
    if (event.index === 0) {
      this.getReportName();
    }
  }

  startService() {
    this.service.start();
    this.listening = true;
  }

  stopService() {
    this.service.stop();
    this.listening = false;
  }

  onSubjectiveChange(e) {
    this.service.message1 = e.target.value;
  }

  onSubjectiveBlur() {
    this.stopService();
  }

  getDoctor(id) {
    this.doctorService.getDoctorById(id).subscribe((res) => {
      this.doctor = res;
    });
  }
  getPatient(id) {
    this.patientService.getPatientById(id).subscribe((res) => {
      this.patient = res;
    });
  }
  formSubmit() {
    let ReportName = this.Frm.value['ReportName'];
    let reportDescription = this.Frm.value['ReportDescription'];

    let body = {
      PatientId: this.patientId,
      DoctorId: this.UserId,
      ReportName: ReportName,
      ReportDescription: reportDescription,
      DoctorName: this.doctor.DoctorName,
      Status: true,
    };

    this.doctorReportService
      .updateDoctorReport(this.id, body)
      .subscribe((res) => {
        this.isSuccess = true;
        // this.Frm.reset();
        this.router.navigate(['/splitScreenDctorPatient/', this.patientId]);
      });
  }
}
