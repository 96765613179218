<div class="pb-5">

  <!-- <div class="content">
        <div class="sidebar mat-elevation"> -->
  <!-- <div class="header">
            <img src="../../../assets/admin.png" class="image" alt="">

            <h4 class="text-white ml-2">Patient Details</h4>
           </div> -->
  <div class="toolbar mat-elevation-z6">

    <button mat-raised-button class="backG" [matMenuTriggerFor]="crt"><mat-icon>menu</mat-icon>Menu</button>

    <mat-menu class="ItemS" #crt="matMenu">

      <div class="ItemS ">
        <mat-icon mat-list-icon>dashboard</mat-icon>
        <div class="mx-2" [routerLink]="['/hospitalDash/']">Clinic Dashboard</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS " (click)="showItem='19'" [ngClass]="{'active': showItem =='19'}">
        <mat-icon mat-list-icon>dashboard</mat-icon>
        <div class="mx-2">back</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <hr>


      <!-- 
        <div class="ItemS" (click)="showItem='42'" [ngClass]="{'active': showItem =='42'}">
          <mat-icon mat-list-icon>chat_apps_script</mat-icon>
          <div class="mx-2">Communication-Assessments</div>
          <mat-icon>navigate_next</mat-icon>
        </div>

        <div class="ItemS" (click)="showItem='50'" [ngClass]="{'active': showItem =='50'}">
          <mat-icon mat-list-icon>visibility</mat-icon>
          <div class="mx-2"> Doctor Initial Evaluation</div>
          <mat-icon>navigate_next</mat-icon>
        </div>
        <hr>
        <div class="ItemS " (click)="showItem='46'" [ngClass]="{'active': showItem =='46'}">
          <mat-icon mat-list-icon>visibility</mat-icon>
          <div class="mx-2">Doctor Report</div>
          <mat-icon>navigate_next</mat-icon>
        </div>


        <div class="ItemS" (click)="showItem='53'" [ngClass]="{'active': showItem =='53'}">
          <mat-icon mat-list-icon>water_pump</mat-icon>
          <div class="mx-2">Lab Chart</div>

          <mat-icon>navigate_next</mat-icon>
        </div>

        <div class="ItemS" (click)="showItem='45'" [ngClass]="{'active': showItem =='45'}">
          <mat-icon mat-list-icon>visibility</mat-icon>
          <div class="mx-2">Nurse Note/Report</div>
          <mat-icon>navigate_next</mat-icon>
        </div> -->

      <div class="ItemS " (click)="showItem='1'" [ngClass]="{'active': showItem =='1'}">
        <mat-icon mat-list-icon>nature_people</mat-icon>
        <div class="mx-2">Patient Summary</div>
        <mat-icon>navigate_next</mat-icon>
      </div>


      <div class="ItemS" (click)="showItem='9'" [ngClass]="{'active': showItem =='9'}">
        <i class="fa-solid fa-pills"></i>
        <div class="mx-2"> Prescription</div>
        <mat-icon>navigate_next</mat-icon>
      </div>


      <!-- <div class="ItemS " (click)="showItem='23'" [ngClass]="{'active': showItem =='23'}">
          <mat-icon mat-list-icon>report</mat-icon>
          <div class="mx-2">Vitals Report</div>
          <mat-icon>navigate_next</mat-icon>
        </div>
        <div class="ItemS " (click)="showItem='30'" [ngClass]="{'active': showItem =='23'}">
          <mat-icon mat-list-icon>report</mat-icon>
          <div class="mx-2">Vital Chart</div>
          <mat-icon>navigate_next</mat-icon>
        </div>




        <div class="ItemS" (click)="showItem='55'" [ngClass]="{'active': showItem =='55'}">
          <mat-icon mat-list-icon>water_pump</mat-icon>
          <div class="mx-2">Lab Chart</div>

          <mat-icon>navigate_next</mat-icon>
        </div> -->




      <hr>


      <div class="ItemS" (click)="showItem='84'" [ngClass]="{'active': showItem =='84'}">
        <mat-icon mat-list-icon>visibility</mat-icon>
        <div class="mx-2"> View Appointments</div>
        <mat-icon>navigate_next</mat-icon>
      </div>



      <hr>

      <div class="ItemS" (click)="showItem='5'" [ngClass]="{'active': showItem =='5'}">
        <mat-icon mat-list-icon>add</mat-icon>
        <div class="mx-2"> New Appointment</div>
        <mat-icon>navigate_next</mat-icon>
      </div>

      <div class="ItemS" (click)="showItem='16'" [ngClass]="{'active': showItem =='16'}">
        <mat-icon mat-list-icon>add</mat-icon>
        <div class="mx-2">New Invoice</div>
        <mat-icon>navigate_next</mat-icon>
      </div>

      <div class="ItemS" (click)="showItem='76'" [ngClass]="{'active': showItem =='76'}">
        <mat-icon mat-list-icon>add</mat-icon>
        <div class="mx-2"> New History</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS" (click)="showItem='77'" [ngClass]="{'active': showItem =='77'}">
        <mat-icon mat-list-icon>add</mat-icon>
        <div class="mx-2"> New Pharmacy</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS" (click)="showItem='56'" [ngClass]="{'active': showItem =='56'}">
        <mat-icon mat-list-icon>add</mat-icon>
        <div class="mx-2"> Patient Forms</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS" (click)="showItem='75'" [ngClass]="{'active': showItem =='75'}">
        <mat-icon mat-list-icon>schedule</mat-icon>
        <div class="mx-2"> Completed Forms</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <!-- 

        <div class="ItemS" (click)="showItem='40'" [ngClass]="{'active': showItem =='40'}">
          <mat-icon mat-list-icon>add</mat-icon>
          <div class="mx-2">New Communication-Assessment</div>
          <mat-icon>navigate_next</mat-icon>
        </div>
 -->


      <div class="ItemS" (click)="showItem='71'" [ngClass]="{'active': showItem =='71'}">
        <mat-icon mat-list-icon>add</mat-icon>
        <div class="mx-2">New ID Proof</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS" (click)="showItem='63'" [ngClass]="{'active': showItem =='63'}">
        <mat-icon mat-list-icon>add</mat-icon>
        <div class="mx-2">New Insurance</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS" (click)="showItem='2'" [ngClass]="{'active': showItem =='2'}">
        <mat-icon mat-list-icon>add</mat-icon>
        <div class="mx-2">New Emergency Contact</div>
        <mat-icon>navigate_next</mat-icon>
      </div>

      <!-- 
        <div class="ItemS" (click)="showItem='38'" [ngClass]="{'active': showItem =='38'}">
          <mat-icon mat-list-icon>add</mat-icon>
          <div class="mx-2">New Lab-Order</div>
          <mat-icon>navigate_next</mat-icon>
        </div> -->
      <div class="ItemS" (click)="showItem='10'" [ngClass]="{'active': showItem =='10'}">
        <mat-icon mat-list-icon>add</mat-icon>
        <div class="mx-2">New Lab-Report</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS" (click)="showItem='61'" [ngClass]="{'active': showItem =='61'}">
        <mat-icon mat-list-icon>add</mat-icon>
        <div class="mx-2">New Refferal</div>
        <mat-icon>navigate_next</mat-icon>
      </div>

      <!-- <div class="ItemS" (click)="showItem='44'" [ngClass]="{'active': showItem =='44'}">
          <mat-icon mat-list-icon>add</mat-icon>
          <div class="mx-2">New Nurse Note/Report</div>
          <mat-icon>navigate_next</mat-icon>
        </div> -->











      <!-- <div class="ItemS" (click)="showItem='65'" [ngClass]="{'active': showItem =='65'}">
          <mat-icon mat-list-icon>add</mat-icon>
          <div class="mx-2">New Radiology Order</div>
          <mat-icon>navigate_next</mat-icon>
        </div> -->

      <!-- <div class="ItemS" (click)="showItem='22'" [ngClass]="{'active': showItem =='22'}">
          <mat-icon mat-list-icon>add</mat-icon>
          <div class="mx-2">New Vitals Report</div>
          <mat-icon>navigate_next</mat-icon>
        </div> -->

      <!-- <div class="ItemS" (click)="showItem='4'" [ngClass]="{'active': showItem =='4'}">
          <mat-icon mat-list-icon>add</mat-icon>
          <div class="mx-2"> New Visit</div>
          <mat-icon>navigate_next</mat-icon>
        </div> -->

      <!-- <div class="ItemS" (click)="showItem='68'" [ngClass]="{'active': showItem =='68'}">
        <mat-icon mat-list-icon>title</mat-icon>
        <div class="mx-2">Radiology Order Subjects</div>
        <mat-icon>navigate_next</mat-icon>
      </div> -->

      <!-- <div class="ItemS" (click)="showItem='8'" [ngClass]="{'active': showItem =='8'}">
               <mat-icon mat-list-icon>add</mat-icon>
               <div class="mx-2">New Script</div>
               <mat-icon>navigate_next</mat-icon>
             </div> -->







      <hr>
      <div class="ItemS" (click)="showItem='13'" [ngClass]="{'active': showItem =='13'}">
        <mat-icon mat-list-icon>add_alert</mat-icon>
        <div class="mx-2"> Allergy History</div>
        <mat-icon>navigate_next</mat-icon>
      </div>

      <div class="ItemS" (click)="showItem='11'" [ngClass]="{'active': showItem =='11'}">
        <mat-icon mat-list-icon>web</mat-icon>
        <div class="mx-2">Lab-Reports</div>
        <mat-icon>navigate_next</mat-icon>
      </div>




      <div class="ItemS" (click)="showItem='64'" [ngClass]="{'active': showItem =='64'}">
        <mat-icon mat-list-icon>visibility</mat-icon>
        <div class="mx-2"> View-Lab-Order</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS" (click)="showItem='84'" [ngClass]="{'active': showItem =='84'}">
        <mat-icon mat-list-icon>visibility</mat-icon>
        <div class="mx-2"> View-Procedure-Order</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS" (click)="showItem='83'" [ngClass]="{'active': showItem =='83'}">
        <mat-icon mat-list-icon>visibility</mat-icon>
        <div class="mx-2"> View Radiology Order</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <hr>
      <div class="ItemS" (click)="showItem='31'" [ngClass]="{'active': showItem =='31'}">
        <mat-icon mat-list-icon>report</mat-icon>
        <div class="mx-2">Radiology-Reports</div>
        <mat-icon>navigate_next</mat-icon>
      </div>

      <!-- 
        <div class="ItemS" (click)="showItem='18'" [ngClass]="{'active': showItem =='18'}">
          <mat-icon mat-list-icon>visibility</mat-icon>
          <div class="mx-2"> Rounds Book</div>
          <mat-icon>navigate_next</mat-icon>
        </div> -->



      <div class="ItemS" (click)="showItem='15'" [ngClass]="{'active': showItem =='15'}">
        <mat-icon mat-list-icon>content_cut</mat-icon>
        <div class="mx-2">Surgical History</div>
        <mat-icon>navigate_next</mat-icon>
      </div>








      <div class="ItemS" (click)="showItem='34'" [ngClass]="{'active': showItem =='34'}">
        <mat-icon mat-list-icon>title</mat-icon>
        <div class="mx-2">Radiology Order Subjects</div>
        <mat-icon>navigate_next</mat-icon>
      </div>

      <hr>
      <div class="ItemS" (click)="showItem='80'" [ngClass]="{'active': showItem =='80'}">
        <mat-icon mat-list-icon>visibility</mat-icon>
        <div class="mx-2">Nerve Study Result</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS" (click)="showItem='81'" [ngClass]="{'active': showItem =='81'}">
        <mat-icon mat-list-icon>visibility</mat-icon>
        <div class="mx-2">Electromyography Result</div>
        <mat-icon>navigate_next</mat-icon>
      </div>


      <div class="ItemS" (click)="showItem='66'" [ngClass]="{'active': showItem =='66'}">
        <mat-icon mat-list-icon>image</mat-icon>
        <div class="mx-2">Upload MRI Image</div>
        <mat-icon>navigate_next</mat-icon>
      </div>

      <div class="ItemS" (click)="showItem='59'" [ngClass]="{'active': showItem =='59'}">
        <mat-icon mat-list-icon>show_chart</mat-icon>
        <div class="mx-2">Upload Radiology Report</div>
        <mat-icon>navigate_next</mat-icon>
      </div>

      <div class="ItemS" (click)="showItem='13'" [ngClass]="{'active': showItem =='13'}">
        <mat-icon mat-list-icon>warning</mat-icon>
        <div class="mx-2">View Allergies</div>
        <mat-icon>navigate_next</mat-icon>
      </div>

      <div class="ItemS" (click)="showItem='72'" [ngClass]="{'active': showItem =='72'}">
        <mat-icon mat-list-icon>visibility</mat-icon>
        <div class="mx-2">View ID Proof </div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS" (click)="showItem='74'" [ngClass]="{'active': showItem =='74'}">
        <mat-icon mat-list-icon>visibility</mat-icon>
        <div class="mx-2">View Inusurance</div>
        <mat-icon>navigate_next</mat-icon>
      </div>

      <hr>
      <div class="ItemS" (click)="showItem='67'" [ngClass]="{'active': showItem =='67'}">
        <mat-icon mat-list-icon>image</mat-icon>
        <div class="mx-2">View MRI images</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <hr>
      <div class="ItemS" (click)="showItem='62'" [ngClass]="{'active': showItem =='62'}">
        <mat-icon mat-list-icon>file_open</mat-icon>
        <div class="mx-2">View Refferal</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <hr>


      <div class="ItemS" (click)="showItem='79'" [ngClass]="{'active': showItem =='79'}">
        <mat-icon mat-list-icon>visibility</mat-icon>
        <div class="mx-2">Procedure Test Result</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <!-- <div class="ItemS" (click)="showItem='60'" [ngClass]="{'active': showItem =='60'}">
        <mat-icon mat-list-icon>view_array</mat-icon>
        <div class="mx-2">View Uploaded Radiology Doc</div>
        <mat-icon>navigate_next</mat-icon>
      </div> -->
      <hr>
      <!-- <div class="ItemS" (click)="showItem='57'" [ngClass]="{'active': showItem =='57'}">
        <mat-icon mat-list-icon>show_chart</mat-icon>
        <div class="mx-2">Upload Lab Doc</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS" (click)="showItem='58'" [ngClass]="{'active': showItem =='58'}">
        <mat-icon mat-list-icon>view_array</mat-icon>
        <div class="mx-2">View Uploaded Lab Doc</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <hr> -->

      <div class="ItemS" (click)="showItem='73'" [ngClass]="{'active': showItem =='73'}">
        <mat-icon mat-list-icon>vaccines</mat-icon>
        <div class="mx-2"> View Vaccine</div>
        <mat-icon>navigate_next</mat-icon>
      </div>


      <hr>
      <div class="ItemS" (click)="showItem='17'" [ngClass]="{'active': showItem =='17'}">
        <mat-icon mat-list-icon>receipt</mat-icon>
        <div class="mx-2">View Invoice</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS" (click)="showItem='82'" [ngClass]="{'active': showItem =='82'}">
        <mat-icon mat-list-icon>payments</mat-icon>
        <div class="mx-2">View Payment History</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <hr>




      <div class="ItemS">
        <mat-icon mat-list-icon>undo</mat-icon>
        <div class="mx-2" [routerLink]="['/hospitalDash/']"> Clinic Dashboard</div>
        <!-- <mat-icon>navigate_next</mat-icon> -->
      </div>
    </mat-menu>

  </div>

  <!-- <div class="ItemS  " >
             <mat-icon mat-list-icon>add</mat-icon>
             <div class="mx-2">New Appointment</div>
             <mat-icon>navigate_next</mat-icon>
           </div>
           <div class="ItemS  " >
             <mat-icon mat-list-icon>visibility</mat-icon>
             <div class="mx-2">Medical Records</div>
             <mat-icon>navigate_next</mat-icon>
           </div>
            -->



  <!-- 
         </div> -->
  <div class="main">
    <mat-tab-group>


      <mat-tab label="New Appointment">
        <ng-template matTabContent>

          <app-add-appointment></app-add-appointment>

        </ng-template>
      </mat-tab>
      <mat-tab label="New Consents ">
        <ng-template matTabContent>

          <app-patient-intake-items></app-patient-intake-items>

        </ng-template>
      </mat-tab>
      <mat-tab label="Completed Consents ">
        <ng-template matTabContent>

          <app-completed-forms></app-completed-forms>


        </ng-template>
      </mat-tab>
    </mat-tab-group>
    <!-- <div class="toolbar mat-elevation-z6" ></div>  -->

    <span [ngSwitch]="showItem">

      <p *ngSwitchCase="'1'">
        <app-patientsummary></app-patientsummary>
      </p>
      <p *ngSwitchCase="'2'">
        <app-add-emergency-contact></app-add-emergency-contact>
      </p>

      <p *ngSwitchCase="'3'">
        <app-add-patient-insurance></app-add-patient-insurance>
      </p>
      <!-- <p *ngSwitchCase="'4'">
        <app-add-patient-visit></app-add-patient-visit>
      </p> -->
      <p *ngSwitchCase="'5'">
        <app-add-appointment></app-add-appointment>
      </p>
      <p *ngSwitchCase="'6'">
        <app-notepad></app-notepad>
      </p>
      <p *ngSwitchCase="'7'">
        <app-vew-doctor-note></app-vew-doctor-note>
      </p>
      <p *ngSwitchCase="'8'">
        <app-add-prescription></app-add-prescription>
      </p>
      <p *ngSwitchCase="'9'">
        <app-staff-view-prescriptiondisplay></app-staff-view-prescriptiondisplay>
      </p>
      <p *ngSwitchCase="'10'">
        <app-add-labreport></app-add-labreport>
      </p>
      <p *ngSwitchCase="'11'">
        <app-view-labreport></app-view-labreport>
      </p>
      <p *ngSwitchCase="'12'">
        <app-add-allergy></app-add-allergy>
      </p>
      <p *ngSwitchCase="'13'">
        <app-view-allergy></app-view-allergy>
      </p>

      <p *ngSwitchCase="'14'">
        <app-add-surgical-history></app-add-surgical-history>
      </p>
      <p *ngSwitchCase="'15'">
        <app-view-surgical-history></app-view-surgical-history>
      </p>
      <p *ngSwitchCase="'16'">
        <app-add-invoice></app-add-invoice>
      </p>
      <p *ngSwitchCase="'17'">
        <app-view-invoice></app-view-invoice>
      </p>
      <p *ngSwitchCase="'18'">
        <app-vew-doctor-note></app-vew-doctor-note>
      </p>
      <p *ngSwitchCase="'19'">
        <app-patientdetails></app-patientdetails>
      </p>



      <p *ngSwitchCase="'22'">
        <app-add-nursereport></app-add-nursereport>
      </p>

      <p *ngSwitchCase="'23'">
        <app-view-patient-nursereport></app-view-patient-nursereport>
      </p>




      <!-- <p *ngSwitchCase="'28'">
      <app-add-nursing-assesment></app-add-nursing-assesment>
    </p> -->

      <!-- <p *ngSwitchCase="'29'">
      <app-view-nursing-assessment-plan></app-view-nursing-assessment-plan>
    </p> -->
      <p *ngSwitchCase="'30'">
        <app-vitals-chart></app-vitals-chart>
      </p>
      <p *ngSwitchCase="'31'">
        <app-view-radiology-report></app-view-radiology-report>
      </p>



      <p *ngSwitchCase="'38'">
        <app-add-lab-order></app-add-lab-order>
      </p>






      <p *ngSwitchCase="'40'">
        <app-add-communication-assessment></app-add-communication-assessment>
      </p>



      <p *ngSwitchCase="'42'">
        <app-view-communication-assessment></app-view-communication-assessment>
      </p>




      <p *ngSwitchCase="'44'">
        <app-add-nurse-note></app-add-nurse-note>
      </p>


      <p *ngSwitchCase="'45'">
        <app-view-nurse-note></app-view-nurse-note>
      </p>

      <p *ngSwitchCase="'46'">
        <app-view-doctor-report></app-view-doctor-report>
      </p>


      <p *ngSwitchCase="'50'">
        <app-view-doctor-initial-evaluation></app-view-doctor-initial-evaluation>

      </p>


      <p *ngSwitchCase="'53'">
        <app-lab-test-chart></app-lab-test-chart>

      </p>







      <p *ngSwitchCase="'64'">
        <app-view-lab-order></app-view-lab-order>

      </p>
      <p *ngSwitchCase="'65'">
        <app-add-radiology-order></app-add-radiology-order>

      </p>
      <p *ngSwitchCase="'66'">
        <app-add-mri></app-add-mri>
      </p>
      <p *ngSwitchCase="'67'">
        <app-view-mri></app-view-mri>
      </p>
      <p *ngSwitchCase="'68'">
        <app-add-radiology-order-subject></app-add-radiology-order-subject>
      </p>



      <p *ngSwitchCase="'71'">
        <app-add-patient-idproof></app-add-patient-idproof>

      </p>
      <p *ngSwitchCase="'72'">
        <app-view-patient-idproof></app-view-patient-idproof>

      </p>
      <p *ngSwitchCase="'73'">
        <app-view-vaccine></app-view-vaccine>

      </p>
      <p *ngSwitchCase="'74'">
        <app-view-patient-insurance></app-view-patient-insurance>

      </p>
      <p *ngSwitchCase="'34'">
        <app-add-radiology-order-subject></app-add-radiology-order-subject>
      </p>



      <p *ngSwitchCase="'55'">
        <app-lab-test-chart></app-lab-test-chart>

      </p>
      <p *ngSwitchCase="'56'">
        <app-patient-intake-items></app-patient-intake-items>
      </p>





      <!-- <p *ngSwitchCase="'57'">
      <app-add-upload-lab-repor></app-add-upload-lab-repor>

    </p>
    <p *ngSwitchCase="'58'">
      <app-view-upload-lab-report></app-view-upload-lab-report>

    </p> -->
      <p *ngSwitchCase="'59'">
        <app-add-upload-radiology-report></app-add-upload-radiology-report>

      </p>
      <!-- <p *ngSwitchCase="'60'">
      <app-view-upload-radiology-report></app-view-upload-radiology-report>

    </p> -->
      <p *ngSwitchCase="'61'">
        <app-add-refferal></app-add-refferal>
      </p>
      <p *ngSwitchCase="'62'">
        <app-view-refferal></app-view-refferal>

      </p>
      <p *ngSwitchCase="'63'">
        <app-add-patient-insurance></app-add-patient-insurance>

      </p>
      <p *ngSwitchCase="'76'">
        <app-patient-history></app-patient-history>
      </p>
      <p *ngSwitchCase="'77'">
        <app-add-patient-pharmacy></app-add-patient-pharmacy>
      </p>

      <p *ngSwitchCase="'75'">
        <app-completed-forms></app-completed-forms>

      </p>

      <p *ngSwitchCase="'84'">
        <app-view-procedure></app-view-procedure>

      </p>
      <!-- <p *ngSwitchCase="'78'">
      <app-view-prescriptions-today></app-view-prescriptions-today>
    </p> -->
      <p *ngSwitchCase="'79'">
        <app-view-test-results></app-view-test-results>

      </p>



      <p *ngSwitchCase="'80'">
        <app-view-nerve-study-results></app-view-nerve-study-results>

      </p>
      <p *ngSwitchCase="'81'">
        <app-view-electromyography></app-view-electromyography>

      </p>
      <p *ngSwitchCase="'82'">
        <app-view-payment-history></app-view-payment-history>


      </p>
      <p *ngSwitchCase="'83'">
        <app-view-radiology-order></app-view-radiology-order>


      </p>
      <p *ngSwitchCase="'84'">
        <app-view-appointment></app-view-appointment>


      </p>


      <p *ngSwitchDefault>

        <app-patientdetails></app-patientdetails>
      </p>
    </span>

  </div>
</div>