import { Component, HostListener, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Doctor } from 'src/app/Models/doctor.model';
import { DoctorReportService } from 'src/app/services/doctor-report.service';
import { DoctorService } from 'src/app/services/doctor.service';
import { PatientService } from 'src/app/services/patient.service';
import { TestResultsService } from 'src/app/services/test-results.service';
import { VoiceRecognitionAssessmentService } from 'src/app/services/voice-recognition-assessment.service';
import { VoiceRecognitionObjectiveService } from 'src/app/services/voice-recognition-objective.service';
import { VoiceRecognitionService } from 'src/app/services/voice-recognition.service';

@Component({
  selector: 'app-add-test-results',
  templateUrl: './add-test-results.component.html',
  styleUrls: ['./add-test-results.component.css'],
})
export class AddTestResultsComponent implements OnInit {
  id = 0;
  // reportList: DoctorReport[] = [];
  isSuccess = false;
  helper = new JwtHelperService();
  myToken = window.localStorage.getItem('token');
  UserId: number = 0;
  doctor: Doctor;
  message: string;
  listening = false;
  listeningObjective = false;
  listeningAssessment = false;
  listeningPatientNote = false;
  patient: any;

  constructor(
    private fb: UntypedFormBuilder,
    private patientService: PatientService,

    private testResultService: TestResultsService,
    private route: ActivatedRoute,
    public service: VoiceRecognitionService,
    private doctorService: DoctorService,
    public serviceObjective: VoiceRecognitionObjectiveService,
    public serviceAssessment: VoiceRecognitionAssessmentService
  ) {
    this.service.init();
    this.serviceObjective.init();
    this.serviceAssessment.init();
  }

  ngOnInit(): void {
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.UserId = parseInt(decodedToken.nameid);

    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    this.getPatient(this.id);

    this.getDoctor(this.UserId);
  }

  Frm = this.fb.group({
    TestName: ['', Validators.required],
    PatientComplaints: ['', Validators.required],
    Findings: ['', Validators.required],
    Impression: ['', Validators.required],
  });

  get Findings() {
    return this.Frm.get('Findings');
  }
  get Impression() {
    return this.Frm.get('Impression');
  }
  get TestName() {
    return this.Frm.get('TestName');
  }

  get PatientComplaints() {
    return this.Frm.get('PatientComplaints');
  }
  getPatient(id) {
    this.patientService.getPatientById(id).subscribe((res) => {
      this.patient = res;
    });
  }
  tabChanged(event: MatTabChangeEvent): void {
    if (event.index === 0) {
    }
  }

  startService() {
    this.service.start();
    this.listening = true;
  }

  stopService() {
    this.service.stop();
    this.listening = false;
  }

  onSubjectiveChange(e) {
    this.service.message1 = e.target.value;
  }

  onSubjectiveBlur() {
    this.stopService();
    this.stopServiceObjective();
    this.stopServiceAssessment();
  }
  onObjectiveBlur() {
    this.stopService();
    this.stopServiceObjective();
    this.stopServiceAssessment();
  }

  onAssessmentBlur() {
    this.stopService();
    this.stopServiceObjective();
    this.stopServiceAssessment();
  }
  onPatientNoteBlur() {
    this.stopService();
    this.stopServiceObjective();
    this.stopServiceAssessment();
  }
  stopAll() {
    this.stopService();
    this.stopServiceObjective();
    this.stopServiceAssessment();
  }
  startServiceObjective() {
    this.serviceObjective.startObjective();
    this.listeningObjective = true;
  }
  stopServiceObjective() {
    this.serviceObjective.stopObjective();
    this.listeningObjective = false;
  }

  onObjectiveChange(e) {
    this.serviceObjective.Objective = e.target.value;
  }

  startServiceAssessment() {
    this.serviceAssessment.startAssessment();
    this.listeningAssessment = true;
  }
  stopServiceAssessment() {
    this.serviceAssessment.stopAssessment();
    this.listeningAssessment = false;
  }
  onAssessmentChange(e) {
    this.serviceAssessment.Assessment = e.target.value;
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode == KEY_CODE.DOWN_ARROW) {
      // Your row selection code
      // console.log(event);
      this.stopAll();
    }
  }

  getDoctor(id) {
    this.doctorService.getDoctorById(id).subscribe((res) => {
      this.doctor = res;
    });
  }

  formSubmit() {
    let testName = this.Frm.value['TestName'];
    let patientComplaints = this.Frm.value['PatientComplaints'];
    let findings = this.Frm.value['Findings'];
    let impression = this.Frm.value['Impression'];

    let body = {
      PatientId: this.id,
      DoctorId: this.UserId,
      TestName: testName,
      PatientComplaints: patientComplaints,
      Findings: findings,
      Impression: impression,
      DoctorName: this.doctor.DoctorName,
    };

    this.testResultService.registerTestResult(body).subscribe((res) => {
      this.isSuccess = true;
      this.Frm.reset();
    });
  }

  resetFn(e) {
    if (e) {
      this.Frm.reset();
    }
  }
}
export enum KEY_CODE {
  UP_ARROW = 38,
  DOWN_ARROW = 40,
  RIGHT_ARROW = 39,
  LEFT_ARROW = 37,
}
