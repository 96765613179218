import { Component, OnInit } from '@angular/core';
import { RadiologyMemberService } from '../../services/radiology-member.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Department } from 'src/app/Models/department.model';
import { UserService } from 'src/app/services/user.service';
import { DepartmentService } from 'src/app/services/department.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { EmailService } from 'src/app/services/email.service';
import { HospitalService } from 'src/app/services/hospital.service';

@Component({
  selector: 'app-add-radiology-member',
  templateUrl: './add-radiology-member.component.html',
  styleUrls: ['./add-radiology-member.component.css'],
})
export class AddRadiologyMemberComponent implements OnInit {
  id = 0;
  helper = new JwtHelperService();

  myToken = window.localStorage.getItem('token');

  isSuccess = false;
  dptList: Department[];
  Codes: any[] = [];
  filteredOptions: Observable<string[]>;
  hospitalName: string;

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private dptService: DepartmentService,
    private radiologyMemberService: RadiologyMemberService,
    private userService: UserService,
    private router: Router,
    private http: HttpClient,
    private emailService: EmailService,
    private hosptalService: HospitalService
  ) {}

  ngOnInit(): void {
    // this.route.params.subscribe((res) => {
    //   this.id = res['id'];
    // });
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.id = parseInt(decodedToken.nameid);

    this.hosptalService.getHospitalById(this.id).subscribe((res) => {
      this.hospitalName = res.HospitalName.substring(0, 3);
    });

    this.getDepartments();

    this.countryCodes();
    this.filteredOptions =
      this.doctorFrm.controls.CountryCode.valueChanges.pipe(
        startWith(''),
        map((value: string) => this._filter(value || ''))
      );
  }

  doctorFrm = this.fb.group({
    DepartmentId: ['', Validators.required],
    FullName: ['', Validators.required],
    Email: ['', Validators.required],
    CountryCode: ['+1', Validators.required],
    PhoneNumber: ['', Validators.required],
    NationalId: ['', Validators.required],
    Address: ['', Validators.required],
    Dob: ['', Validators.required],
    Status: [''],
    Password: [
      '',
      [
        Validators.required,
        Validators.pattern(
          '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{7,}$'
        ),
      ],
    ],
  });
  get CountryCode() {
    return this.doctorFrm.get('CountryCode');
  }
  get FullName() {
    return this.doctorFrm.get('FullName');
  }
  get Email() {
    return this.doctorFrm.get('Email');
  }
  get DepartmentId() {
    return this.doctorFrm.get('DepartmentId');
  }
  get PhoneNumber() {
    return this.doctorFrm.get('PhoneNumber');
  }
  get NationalId() {
    return this.doctorFrm.get('NationalId');
  }

  get Dob() {
    return this.doctorFrm.get('Dob');
  }
  get Address() {
    return this.doctorFrm.get('Address');
  }

  get Status() {
    return this.doctorFrm.get('Status');
  }
  get Password() {
    return this.doctorFrm.get('Password');
  }

  getDepartments() {
    this.dptService.getDepartments().subscribe((res) => {
      this.dptList = res;
    });
  }

  formSubmit() {
    let fullName = this.doctorFrm.value['FullName'];
    let dob = this.doctorFrm.value['Dob'];
    let email = this.doctorFrm.value['Email'];
    let phoneNumber =
      this.doctorFrm.value['CountryCode'] + this.doctorFrm.value['PhoneNumber'];
    let nationalId = this.doctorFrm.value['NationalId'];
    let address = this.doctorFrm.value['Address'];
    let status = this.doctorFrm.value['Status'];
    let password = this.doctorFrm.value['Password'];
    let departmentId = this.doctorFrm.value['DepartmentId'];
    let dptName = this.dptList.filter(
      (it) => it.DepartmentId == departmentId
    )[0].DepartmentName;

    let userBody = {
      Email: email,
      Password: password,
      UserName: this.hospitalName + email,
      PhoneNumber: phoneNumber,
    };
    this.userService.registerRadiologyMember(userBody).subscribe((res) => {
      let body = {
        HospitalId: this.id,
        RadiologyMemberIid: res.Id,
        FullName: fullName,
        Dob: dob,
        Email: email,
        PhoneNumber: phoneNumber,
        NationalId: nationalId,
        Address: address,
        Status: status,
        Password: password,
        DepartmentId: departmentId,
      };

      this.radiologyMemberService
        .registerRadiologyMember(body)
        .subscribe((res) => {
          this.isSuccess = true;
          this.doctorFrm.reset();

          this.emailService
            .sendEmail({
              Email: body.Email,
              Subject: ' Login Credentials',
              Template: `<h3>Your Username ${userBody.UserName} please keep this safe. You may reset password now at https://binseraneurology.com. </h3> `,
            })
            .subscribe();

          const smsBodyDetails =
            'Your Username ' +
            userBody.UserName +
            ' You may reset password now at https://binseraneurology.com ';
          let smsBody = {
            PhoneNumber: phoneNumber,
            Body: smsBodyDetails,
          };
          this.emailService.sendSms(smsBody).subscribe();
          this.isSuccess = true;

          setTimeout(() => {}, 2000);
        });
    });
  }

  public countryCodes() {
    this.http.get('/assets/json/country-codes.json').subscribe({
      next: (res: any) => {
        this.Codes = res.map((x) => x.dial_code);
      },
    });
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.Codes.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }
}
