import { Component, OnInit } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { DoctorTemplateService } from 'src/app/services/doctor-template.service';
import { DoctorService } from 'src/app/services/doctor.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-doctor-template-editor',
  templateUrl: './doctor-template-editor.component.html',
  styleUrls: ['./doctor-template-editor.component.css'],
})
export class DoctorTemplateEditorComponent implements OnInit {
  id = 0;
  doctorId = 0;
  helper = new JwtHelperService();
  myToken = window.localStorage.getItem('token');

  isSuccess = false;

  constructor(
    private templateService: DoctorTemplateService,
    private doctorService: DoctorService
  ) {}
  ngOnInit(): void {
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.doctorId = parseInt(decodedToken.nameid);
  }

  template = {
    name: '',
    shortcut: '',
    // content: '',
  };
  question_image(event) {
    // console.log(e)
    let formData = new FormData();
    let files = event.target.files;
    formData.append('file', files[0], files[0].name);

    this.doctorService.imageUpload(formData).subscribe(
      (res) => {
        let qImag = `<p><img src="${environment.imgurl + res['path']}"/></p>`;
        // let stImg = environment.imgurl + res['path'];

        this.editorContent.setValue(this.editorContent.value + qImag);
      },
      (err) => console.log(err)
    );
  }

  editorContent: any = 'Initial content'; // Your editor content

  // Update the content on every change in the editor
  onContentChanged(event: any) {
    this.editorContent = event.html; // Update the content based on Quill's HTML
    let cleanedContent = this.editorContent.replace(/<\/?p[^>]*>/gi, '\n');

    // Step 2: Remove <strong> tags entirely
    cleanedContent = cleanedContent.replace(/<\/?strong[^>]*>/gi, '');

    // Step 3: Remove all other HTML tags (if any remain)
    cleanedContent = cleanedContent.replace(/<\/?[^>]+(>|$)/g, '');

    // Clean up excess new lines (optional)
    cleanedContent = cleanedContent.replace(/\n\s*\n/g, '\n').trim();

    // The final cleaned content
    this.editorContent = cleanedContent;
  }
  saveTemplate() {
    let body = {
      ...this.template,
      Content: this.editorContent,
      DoctorId: this.doctorId,
    };

    this.templateService.saveTemplate(body).subscribe((response) => {
      this.isSuccess = true;
    });
  }
}
// Content: this.editorContent.replace(/<\/?p[^>]*>/g, ''),
// Content: this.editorContent.replace(/<\/?p[^>]*>/g, '\n'),
