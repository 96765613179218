<div class="container mt-5">

    <div class="row">
        <div class="col-lg regFrm mat-elevation-z8">
            <h2 class="pt-3">Send Message to a Nurse</h2>
            <form [formGroup]="Frm" (ngSubmit)="formSubmit()" class="example-form">
                <div class="row">




                    <div class="col-lg-6">

                        <mat-form-field class="example-full-width">
                            <mat-label>Select a Nurse</mat-label>


                            <!-- <input type="text" formControlName="DoctorName" matInput >
                      <mat-error >Please enter Doctor Name</mat-error> -->

                            <mat-select formControlName="UserId">

                                <mat-option *ngFor="let nurse of nurseList"
                                    [value]="nurse.NurseId">{{nurse.NurseName}}</mat-option>

                            </mat-select>
                            <mat-error>select an option</mat-error>
                        </mat-form-field>

                    </div>


                </div>



                <div class="row">
                    <div class="col-lg-12">


                        <mat-form-field appearance="fill" class="example-full-width">
                            <mat-label>Message</mat-label>

                            <textarea matInput formControlName="Message" cdkTextareaAutosize
                                #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="5" cdkAutosizeMaxRows="140"
                                matInput></textarea>


                        </mat-form-field>


                    </div>

                </div>



                <button class="float-right lgBtCl" mat-button>
                    <mat-icon>send</mat-icon>
                </button>

            </form>



        </div>
    </div>


</div>

<div class="mt-5">
    <table class="table table">
        <thead>
            <th>From</th>
            <th>To</th>
            <th>Message</th>
            <th>Time</th>
        </thead>
        <tbody>
            <tr *ngFor="let mes of myMessage">
                <td>{{mes.FromUserName}}</td>
                <td>{{mes.ToUserName}}</td>
                <td>{{mes.Message}}</td>
                <td>{{mes.Timestamp | date: 'medium'}}</td>
                <td><button color="accent" mat-mini-fab (click)="delete(mes.Id)"><mat-icon>delete</mat-icon></button>
                </td>
            </tr>
        </tbody>
    </table>