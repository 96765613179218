<div class="container ">
    <!-- <div class="row mt-3">
      <div class="col-lg-3 ">
  
        Patient Name : {{patient.PatientFirstName}}
        {{patient.PatientLastName}},
      </div>
      <div class="col-lg-5">
  
        D.O.B : {{patient.PatientDOB}},
        Patient No : {{patient.PatientId}}
  
      </div>
      <div class="col-lg-4"></div>
    </div> -->
    <div class="row">

        <div class="col-lg regFrm mat-elevation-z8">
            <h1>Update Progress Notes</h1>
            <form [formGroup]="Frm" (ngSubmit)="formSubmit()" class="example-form">
                <mat-form-field class="example-full-width">
                    <mat-label>Report Name</mat-label>
                    <input type="text" formControlName="ReportName" matInput>
                    <mat-error>Please enter Report Name</mat-error>
                </mat-form-field>


                <mat-form-field appearance="fill" class="example-full-width">
                    <mat-label>Description</mat-label>

                    <textarea matInput (input)="onSubjectiveChange($event)" formControlName="ReportDescription"
                        cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="5"
                        cdkAutosizeMaxRows="140" [value]="service.message1" matInput
                        (blur)="onSubjectiveBlur()"></textarea>
                    <button type="button" class="app-speech-input-btn" [disabled]="listening"
                        (click)="startService()"></button>
                </mat-form-field>
                <button type="button" color="accent" (click)="stopService()"
                    mat-mini-fab><mat-icon>stop</mat-icon></button>



                <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
                    Successfully submitted
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <button class="float-right lgBtCl" mat-button>
                    <mat-icon>save</mat-icon>
                    Sign & Save</button>

            </form>

        </div>
    </div>
</div>