<mat-tab-group #tabRef1 (selectedIndexChange)="logIndex(tabRef1.selectedIndex)">

    <mat-tab label="Procedure Test Results">

        <ng-template matTabContent>
            <app-view-test-results></app-view-test-results>

        </ng-template>
    </mat-tab>
    <mat-tab label="Nerve-study Results">
        <ng-template matTabContent>
            <app-view-nerve-study-results></app-view-nerve-study-results>


        </ng-template>
    </mat-tab>
    <mat-tab label="Electromyography Results">
        <ng-template matTabContent>
            <app-view-electromyography></app-view-electromyography>


        </ng-template>
    </mat-tab>
</mat-tab-group>