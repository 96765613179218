<div class="ml-3">
    <div class="row">
        <div class="col-lg-6 float-right">
            <mat-form-field class="example-full-width mt-5">
                <mat-label>Filter</mat-label>
                <input type="text" #searchValue (keyup)="applyFilter(searchValue)" matInput>

            </mat-form-field>
        </div>
    </div>
</div>

<div class=" mat-elevation-z8 ">


    <table class="table table-hover w-100">
        <thead>
            <tr>
                <th scope="col">Date</th>
                <th scope="col">Side</th>
                <th scope="col">Muscle</th>
                <th scope="col">Nerve</th>
                <th scope="col">Root</th>
                <th scope="col">Ins.Act</th>
                <th scope="col">Fibs</th>
                <th scope="col">Psw</th>
                <th scope="col">Amp</th>
                <th scope="col">Duration (ms)</th>
                <th scope="col">Poly</th>
                <th scope="col">Recrt</th>
                <th scope="col">Int. Pat</th>
                <th scope="col">Comments</th>
                <th scope="col">Update</th>
                <th scope="col">Delete</th>
            </tr>
        </thead>
        <tbody>


            <tr *ngFor="let item of studyResult">
                <td>{{item.createdDate | date: 'short'}}</td>
                <td>{{item.Side}}</td>
                <td>{{item.Muscle}}</td>
                <td>{{item.Nerve}}</td>
                <td>{{item.Root}}</td>
                <td>{{item.InsAct}}</td>
                <td>{{item.Fibs}}</td>
                <td>{{item.Psw}}</td>
                <td>{{item.Amp}}</td>
                <td>{{item.Duration}}</td>
                <td>{{item.Poly}}</td>
                <td>{{item.Recrt}}</td>
                <td>{{item.IntPat}}</td>
                <td>{{item.Comment}}</td>
                <td>
                    <button type="button" [routerLink]="['/updateElectromyograpgy/',item.ElectromyographyId]"
                        mat-mini-fab color="primary">
                        <mat-icon matPrefix>edit</mat-icon>
                    </button>
                </td>
                <td>
                    <button mat-mini-fab color="accent"
                        (click)="delete(item.ElectromyographyId)"><mat-icon>delete</mat-icon></button>
                </td>
            </tr>


        </tbody>
    </table>


</div>