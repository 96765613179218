import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { NurseService } from '../../services/nurse.service';
import { DepartmentService } from '../../services/department.service';
import { Department } from 'src/app/Models/department.model';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { WebcamImage } from 'ngx-webcam';
import { Subject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { startWith, map } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HospitalService } from 'src/app/services/hospital.service';
import { EmailService } from 'src/app/services/email.service';

@Component({
  selector: 'app-add-nurse',
  templateUrl: './add-nurse.component.html',
  styleUrls: ['./add-nurse.component.css'],
})
export class AddNurseComponent implements OnInit {
  id = 0;
  helper = new JwtHelperService();

  myToken = window.localStorage.getItem('token');

  NurseSpeciality: string[] = [
    'Med-Surg',
    'Telemetry',
    'ICU',
    'OPT',
    'ER',
    'Oncology',
    'Neurology',
  ];
  dptList: Department[];
  imageUrl: string;
  Codes: any[] = [];
  filteredOptions: Observable<string[]>;
  isSuccess = false;
  hospitalName: string;

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private nurseService: NurseService,
    private userService: UserService,
    private router: Router,
    private dptService: DepartmentService,
    private http: HttpClient,
    private hosptalService: HospitalService,
    private emailService: EmailService
  ) {}

  ngOnInit(): void {
    // this.route.params.subscribe((res) => {
    //   this.id = res['id'];
    // });
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.id = parseInt(decodedToken.nameid);

    this.hosptalService.getHospitalById(this.id).subscribe((res) => {
      this.hospitalName = res.HospitalName.substring(0, 3);
    });

    this.getDepartments(this.id);
    this.countryCodes();
    this.filteredOptions = this.nurseFrm.controls.CountryCode.valueChanges.pipe(
      startWith(''),
      map((value: string) => this._filter(value || ''))
    );
  }

  nurseFrm = this.fb.group({
    DepartmentId: [''],
    NurseName: ['', Validators.required],
    NpiNumber: [''],
    DepartmentName: [''],
    NurseQualification: ['', Validators.required],
    NursesSpeciality: ['', Validators.required],
    NursePostion: ['', Validators.required],
    IsLicenced: ['', Validators.required],
    LicenceNumber: ['', Validators.required],
    SSN: ['', Validators.required],
    NurseDOB: ['', Validators.required],
    Email: ['', Validators.required],
    CountryCode: ['+1', Validators.required],
    PhoneNumber: ['', Validators.required],
    NurseAddress: ['', Validators.required],
    StartDate: [''],
    EndDate: [''],
    Password: [
      '',
      [
        Validators.required,
        Validators.pattern(
          '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{7,}$'
        ),
      ],
    ],
    Status: [true],
  });

  getDepartments(id) {
    this.hosptalService.getDepartment(id).subscribe((res) => {
      this.dptList = res.Departments;
    });
  }

  get NpiNumber() {
    return this.nurseFrm.get('NpiNumber');
  }

  get CountryCode() {
    return this.nurseFrm.get('CountryCode');
  }

  get DepartmentName() {
    return this.nurseFrm.get('DepartmentName');
  }
  get NurseName() {
    return this.nurseFrm.get('NurseName');
  }
  get NurseQualification() {
    return this.nurseFrm.get('NurseQualification');
  }
  get NursesSpeciality() {
    return this.nurseFrm.get('NursesSpeciality');
  }
  get NursePostion() {
    return this.nurseFrm.get('NursePostion');
  }
  get IsLicenced() {
    return this.nurseFrm.get('IsLicenced');
  }
  get LicenceNumber() {
    return this.nurseFrm.get('LicenceNumber');
  }
  get SSN() {
    return this.nurseFrm.get('SSN');
  }
  get NurseDOB() {
    return this.nurseFrm.get('NurseDOB');
  }
  get PhoneNumber() {
    return this.nurseFrm.get('PhoneNumber');
  }
  get NurseAddress() {
    return this.nurseFrm.get('NurseAddress');
  }
  get Password() {
    return this.nurseFrm.get('Password');
  }
  get Email() {
    return this.nurseFrm.get('Email');
  }
  get DepartmentId() {
    return this.nurseFrm.get('DepartmentId');
  }
  get StartDate() {
    return this.nurseFrm.get('StartDate');
  }
  get EndDate() {
    return this.nurseFrm.get('EndDate');
  }
  get Status() {
    return this.nurseFrm.get('Status');
  }

  image(e) {
    let formData = new FormData();
    let files = e.target.files;
    formData.append('file', files[0], files[0].name);

    this.nurseService.imageUpload(formData).subscribe(
      (res) => {
        let stImg = environment.imgurl + res['path'];
        this.imageUrl = stImg;
      },
      (err) => console.log(err)
    );
  }

  private trigger: Subject<any> = new Subject();
  public webcamImage!: WebcamImage;
  private nextWebcam: Subject<any> = new Subject();
  sysImage = '';
  public getSnapshot(): void {
    this.trigger.next(void 0);
  }
  public captureImg(webcamImage: WebcamImage): void {
    this.webcamImage = webcamImage;
    this.sysImage = webcamImage!.imageAsDataUrl;
    // console.info('got webcam image', this.sysImage);
  }
  public get invokeObservable(): Observable<any> {
    return this.trigger.asObservable();
  }
  public get nextWebcamObservable(): Observable<any> {
    return this.nextWebcam.asObservable();
  }

  formSubmit() {
    let npiNumber = this.nurseFrm.value['NpiNumber'];
    let nurseName = this.nurseFrm.value['NurseName'];
    let nurseQualification = this.nurseFrm.value['NurseQualification'];
    let nursesSpeciality = this.nurseFrm.value['NursesSpeciality'];
    let nursePostion = this.nurseFrm.value['NursePostion'];
    let isLicenced = this.nurseFrm.value['IsLicenced'];
    let sSN = this.nurseFrm.value['SSN'];
    let licenceNumber = this.nurseFrm.value['LicenceNumber'];
    let nurseDOB = this.nurseFrm.value['NurseDOB'];
    nurseDOB = moment(nurseDOB).format('MM/DD/YYYY');
    let email = this.nurseFrm.value['Email'];
    let phoneNumber =
      this.nurseFrm.value['CountryCode'] + this.nurseFrm.value['PhoneNumber'];
    let nurseAddress = this.nurseFrm.value['NurseAddress'];
    let startDate = this.nurseFrm.value['StartDate'];
    let status = this.nurseFrm.value['Status'];
    let password = this.nurseFrm.value['Password'];
    let departmentId = this.nurseFrm.value['DepartmentId'];
    let dptName = this.dptList.filter(
      (it) => it.DepartmentId == departmentId
    )[0].DepartmentName;

    let userBody = {
      Email: email,
      Password: password,
      UserName: this.hospitalName + email,
      PhoneNumber: phoneNumber,
    };
    this.userService.registerNurse(userBody).subscribe((res) => {
      let body = {
        HospitalId: this.id,
        NurseId: res.Id,
        NurseName: nurseName,
        NpiNumber: npiNumber,
        NurseQualification: nurseQualification,
        NurseSpeciality: nursesSpeciality,
        NursePostion: nursePostion,
        IsLicenced: isLicenced,
        SSN: sSN,
        LicenceNumber: licenceNumber,
        NurseDOB: nurseDOB,
        Email: email,
        PhoneNumber: phoneNumber,
        NurseAddress: nurseAddress,
        StartDate: startDate,
        Status: status,
        Password: password,
        DepartmentId: departmentId,
        DepartmentName: dptName,
        NurseImage: this.sysImage ? this.sysImage : this.imageUrl,
      };

      this.nurseService.registerNurse(body).subscribe((res) => {
        this.isSuccess = true;
        this.nurseFrm.reset();
        this.emailService
          .sendEmail({
            Email: body.Email,
            Subject: 'Nurse  Login Credentials',
            Template: `<h3>Your Username ${userBody.UserName} please keep this safe. You may reset password now at https://binseraneurology.com. </h3> `,
          })
          .subscribe();

        const smsBodyDetails =
          'Your Username ' +
          userBody.UserName +
          ' You may reset password now at https://binseraneurology.com ';
        let smsBody = {
          PhoneNumber: phoneNumber,
          Body: smsBodyDetails,
        };
        this.emailService.sendSms(smsBody).subscribe();
        this.isSuccess = true;

        setTimeout(() => {}, 2000);
      });
    });
  }

  public countryCodes() {
    this.http.get('/assets/json/country-codes.json').subscribe({
      next: (res: any) => {
        this.Codes = res.map((x) => x.dial_code);
      },
    });
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.Codes.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }
  facingMode: string = 'user'; //Set front camera
  public allowCameraSwitch = true;

  public get videoOptions(): MediaTrackConstraints {
    const result: MediaTrackConstraints = {};
    if (this.facingMode && this.facingMode !== '') {
      result.facingMode = { ideal: this.facingMode };
    }
    return result;
  }
}
