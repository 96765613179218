import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import { PatientsPharmacy } from 'src/app/Models/patientspharmacy.model';
import { Prescription } from 'src/app/Models/prescription.model';
import { PatientService } from 'src/app/services/patient.service';
import { PrescriptionService } from 'src/app/services/prescription.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog-component';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatDialog } from '@angular/material/dialog';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Doctor } from 'src/app/Models/doctor.model';
import { DoctorService } from 'src/app/services/doctor.service';
type NewType = Prescription;

@Component({
  selector: 'app-card-view-prescription',
  templateUrl: './card-view-prescription.component.html',
  styleUrls: ['./card-view-prescription.component.css'],
})
export class CardViewPrescriptionComponent implements OnInit {
  @Output() navigation = new EventEmitter<any>();

  helper = new JwtHelperService();
  myToken = window.localStorage.getItem('token');
  id = 0;
  prescriptions: Prescription[];
  pharmacy: PatientsPharmacy[];
  prescriptionData: {};
  isSuccess: boolean;
  Doctor: Doctor;
  CurrentDoctorId: number;
  filteredList: Prescription[];

  constructor(
    private route: ActivatedRoute,
    private patientService: PatientService,
    private prescriptionService: PrescriptionService,
    private clipBoard: Clipboard,
    private dialog: MatDialog,
    private doctorService: DoctorService
  ) {}

  ngOnInit(): void {
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.CurrentDoctorId = parseInt(decodedToken.nameid);
    this.getDoctor(this.CurrentDoctorId);

    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    this.getPrescription(this.id);
    this.getPharmacy(this.id);
  }

  getPrescription(id) {
    this.patientService.getPatientPrescription(id).subscribe((res) => {
      this.prescriptions = res.Prescriptions.sort((x, y) => {
        return y.PrescriptionId - x.PrescriptionId;
      });
      this.filteredList = res.Prescriptions.sort((x, y) => {
        return y.PrescriptionId - x.PrescriptionId;
      });
    });
  }

  getDoctor(id) {
    this.doctorService.getDoctorById(id).subscribe((res) => {
      this.Doctor = res;
    });
  }
  tabChanged(event: MatTabChangeEvent): void {
    if (event.index) {
      this.getPrescription(this.id);
    }
  }
  getPharmacy(id) {
    this.patientService.getPatientsPharmacy(id).subscribe((res) => {
      this.pharmacy = res.PatientsPharmacys;
    });
  }
  delete(id) {
    this.prescriptionService.deletePrescription(id).subscribe((res) => {
      this.getPrescription(this.id);
    });
  }

  copy(e: Prescription) {
    this.prescriptionData = {
      DrugName: e.DrugName,
      Strength: e.Strength,
      BrandName: e.BrandName,
      Quantity: e.Quantity,
      DatePrescrib: e.DatePrescribed,
      Instruction: e.Instruction,
      MorningTime: e.MorningTime,
      AfternoonTim: e.AfternoonTime,
      EveningTime: e.EveningTime,
      NightTime: e.NightTime,
      QTime: e.QTime,
      Status: e.Status,
      StartDate: new Date().toLocaleDateString(),
      EndDate: e.EndDate,
      RefillDurati: e.RefillDuration,
      Numberofrefi: e.Numberofrefills,
      DoctorName: e.DoctorName,
      Note: e.Note,
      Route: e.Route,
      Frequency: e.Frequency,
      NoOfTablets: e.NoOfTablets,
      PatientId: e.PatientId,
      DoctorId: this.CurrentDoctorId,
    };
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Copy Prescription Data',
        content: 'Are you sure you want to copy data from this Prescription ?',
      },
    });
    // this.clipBoard.copy(e);

    dialogRef.afterClosed().subscribe({
      next: (res) => {
        if (res === true) {
          this.prescriptionService
            .registerPrescription(this.prescriptionData)
            .subscribe((res) => {
              this.isSuccess = true;
              this.getPrescription(this.id);
            });

          setTimeout(() => {
            this.navigation.emit();
          }, 2000);
        }
      },
    });
  }

  search(searchValue) {
    if (searchValue.value != null) {
      this.filteredList = this.prescriptions.filter((x) =>
        x.CreatedDate.toLowerCase().includes(searchValue.value.toLowerCase())
      );
    }
  }
  itemsToShow = 3;

  // Function to load more items
  loadMore() {
    this.itemsToShow += 2; // Increase the number of items to show by 3
  }

  // Function to check if there are more items to load
  hasMoreItems() {
    return this.itemsToShow < this.filteredList.length;
  }
}
