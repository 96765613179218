<mat-tab-group #tabRef1 (selectedIndexChange)="logIndex(tabRef1.selectedIndex)">

    <mat-tab label="New Lab Order">
        <ng-template matTabContent>
            <app-add-lab-order></app-add-lab-order>

        </ng-template>
    </mat-tab>
    <mat-tab label="New Radiology Order">
        <ng-template matTabContent>
            <app-add-radiology-order></app-add-radiology-order>

        </ng-template>
    </mat-tab>
    <mat-tab label="New Procedure Order">
        <ng-template matTabContent>
            <app-add-procedure></app-add-procedure>

        </ng-template>
    </mat-tab>
</mat-tab-group>

<!-- <mat-tab-group (selectedTabChange)="tabChanged($event)"> -->