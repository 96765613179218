<div class=" mat-elevation-z8 ">


    <table class="table table-responsive table-hover w-100">
        <thead>
            <tr>
                <th scope="col">Date</th>
                <th scope="col">Type</th>
                <th scope="col">Sub Type</th>
                <th scope="col">Nerve/Sites</th>
                <th scope="col">Rec. Site</th>
                <th scope="col">Latency</th>
                <th scope="col">Norm(ms)</th>
                <th scope="col">Amplitude</th>
                <th scope="col">Norm(ms)</th>
                <th scope="col">Amplitude Ratio(%)</th>
                <th scope="col">Duration (ms)</th>
                <th scope="col">Segments</th>
                <th scope="col">Distance (cm)</th>
                <th scope="col">Velocity(m/s)</th>
                <th scope="col">Norm (m/s)</th>
                <th scope="col">Comments</th>
                <th scope="col">Update</th>
                <th scope="col">Delete</th>
            </tr>
        </thead>
        <tbody>


            <tr *ngFor="let item of studyResult">

                <td>{{item.createdDate | date: 'short'}}</td>
                <td>{{item.StudyType}}</td>
                <td>{{item.StudySubType}}</td>
                <td>{{item.NerveSite}}</td>
                <td>{{item.RecSite}}</td>
                <td>{{item.LatencyMs}}</td>
                <td>{{item.LatencyNorm}}</td>
                <td>{{item.AmplitudeMv}}</td>
                <td>{{item.AmplitudeNorm}}</td>
                <td>{{item.AmplitudeRatio}}</td>
                <td>{{item.DurationMs}}</td>
                <td>{{item.Segment}}</td>
                <td>{{item.Distance}}</td>
                <td>{{item.VelocityMs}}</td>
                <td>{{item.VelocityNorm}}</td>
                <td>{{item.Comments}}</td>
                <td>
                    <button type="button" [routerLink]="['/updateNerveStudyResults/',item.NerveStudyResultId]"
                        mat-mini-fab color="primary">
                        <mat-icon matPrefix>edit</mat-icon>
                    </button>
                </td>
                <td>
                    <button mat-mini-fab color="accent"
                        (click)="delete(item.NerveStudyResultId)"><mat-icon>delete</mat-icon></button>
                </td>
            </tr>


        </tbody>
    </table>


</div>