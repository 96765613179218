import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { EmailPayload } from '../Models/email.model';
import { TextMessage } from '../Models/textmessage.model';
import { FaxPayload } from '../Models/fax.mode';

@Injectable({
  providedIn: 'root',
})
export class EmailService {
  url = environment.Url + 'Emails/email';
  smsUrl = environment.Url + 'Emails/sms';
  genericEmailUrl = environment.Url + 'Emails/genericEmail';
  genericFaxUrl = environment.Url + 'Emails/myGenericFax';

  header = new HttpHeaders({
    Authorization: 'token' + localStorage.getItem('token'),
  });

  constructor(private http: HttpClient) {}

  public sendEmail(payload: EmailPayload) {
    return this.http.post(this.url, payload);
  }
  public sendSms(smspayload: TextMessage) {
    return this.http.post(this.smsUrl, smspayload);
  }

  public sendGenericEmail(payload: EmailPayload) {
    return this.http.post(this.genericEmailUrl, payload);
  }

  public sendGenericFax(payload: FaxPayload) {
    return this.http.post(this.genericFaxUrl, payload);
  }
}
