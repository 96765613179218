import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Allergy } from 'src/app/Models/allergy.models';
import { EmergencyContact } from 'src/app/Models/emergencycontact.models';
import { Labreport } from 'src/app/Models/labreport.model';
import { Patient } from 'src/app/Models/patient.model';
import { PatientInsurance } from 'src/app/Models/patientinsurance.model';
import { Prescription } from 'src/app/Models/prescription.model';
import { SurgicalHistory } from 'src/app/Models/surgicalhistory.model';
import { PatientService } from 'src/app/services/patient.service';

@Component({
  selector: 'app-patientdashboard',
  templateUrl: './patientdashboard.component.html',
  styleUrls: ['./patientdashboard.component.css'],
})
export class PatientdashboardComponent implements OnInit {
  id = 0;
  helper = new JwtHelperService();

  myToken = window.localStorage.getItem('token');

  showItem = '';
  Patient: Patient;
  Insurance: PatientInsurance[] = [];
  emContacts: EmergencyContact[] = [];
  allergies: Allergy[] = [];
  surgeries: SurgicalHistory[] = [];
  prescriptions: Prescription[] = [];
  labReport: Labreport[] = [];
  constructor(
    private route: ActivatedRoute,
    private patientService: PatientService,
    private router: Router
  ) {}

  isNewPatient = false;
  ngOnInit(): void {
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.id = parseInt(decodedToken.nameid);
    this.getPatientById(this.id);
    this.getPtInsurance(this.id);
    this.getEmContact(this.id);
    this.getAllergyies(this.id);
    this.getSurgeries(this.id);
    this.getPrescription(this.id);
    this.GetlabReports(this.id);
  }

  getPatientById(id) {
    this.patientService.getPatientById(id).subscribe((res) => {
      this.Patient = res;
      this.isNewPatient =
        this.Patient.IsNewPatient == false ? false : this.Patient.IsNewPatient;
      // console.log(this.isNewPatient);
    });
  }

  getPtInsurance(id) {
    this.patientService.getPatientInsurance(id).subscribe((res) => {
      this.Insurance = res.PatientInsurances;
    });
  }
  getEmContact(id) {
    this.patientService.getPatientEMcontact(id).subscribe((res) => {
      this.emContacts = res.EmergencyContacts;
    });
  }
  getAllergyies(id) {
    this.patientService.getPatientAllergy(id).subscribe((res) => {
      this.allergies = res.Allergies;
    });
  }
  getSurgeries(id) {
    this.patientService.getPatientsurgery(id).subscribe((res) => {
      this.surgeries = res.SurgicalHistories;
    });
  }
  getPrescription(id) {
    this.patientService.getPatientPrescription(id).subscribe((res) => {
      this.prescriptions = res.Prescriptions;
    });
  }

  GetlabReports(id) {
    this.patientService.getPatienLabReport(id).subscribe((res) => {
      this.labReport = res.LabReports;
    });
  }

  logout() {
    localStorage.removeItem('token');
    this.router.navigate(['/']);
  }

  innerRouting(router: any) {
    this.showItem = router;
  }
}
