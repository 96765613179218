<div class="ml-3">
    <div class="row">
        <div class="col-lg-6 float-right">
            <mat-form-field class="example-full-width mt-5">
                <mat-label>Filter</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Ex.Mia" #input>
            </mat-form-field>
        </div>
    </div>
</div>
<div class=" mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" matSort>




        <ng-container matColumnDef="Date">
            <th mat-header-cell *matHeaderCellDef> Date </th>
            <td mat-cell *matCellDef="let row"> {{row.createdDate | date:'short'}} </td>
        </ng-container>

        <ng-container matColumnDef="View">
            <th mat-header-cell *matHeaderCellDef> View Report </th>
            <td mat-cell *matCellDef="let row">
                <button class="btn" (click)="modal3.show();viewRdiologyReport(row) " color="primary"
                    mat-mini-fab><mat-icon>visibility</mat-icon></button>&nbsp;

                <app-modal id="printable" #modal3 class="panel-demo1" [maximizable]="true" [backdrop]="false">
                    <ng-container class="app-modal-header">Patient Name: {{patient?.PatientFirstName}} &nbsp;
                        {{patient?.PatientLastName}}</ng-container>
                    <ng-container class="app-modal-body">
                        <div class="modal-body " id="printable">
                            <!-- <img src="{{radioDocument}}" alt=""> -->
                            <mat-card class="example-card">
                                <mat-card-header class="example-header" style="padding-left: 40%;">
                                </mat-card-header>
                                <mat-card-content>
                                    <div [innerHTML]="radioDocument "></div>

                                </mat-card-content>
                            </mat-card>

                        </div>


                    </ng-container>
                    <ng-container class="app-modal-footer">
                        <button type="button" (click)="print()" class="btn btn-primary ">Print</button>

                        <button class="dt-button dt-blue" style="float: right;" (click)="modal3.hide()">Close</button>

                    </ng-container>
                </app-modal>
                <!-- modal  -->
                <!-- <button type="button" (click)="viewRdiologyReport(row)" class="btn btn-primary" data-toggle="modal"
                    data-target="#lab_report_modal">
                    View Report
                </button>

                <div class="modal fade bd-example-modal-lg" id="lab_report_modal" tabindex="-1" role="dialog"
                    aria-labelledby="myLargeModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-lg">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLongTitle">
                                    Report
                                </h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body " id="printable">
                              
                                <mat-card class="example-card">
                                    <mat-card-header class="example-header" style="padding-left: 40%;">
                                    </mat-card-header>
                                    <mat-card-content>
                                        <div [innerHTML]="radioDocument "></div>

                                    </mat-card-content>
                                </mat-card>

                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">
                                    Close
                                </button>
                                <button type="button" (click)="print()" class="btn btn-secondary mx-3"
                                    data-dismiss="modal">
                                    Print
                                </button>
                            </div>
                        </div>
                    </div>
                </div> -->

                <!-- modal  -->
            </td>
        </ng-container>
        <!-- <ng-container matColumnDef="Update">
        <th mat-header-cell *matHeaderCellDef> Delete</th>
        <td mat-cell *matCellDef="let element">
          <button type="button" [routerLink]="['/updateRadiologyReport/',element.UploadRadiologyReportId]" mat-mini-fab
            color="primary">
            <mat-icon matPrefix>edit</mat-icon>
          </button>
        </td>
      </ng-container> -->
        <ng-container matColumnDef="Delete">
            <th mat-header-cell *matHeaderCellDef> Delete</th>
            <td mat-cell *matCellDef="let element">
                <button (click)="delete(element.UploadRadiologyReportId)" mat-mini-fab
                    color="accent"><mat-icon>delete</mat-icon></button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
        </tr>
    </table>

    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</div>