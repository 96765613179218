<mat-tab-group #tabRef1 (selectedIndexChange)="logIndex(tabRef1.selectedIndex)">

    <mat-tab label="Lab Order">
        <ng-template matTabContent>
            <app-view-lab-order></app-view-lab-order>

        </ng-template>
    </mat-tab>
    <mat-tab label="Radiology Order">
        <ng-template matTabContent>
            <app-view-radiology-order></app-view-radiology-order>

        </ng-template>
    </mat-tab>
    <mat-tab label="Procedure Order">
        <ng-template matTabContent>
            <app-view-procedure></app-view-procedure>

        </ng-template>
    </mat-tab>
</mat-tab-group>