<mat-tab-group #tabRef1 (selectedIndexChange)="logIndex(tabRef1.selectedIndex)">

    <mat-tab label="New Procedure Test Result">
        <ng-template matTabContent>
            <app-add-test-results></app-add-test-results>

        </ng-template>
    </mat-tab>
    <mat-tab label="New Nerve-study Result">
        <ng-template matTabContent>
            <app-add-nerve-study-results></app-add-nerve-study-results>

        </ng-template>
    </mat-tab>
    <mat-tab label="New Electromyography Result">
        <ng-template matTabContent>
            <app-add-electromyography></app-add-electromyography>

        </ng-template>
    </mat-tab>

</mat-tab-group>