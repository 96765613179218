import { Component } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'app-hospital-template',
  templateUrl: './hospital-template.component.html',
  styleUrls: ['./hospital-template.component.css'],
})
export class HospitalTemplateComponent {
  helper = new JwtHelperService();
  myToken = window.localStorage.getItem('token');

  id = 0;
  isSuccess = false;
  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    // let decodedToken = this.helper.decodeToken(this.myToken);
    // this.id = parseInt(decodedToken.nameid);
  }

  editorContent: string = 'Initial content'; // Your editor content

  // Update the content on every change in the editor
  onContentChanged(event: any) {
    this.editorContent = event.html; // Update the content based on Quill's HTML
  }
}
