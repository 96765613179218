<div class="container ">
    <div class="row">
        <div class="col-lg regFrm mat-elevation-z8">
            <h1>Upate Nurse Note/Report</h1>
            <form [formGroup]="Frm" (ngSubmit)="formSubmit()" class="example-form">
                <mat-form-field class="example-full-width">
                    <mat-label>Report Name</mat-label>
                    <textarea matInput (input)="onObjectiveChange($event)" formControlName="ReportName"
                        cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="2"
                        cdkAutosizeMaxRows="140" [value]="serviceObjective.Objective" matInput
                        (blur)="onObjectiveBlur()"></textarea>
                    <button type="button" class="app-speech-input-btn" [disabled]="listeningReportName"
                        (click)="startServiceObjective()"></button>
                    <mat-error>Please enter Report Name</mat-error>
                </mat-form-field>

                <div class="row">
                    <div class="col-lg-11">

                        <mat-form-field appearance="fill" class="example-full-width">
                            <mat-label>Descriptive reason for Order</mat-label>

                            <textarea matInput (input)="onSubjectiveChange($event)" formControlName="ReportDescription"
                                cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="2"
                                cdkAutosizeMaxRows="140" [value]="service.message1" matInput
                                (blur)="onSubjectiveBlur()"></textarea>
                            <button type="button" class="app-speech-input-btn" [disabled]="listening"
                                (click)="startService()"></button>
                        </mat-form-field>
                    </div>
                    <button type="button" color="accent" (click)="stopAll()"
                        mat-mini-fab><mat-icon>stop</mat-icon></button>
                </div>


                <button class="float-right lgBtCl" mat-button>
                    <mat-icon>save</mat-icon>
                    Save</button>

                <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
                    Successfully submitted
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </form>

        </div>
    </div>
</div>