<div class="container">
    <div class="row">
        <div class="col-lg regFrm mat-elevation-z8">
            <h1> Off Dates (Un-Available dates)</h1>
            <form [formGroup]="timFrm" (ngSubmit)="formSubmit()" class="example-form">
                <h4> Doctor Name: {{doctor?.DoctorName}}</h4>
                <hr>
                <p>Pleas add your off days below</p>
                <mat-form-field class="example-full-width">
                    <mat-label> Unavailable Date</mat-label>
                    <input type="date" matInput formControlName="AvailableDate">
                    <!-- <input [ngxTimepicker]="picker" matInput formControlName="TimeSlots">
<ngx-material-timepicker #picker></ngx-material-timepicker> -->
                    <mat-error>Please enter Unavailable Date</mat-error>
                </mat-form-field>




                <button [disabled]="timFrm.invalid" class="float-right lgBtCl" mat-button>
                    <mat-icon>save</mat-icon>
                </button>
                <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
                    Successfully submitted
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </form>

        </div>
    </div>
</div>