<div mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane">Patient MRN: {{data.userId}}</div>
<div mat-dialog-content>
    <div class="row">
        <div class="col-lg-6">
            <mat-tab-group>


                <mat-tab label="New Visit">
                    <ng-template matTabContent>

                        <app-add-patient-visit></app-add-patient-visit>

                    </ng-template>
                </mat-tab>

                <mat-tab label="New Progress Note">
                    <ng-template matTabContent>
                        <app-add-doctor-report></app-add-doctor-report>
                    </ng-template>
                </mat-tab>

                <mat-tab label="New Prescription">
                    <ng-template matTabContent>

                        <app-add-prescription></app-add-prescription>

                    </ng-template>
                </mat-tab>
                <mat-tab label="New Orders">
                    <ng-template matTabContent>
                        <app-new-orders></app-new-orders>


                    </ng-template>

                </mat-tab>
                <mat-tab label="New Initial Evaluation">
                    <ng-template matTabContent>
                        <app-add-doctor-initial-evaluation></app-add-doctor-initial-evaluation>


                    </ng-template>

                </mat-tab>
                <mat-tab label="New Rounds Book">
                    <ng-template matTabContent>

                        <app-draw-pad></app-draw-pad>

                    </ng-template>
                </mat-tab>
                <mat-tab label="New Refferal">
                    <ng-template matTabContent>
                        <app-add-refferal></app-add-refferal>


                    </ng-template>

                </mat-tab>
                <mat-tab label="New Invoice">
                    <ng-template matTabContent>

                        <app-add-invoice></app-add-invoice>

                    </ng-template>

                </mat-tab>
                <mat-tab label="New Appointment">
                    <ng-template matTabContent>

                        <app-add-appointment-by-doctor></app-add-appointment-by-doctor>

                    </ng-template>

                </mat-tab>
                <mat-tab label="New Test Reults">
                    <ng-template matTabContent>

                        <app-new-test-results></app-new-test-results>

                    </ng-template>

                </mat-tab>
                <mat-tab label="New Study Report">
                    <ng-template matTabContent>
                        <app-add-radiology-report></app-add-radiology-report>


                    </ng-template>

                </mat-tab>
                <mat-tab label="New Study Image">
                    <ng-template matTabContent>

                        <app-add-mri></app-add-mri>

                    </ng-template>

                </mat-tab>

            </mat-tab-group>
        </div>
        <div class="col-lg-6">
            <mat-tab-group>


                <mat-tab label="Visit Summary">
                    <ng-template matTabContent>

                        <app-doctor-patient-visit></app-doctor-patient-visit>

                    </ng-template>
                </mat-tab>

                <mat-tab label="Progress Note">
                    <ng-template matTabContent>
                        <app-view-doctor-report></app-view-doctor-report>

                    </ng-template>
                </mat-tab>

                <mat-tab label="Recent Prescription">
                    <ng-template matTabContent>

                        <app-prescription-display></app-prescription-display>


                    </ng-template>
                </mat-tab>




            </mat-tab-group>
        </div>
    </div>

</div>

<div mat-dialog-actions class="float-right">
    <button mat-button mat-dialog-close>Close</button>
    <!-- <button mat-button>Save</button> -->
</div>