import { Component, HostListener, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { Doctor } from 'src/app/Models/doctor.model';
import { Patient } from 'src/app/Models/patient.model';
import { HospitalService } from 'src/app/services/hospital.service';
import { PatientService } from 'src/app/services/patient.service';
import { ProcedureService } from 'src/app/services/procedure.service';
import { VoiceRecognitionService } from 'src/app/services/voice-recognition.service';

@Component({
  selector: 'app-add-procedure',
  templateUrl: './add-procedure.component.html',
  styleUrls: ['./add-procedure.component.css'],
})
export class AddProcedureComponent implements OnInit {
  id = 0;
  isSuccess = false;
  doctorList: Doctor[];
  hospitalId: number;
  flteredsubjectList: Observable<any>;
  listening = false;
  patient: Patient;

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private procedureService: ProcedureService,
    private hospitalService: HospitalService,
    private patientService: PatientService,
    public service: VoiceRecognitionService
  ) {
    this.service.init();
  }

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    this.getPatient(this.id);

    this.patientService.getpatienHospitalId(this.id).subscribe((res) => {
      this.hospitalId = res.Hospital.HospitalId;
      this.getDoctors(this.hospitalId);
    });
  }

  labFrm = this.fb.group({
    DoctorName: ['', Validators.required],
    ProcedureName: ['', Validators.required],
    Description: ['', Validators.required],
  });

  get DoctorName() {
    return this.labFrm.get('DoctorName');
  }
  get ProcedureName() {
    return this.labFrm.get('ProcedureName');
  }
  get Description() {
    return this.labFrm.get('Description');
  }

  startService() {
    this.service.start();
    this.listening = true;
  }

  stopService() {
    this.service.stop();
    this.listening = false;
  }

  onSubjectiveChange(e) {
    this.service.message1 = e.target.value;
  }

  onSubjectiveBlur() {
    this.stopService();
  }
  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode == KEY_CODE.DOWN_ARROW) {
      // Your row selection code
      // console.log(event);
      this.stopService();
    }
  }
  getDoctors(id) {
    this.hospitalService.getDoctors(id).subscribe((res) => {
      this.doctorList = res.Doctors;
    });
  }
  getPatient(id) {
    this.patientService.getPatientById(id).subscribe((res) => {
      this.patient = res;
    });
  }
  formSubmit() {
    let doctorName = this.labFrm.value['DoctorName'];
    let procedureName = this.labFrm.value['ProcedureName'];
    let description = this.labFrm.value['Description'];
    // let date = this.labFrm.value['Date'];
    // date = moment(date).format('MM/DD/YYYY');

    let body = {
      PatientId: this.id,
      DoctorName: doctorName,
      ProcedureName: procedureName,
      Description: description,
    };

    this.procedureService.registerProcedure(body).subscribe((res) => {
      this.isSuccess = true;
      this.labFrm.reset();
    });
  }
  resetFn(e) {
    if (e) {
      this.labFrm.reset();
    }
  }
}
export enum KEY_CODE {
  UP_ARROW = 38,
  DOWN_ARROW = 40,
  RIGHT_ARROW = 39,
  LEFT_ARROW = 37,
}
