import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { environment } from 'src/environments/environment';
import { ChatMessage } from '../Models/chatmessage.model';
import { catchError } from 'rxjs/operators';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { User } from '../Models/user.model';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  url = environment.Url + 'ChatMessages';

  header = new HttpHeaders({
    Authorization: 'token' + localStorage.getItem('token'),
  });

  constructor(private http: HttpClient) {}

  registerChatMessage(body): Observable<ChatMessage> {
    return this.http
      .post<ChatMessage>(this.url, body)
      .pipe(catchError(this.handleError));
  }

  getChatMessages(): Observable<ChatMessage[]> {
    return this.http
      .get<ChatMessage[]>(this.url)
      .pipe(catchError(this.handleError));
  }
  getChatMessageById(id): Observable<ChatMessage> {
    return this.http.get<ChatMessage>(this.url + '/' + id);
  }

  updateChatMessage(id, body): Observable<ChatMessage> {
    return this.http
      .put<ChatMessage>(this.url + '/' + id, body)
      .pipe(catchError(this.handleError));
  }

  deleteChatMessage(id): Observable<ChatMessage> {
    return this.http
      .delete<ChatMessage>(this.url + '/' + id)
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      if (error.status == 400) {
        console.log(error.error);
        return throwError(error.error);
      } else if (error.status == 401) {
        return throwError('invalid username or password');
      } else if (error.status == 0) {
        return throwError('network failure please try again later ');
      }
      return throwError(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }
}
