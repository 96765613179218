import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-new-test-results',
  templateUrl: './new-test-results.component.html',
  styleUrls: ['./new-test-results.component.css'],
})
export class NewTestResultsComponent implements OnInit {
  id = 0;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
  }
  logIndex(e) {}
}
