<div>

    <div class="toolbar mat-elevation-z6">

        <button mat-raised-button class="backG mx-5" [matMenuTriggerFor]="crt"><mat-icon>menu</mat-icon>Menu</button>
        <button type="button" (click)="logout()" class="float-right mx-2 my-2" color="info" mat-raised-button>
            Logout
        </button>
        <mat-menu class="ItemS " #crt="matMenu">
            <div class="ItemS">
                <mat-icon mat-list-icon>undo</mat-icon>
                <div class="mx-2" [routerLink]="['/doctorDash/']"> Doctor Dashboard</div>
            </div>
            <div class="ItemS " (click)="showItem='0'" [ngClass]="{'active': showItem =='0'}">
                <mat-icon mat-list-icon>dashboard</mat-icon>
                <div class="mx-2">Back</div>
                <mat-icon>navigate_next</mat-icon>
            </div>
            <div class="ItemS " (click)="showItem='1'" [ngClass]="{'active': showItem =='1'}">
                <mat-icon mat-list-icon>dashboard</mat-icon>
                <div class="mx-2">Lab Report</div>
                <mat-icon>navigate_next</mat-icon>
            </div>
            <hr>
        </mat-menu>
        <h2 class="ml-5 text-white text-center"><img class="nurseimg " src="{{Doctor?.DoctorImage}}" alt="">
            Dr. {{Doctor?.DoctorName}} <span class="text-white "> </span></h2>
    </div>

    <div class="main ">


        <div class="row ">
            <div class="col-md-1 ml-2 mt-2">

                <div class="patient-image" [ngStyle]="{
                    'background-image': 'url(' + patient?.PatientImage + ')'
                  }">

                </div>
                <br>
                <span style="font-weight: bold; font-size: medium; margin-bottom: 10px;">
                    {{patient?.PatientFirstName}}
                    {{patient?.PatientLastName}}</span> <br>
                MRN : {{patient?.PatientId}}, <br>

                <!-- Patient Name : {{patient.PatientFirstName}}
                {{patient.PatientLastName}}<br> -->


                D.O.B : {{patient?.PatientDOB}},<br>
                Sex : {{patient?.PatientGender}}<br>
                Address : {{patient?.PatientAddress}}
            </div>

            <div class="col-md-5  ">

                <mat-tab-group #tabRef1 (selectedIndexChange)="logIndex(tabRef1.selectedIndex)">

                    <!-- <mat-tab label="+ Visit"><app-add-patient-visit></app-add-patient-visit> </mat-tab> -->
                    <mat-tab label="New Progress Note"> <app-add-doctor-report></app-add-doctor-report> </mat-tab>
                    <mat-tab label="New Prescription">
                        <app-add-prescription></app-add-prescription>
                    </mat-tab>
                    <mat-tab label="New Orders">
                        <app-new-orders></app-new-orders>

                    </mat-tab>
                    <!-- <mat-tab label="New Initial Evaluation">
                        <app-add-doctor-initial-evaluation></app-add-doctor-initial-evaluation>

                    </mat-tab> -->
                    <mat-tab label="New Rounds Book">
                        <app-draw-pad></app-draw-pad>
                    </mat-tab>
                    <mat-tab label="New Refferal">
                        <app-add-refferal></app-add-refferal>

                    </mat-tab>
                    <mat-tab label="New Invoice">
                        <app-add-invoice></app-add-invoice>

                    </mat-tab>
                    <!-- <mat-tab label="New Appointment">
                        <app-add-appointment-by-doctor></app-add-appointment-by-doctor>

                    </mat-tab>
                    <mat-tab label="New Test Reults">
                        <app-new-test-results></app-new-test-results>

                    </mat-tab>
                    <mat-tab label="New Study Report">
                        <app-add-radiology-report></app-add-radiology-report>

                    </mat-tab>
                    <mat-tab label="New Study Image">
                        <app-add-mri></app-add-mri>

                    </mat-tab> -->

                </mat-tab-group>
            </div>
            <div class="col ">
                <div>
                    <app-tabs-component [tabsArray]="tabs" (onTabChange)="tabChange($event)"></app-tabs-component>
                </div>
                <div *ngIf="activatedTabIndex ==0">
                    <app-doctor-patient-visit></app-doctor-patient-visit>
                </div>

                <div *ngIf="activatedTabIndex ==1">
                    <app-view-doctor-report></app-view-doctor-report>
                </div>
                <div *ngIf="activatedTabIndex ==2">
                    <app-prescription-display></app-prescription-display>

                </div>
                <div *ngIf="activatedTabIndex ==3">
                    <app-view-labreport></app-view-labreport>
                </div>
                <div *ngIf="activatedTabIndex ==4">
                    <app-view-radiology-report></app-view-radiology-report>
                </div>
                <div *ngIf="activatedTabIndex ==5">
                    <app-view-orders></app-view-orders>
                </div>
                <div *ngIf="activatedTabIndex ==6">
                    <app-all-test-results></app-all-test-results>

                </div>
                <div *ngIf="activatedTabIndex ==7">
                    <app-view-radiology-report></app-view-radiology-report>

                </div>
                <div *ngIf="activatedTabIndex ==8">
                    <app-view-nursereport></app-view-nursereport>

                </div>
                <div *ngIf="activatedTabIndex ==9">
                    <app-view-nurse-note></app-view-nurse-note>

                </div>
                <div *ngIf="activatedTabIndex ==10">
                    <app-view-doctor-initial-evaluation></app-view-doctor-initial-evaluation>


                </div>
                <div *ngIf="activatedTabIndex ==11">
                    <app-view-refferal></app-view-refferal>


                </div>
                <div *ngIf="activatedTabIndex ==12">
                    <app-view-doctor-note></app-view-doctor-note>


                </div>
                <div *ngIf="activatedTabIndex ==13">
                    <app-view-invoice></app-view-invoice>


                </div>
                <div *ngIf="activatedTabIndex ==14">
                    <app-view-mri></app-view-mri>


                </div>


            </div>
            <!-- <div *ngIf="activatedTabIndex ==4">
                </div> -->
        </div>
    </div>










</div>