<div class="container">
    <!-- <div class="row mt-3"> -->
    <!-- <div class="col-lg-3 ">

            Patient Name : {{patient.PatientFirstName}}
            {{patient.PatientLastName}},
        </div>
        <div class="col-lg-5">

            D.O.B : {{patient.PatientDOB}},
            Patient No : {{patient.PatientId}}

        </div>
        <div class="col-lg-4"></div>
    </div> -->
    <div class="row">
        <div class="col-lg regFrm mat-elevation-z8">

            <form [formGroup]="Frm" (ngSubmit)="formSubmit()" class="example-form">
                <h1 class="mx-5 my-5">New- Nerve Conduction Studies </h1>
                <div class="row">
                    <div class="col-lg-4">
                        <mat-form-field class="example-full-width">
                            <mat-label>Study Type</mat-label>

                            <mat-select formControlName="StudyType">
                                <mat-option *ngFor="let op of studyType" [value]="op">{{
                                    op
                                    }}</mat-option>
                            </mat-select>
                            <mat-error>Study Type required</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-4">
                        <mat-form-field class="example-full-width">
                            <mat-label>Study Type</mat-label>


                            <mat-select formControlName="StudySubType">
                                <mat-option *ngFor="let stype of studySubType" [value]="stype">{{
                                    stype
                                    }}</mat-option>
                            </mat-select>
                            <mat-error>Study Sub Type required</mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-3">
                        <mat-form-field class="example-full-width">
                            <mat-label>Nerve/Sites</mat-label>
                            <input type="text" formControlName="NerveSite" matInput>
                            <mat-error>Please enter a</mat-error>

                        </mat-form-field>
                    </div>
                    <div class="col-lg-3">
                        <mat-form-field class="example-full-width">
                            <mat-label>Rec. Site</mat-label>
                            <input type="text" formControlName="RecSite" matInput>
                            <mat-error>Please enter a value</mat-error>

                        </mat-form-field>
                    </div>
                    <div class="col-lg-3">
                        <mat-form-field class="example-full-width">
                            <mat-label>Latency (ms)</mat-label>
                            <input type="text" formControlName="LatencyMs" matInput>
                            <mat-error>Please enter a value</mat-error>

                        </mat-form-field>
                    </div>
                    <div class="col-lg-3">
                        <mat-form-field class="example-full-width">
                            <mat-label>Ref(ms)</mat-label>
                            <input type="text" formControlName="LatencyNorm" matInput>
                            <mat-error>Please enter a value</mat-error>

                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-3">
                        <mat-form-field class="example-full-width">
                            <mat-label>Amplitude (mV)</mat-label>
                            <input type="text" formControlName="AmplitudeMv" matInput>
                            <mat-error>Please enter a value</mat-error>

                        </mat-form-field>
                    </div>

                    <div class="col-lg-3">
                        <mat-form-field class="example-full-width">
                            <mat-label>Norm/Ref(mV)</mat-label>
                            <input type="text" formControlName="AmplitudeNorm" matInput>
                            <mat-error>Please enter a value</mat-error>

                        </mat-form-field>
                    </div>
                    <div class="col-lg-3">
                        <mat-form-field class="example-full-width">
                            <mat-label>Amplitude Ratio %</mat-label>
                            <input type="text" formControlName="AmplitudeRatio" matInput>
                            <mat-error>Please enter a value</mat-error>

                        </mat-form-field>
                    </div>
                    <div class="col-lg-3">
                        <mat-form-field class="example-full-width">
                            <mat-label>Duration (ms)</mat-label>
                            <input type="text" formControlName="DurationMs" matInput>
                            <mat-error>Please enter a value</mat-error>

                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-3">
                        <mat-form-field class="example-full-width">
                            <mat-label>Segments</mat-label>
                            <input type="text" formControlName="Segment" matInput>
                            <mat-error>Please enter a value</mat-error>

                        </mat-form-field>
                    </div>

                    <div class="col-lg-3">
                        <mat-form-field class="example-full-width">
                            <mat-label>Distance (mm)</mat-label>
                            <input type="text" formControlName="Distance" matInput>
                            <mat-error>Please enter a value</mat-error>

                        </mat-form-field>
                    </div>
                    <div class="col-lg-3">
                        <mat-form-field class="example-full-width">
                            <mat-label>Velocity (m/s)</mat-label>
                            <input type="text" formControlName="VelocityMs" matInput>
                            <mat-error>Please enter a value</mat-error>

                        </mat-form-field>
                    </div>
                    <div class="col-lg-3">
                        <mat-form-field class="example-full-width">
                            <mat-label> Norm / Ref(m/s)</mat-label>
                            <input type="text" formControlName="VelocityNorm" matInput>
                            <mat-error>Please enter a value</mat-error>

                        </mat-form-field>
                    </div>

                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <mat-form-field class="example-full-width">
                            <mat-label> Comments</mat-label>
                            <textarea matInput formControlName="Comments" cdkTextareaAutosize
                                #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="140"></textarea>
                            <mat-error>Please enter a value</mat-error>

                        </mat-form-field>
                    </div>
                </div>
                <button type="submit" [disabled]="Frm.invalid" class="float-right lgBtCl" mat-button>
                    <mat-icon>save</mat-icon>
                </button>
                <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
                    Successfully submitted
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </form>
            <button class="float-right btn-danger mx-3" (click)="resetFn($event)" mat-raised-button>
                reset
            </button>
        </div>

    </div>
</div>