import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import moment from 'moment';
import { Doctor } from 'src/app/Models/doctor.model';
import { Patient } from 'src/app/Models/patient.model';
import { DoctorService } from 'src/app/services/doctor.service';
import { HospitalService } from 'src/app/services/hospital.service';
import { PatientService } from 'src/app/services/patient.service';
import { RefferalService } from 'src/app/services/refferal.service';
import { VoiceRecognitionService } from 'src/app/services/voice-recognition.service';

@Component({
  selector: 'app-update-refferal',
  templateUrl: './update-refferal.component.html',
  styleUrls: ['./update-refferal.component.css'],
})
export class UpdateRefferalComponent implements OnInit {
  id = 0;
  isSuccess = false;
  doctorList: Doctor;
  helper = new JwtHelperService();
  myToken = window.localStorage.getItem('token');
  hospitalId = 0;
  listening = false;
  patient: Patient;
  patientId: any;
  doctorId: number;

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private doctorService: DoctorService,
    private referalService: RefferalService,
    private hospitalService: HospitalService,
    public service: VoiceRecognitionService,
    private patientService: PatientService,
    private router: Router
  ) {
    this.service.init();
  }

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
      this.patientId = res['PatientId'];
    });
    this.referalService.getRefferalById(this.id).subscribe((res) => {
      this.labFrm.patchValue(res);
    });
    this.getPatient(this.patientId);

    let decodedToken = this.helper.decodeToken(this.myToken);
    this.doctorId = parseInt(decodedToken.nameid);
    this.getDoctors(this.doctorId);
  }

  labFrm = this.fb.group({
    RefferingDoctorName: ['', Validators.required],
    RefferedToDoctorName: ['', Validators.required],
    Subject: ['', Validators.required],
    Description: ['', Validators.required],
    Date: [''],
    Email: ['', Validators.required],
    Phone: ['', Validators.required],
    Address: ['', Validators.required],
  });

  get RefferedToDoctorName() {
    return this.labFrm.get('RefferedToDoctorName');
  }

  get RefferingDoctorName() {
    return this.labFrm.get('RefferingDoctorName');
  }
  get Address() {
    return this.labFrm.get('Address');
  }
  get Phone() {
    return this.labFrm.get('Phone');
  }
  get Email() {
    return this.labFrm.get('Email');
  }
  get Subject() {
    return this.labFrm.get('Subject');
  }
  get Description() {
    return this.labFrm.get('Description');
  }
  get Date() {
    return this.labFrm.get('Date');
  }
  startService() {
    this.service.start();
    this.listening = true;
  }

  stopService() {
    this.service.stop();
    this.listening = false;
  }

  onSubjectiveChange(e) {
    this.service.message1 = e.target.value;
  }

  onSubjectiveBlur() {
    this.stopService();
  }
  getDoctors(id) {
    this.doctorService.getDoctorById(id).subscribe((res) => {
      this.doctorList = res;
    });
  }
  getPatient(id) {
    this.patientService.getPatientById(id).subscribe((res) => {
      this.patient = res;
    });
  }
  formSubmit() {
    let refferedToDoctorName = this.labFrm.value['RefferedToDoctorName'];
    let refferingDoctorName = this.labFrm.value['RefferingDoctorName'];
    let address = this.labFrm.value['Address'];
    let phone = this.labFrm.value['Phone'];
    let email = this.labFrm.value['Email'];
    let subject = this.labFrm.value['Subject'];
    let description = this.labFrm.value['Description'];
    let date = this.labFrm.value['Date'];
    date = moment(date).format('MM/DD/YYYY');

    let body = {
      PatientId: this.patientId,
      RefferingDoctorName: refferingDoctorName,
      RefferedToDoctorName: refferedToDoctorName,
      Subject: subject,
      Email: email,
      Phone: phone,
      Address: address,
      Description: description,
      Date: date,
    };

    this.referalService.updateRefferal(this.id, body).subscribe((res) => {
      this.isSuccess = true;
      this.router.navigate(['/splitScreenDctorPatient/', this.patientId]);
    });
  }
}
