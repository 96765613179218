<div>


  <div class=" toolbar mat-elevation-z6">

    <button mat-raised-button class="backG mx-5" [matMenuTriggerFor]="crt"><mat-icon>menu</mat-icon>Menu</button>

    <button type="button" (click)="logout()" class="float-right mx-2 my-2" color="info" mat-raised-button>
      Logout
    </button>
    <mat-menu class="ItemS " #crt="matMenu">
      <div class="ItemS">
        <mat-icon mat-list-icon>undo</mat-icon>
        <div class="mx-2" [routerLink]="['/doctorDash/']"> Doctor Dashboard</div>
      </div>
      <div class="ItemS " (click)="showItem='27'" [ngClass]="{'active': showItem =='27'}">
        <mat-icon mat-list-icon>dashboard</mat-icon>
        <div class="mx-2">Back</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <hr>
      <!-- 
      <div class="ItemS" (click)="showItem='62'" [ngClass]="{'active': showItem =='62'}">
        <mat-icon mat-list-icon>view_array</mat-icon>
        <div class="mx-2">Add + View Prescription</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <hr> -->

      <div class="ItemS " (click)="showItem='46'" [ngClass]="{'active': showItem =='46'}">
        <mat-icon mat-list-icon>chat_apps_script</mat-icon>
        <div class="mx-2">Communication-Assessment</div>
        <mat-icon>navigate_next</mat-icon>
      </div>

      <div class="ItemS " (click)="showItem='49'" [ngClass]="{'active': showItem =='49'}">
        <mat-icon mat-list-icon>visibility</mat-icon>
        <div class="mx-2">Doctor Report</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <hr>
      <div class="ItemS" (click)="showItem='54'" [ngClass]="{'active': showItem =='54'}">
        <mat-icon mat-list-icon>visibility</mat-icon>
        <div class="mx-2"> Doctor Initial Evaluation</div>
        <mat-icon>navigate_next</mat-icon>
      </div>

      <div class="ItemS" (click)="showItem='11'" [ngClass]="{'active': showItem =='11'}">
        <mat-icon mat-list-icon>bug_report</mat-icon>
        <div class="mx-2">Lab-Report</div>
        <mat-icon>navigate_next</mat-icon>
      </div>



      <div class="ItemS " (click)="showItem='52'" [ngClass]="{'active': showItem =='52'}">
        <mat-icon mat-list-icon>report</mat-icon>
        <div class="mx-2">Nurse Report/Notes</div>
        <mat-icon>navigate_next</mat-icon>
      </div>

      <div class="ItemS " (click)="showItem='1'" [ngClass]="{'active': showItem =='1'}">
        <mat-icon mat-list-icon>nature_people</mat-icon>
        <div class="mx-2">Patient Summary</div>
        <mat-icon>navigate_next</mat-icon>
      </div>


      <div class="ItemS" (click)="showItem='9'" [ngClass]="{'active': showItem =='9'}">
        <mat-icon mat-list-icon>note_add</mat-icon>
        <div class="mx-2">Prescription</div>
        <mat-icon>navigate_next</mat-icon>
      </div>


      <div class="ItemS " (click)="showItem='21'" [ngClass]="{'active': showItem =='21'}">
        <mat-icon mat-list-icon>report</mat-icon>
        <div class="mx-2">Vitals Report</div>
        <mat-icon>navigate_next</mat-icon>
      </div>

      <div class="ItemS " (click)="showItem='41'" [ngClass]="{'active': showItem =='41'}">
        <mat-icon mat-list-icon>report</mat-icon>
        <div class="mx-2">Vital Chart</div>
        <mat-icon>navigate_next</mat-icon>
      </div>


      <div class="ItemS" (click)="showItem='55'" [ngClass]="{'active': showItem =='55'}">
        <mat-icon mat-list-icon>water_pump</mat-icon>
        <div class="mx-2">Lab Chart</div>

        <mat-icon>navigate_next</mat-icon>
      </div>



      <hr>

      <!-- 
          <div class="ItemS" (click)="showItem='2'" [ngClass]="{'active': showItem =='2'}">
            <mat-icon mat-list-icon>add</mat-icon>
            <div class="mx-2"> Emergency Contact</div>
            <mat-icon>navigate_next</mat-icon>
          </div> -->

      <!-- <div class="ItemS" (click)="showItem='3'" [ngClass]="{'active': showItem =='3'}">
            <mat-icon mat-list-icon>add</mat-icon>
            <div class="mx-2">New Inusurance</div>
            <mat-icon>navigate_next</mat-icon>
          </div> -->
      <!-- <div class="ItemS" (click)="showItem='36'" [ngClass]="{'active': showItem =='36'}">
            <mat-icon mat-list-icon>visibility</mat-icon>
            <div class="mx-2">View Inusurance</div>
            <mat-icon>navigate_next</mat-icon>
          </div> -->
      <!-- <div class="ItemS" (click)="showItem='37'" [ngClass]="{'active': showItem =='37'}">
            <mat-icon mat-list-icon>add</mat-icon>
            <div class="mx-2">New ID Proof</div>
            <mat-icon>navigate_next</mat-icon>
          </div> -->
      <!-- <div class="ItemS" (click)="showItem='38'" [ngClass]="{'active': showItem =='38'}">
            <mat-icon mat-list-icon>visibility</mat-icon>
            <div class="mx-2">View ID Proof </div>
            <mat-icon>navigate_next</mat-icon>
          </div>
          <hr> -->
      <hr>
      <div class="ItemS" (click)="showItem='5'" [ngClass]="{'active': showItem =='5'}">
        <mat-icon mat-list-icon>add</mat-icon>
        <div class="mx-2"> New Appointment</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <hr>

      <div class="ItemS" (click)="showItem='4'" [ngClass]="{'active': showItem =='4'}">
        <mat-icon mat-list-icon>add</mat-icon>
        <div class="mx-2"> New Visit</div>
        <mat-icon>navigate_next</mat-icon>
      </div>




      <div class="ItemS" (click)="showItem='48'" [ngClass]="{'active': showItem =='48'}">
        <mat-icon mat-list-icon>add</mat-icon>
        <div class="mx-2"> New Doctor Report</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS" (click)="showItem='53'" [ngClass]="{'active': showItem =='53'}">
        <mat-icon mat-list-icon>add</mat-icon>
        <div class="mx-2"> New Doctor Initial Evaluation</div>
        <mat-icon>navigate_next</mat-icon>
      </div>


      <div class="ItemS" (click)="showItem='28'" [ngClass]="{'active': showItem =='28'}">
        <mat-icon mat-list-icon>add</mat-icon>
        <div class="mx-2"> New Lab-Order</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS" (click)="showItem='63'" [ngClass]="{'active': showItem =='63'}">
        <mat-icon mat-list-icon>add</mat-icon>
        <div class="mx-2"> New Procedure-Order</div>
        <mat-icon>navigate_next</mat-icon>
      </div>


      <div class="ItemS" (click)="showItem='29'" [ngClass]="{'active': showItem =='29'}">
        <mat-icon mat-list-icon>add</mat-icon>
        <div class="mx-2">New Radiology Order</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS" (click)="showItem='6'" [ngClass]="{'active': showItem =='6'}">
        <mat-icon mat-list-icon>add</mat-icon>
        <div class="mx-2">New Round Book</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS" (click)="showItem='60'" [ngClass]="{'active': showItem =='60'}">
        <mat-icon mat-list-icon>add</mat-icon>
        <div class="mx-2">New Refferal</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS" (click)="showItem='65'" [ngClass]="{'active': showItem =='65'}">
        <mat-icon mat-list-icon>add</mat-icon>
        <div class="mx-2">Create Procedure Test Result</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS" (click)="showItem='68'" [ngClass]="{'active': showItem =='68'}">
        <mat-icon mat-list-icon>add</mat-icon>
        <div class="mx-2">Create Nerve Study Result</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS" (click)="showItem='69'" [ngClass]="{'active': showItem =='69'}">
        <mat-icon mat-list-icon>add</mat-icon>
        <div class="mx-2">Create Electromyography Result</div>
        <mat-icon>navigate_next</mat-icon>
      </div>

      <div class="ItemS" (click)="showItem='35'" [ngClass]="{'active': showItem =='35'}">
        <mat-icon mat-list-icon>add</mat-icon>
        <div class="mx-2">Create Radiology-Report</div>
        <mat-icon>navigate_next</mat-icon>
      </div>

      <hr>
      <div class="ItemS" (click)="showItem='18'" [ngClass]="{'active': showItem =='18'}">
        <mat-icon mat-list-icon>note</mat-icon>
        <div class="mx-2"> Roundbook</div>
        <mat-icon>navigate_next</mat-icon>
      </div>


      <div class="ItemS" (click)="showItem='16'" [ngClass]="{'active': showItem =='16'}">
        <mat-icon mat-list-icon>add</mat-icon>
        <div class="mx-2">New Invoice</div>
        <mat-icon>navigate_next</mat-icon>
      </div>

      <div class="ItemS" (click)="showItem='17'" [ngClass]="{'active': showItem =='17'}">
        <mat-icon mat-list-icon>receipt</mat-icon>
        <div class="mx-2">View Invoice</div>
        <mat-icon>navigate_next</mat-icon>
      </div>

      <hr>

      <!-- <div class="ItemS" (click)="showItem='8'" [ngClass]="{'active': showItem =='8'}">
            <mat-icon mat-list-icon>add</mat-icon>
            <div class="mx-2">New Script</div>
            <mat-icon>navigate_next</mat-icon>
          </div> -->


      <div class="ItemS" (click)="showItem='30'" [ngClass]="{'active': showItem =='30'}">
        <mat-icon mat-list-icon>visibility</mat-icon>
        <div class="mx-2"> View-Lab-Order</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS" (click)="showItem='64'" [ngClass]="{'active': showItem =='64'}">
        <mat-icon mat-list-icon>visibility</mat-icon>
        <div class="mx-2"> View-Procedure-Order</div>
        <mat-icon>navigate_next</mat-icon>
      </div>

      <div class="ItemS" (click)="showItem='61'" [ngClass]="{'active': showItem =='61'}">
        <mat-icon mat-list-icon>file_open</mat-icon>
        <div class="mx-2"> View Refferal</div>
        <mat-icon>navigate_next</mat-icon>
      </div>

      <div class="ItemS" (click)="showItem='31'" [ngClass]="{'active': showItem =='31'}">
        <mat-icon mat-list-icon>visibility</mat-icon>
        <div class="mx-2"> View Radiology Order</div>
        <mat-icon>navigate_next</mat-icon>
      </div>




      <hr>





      <div class="ItemS" (click)="showItem='34'" [ngClass]="{'active': showItem =='34'}">
        <mat-icon mat-list-icon>radiology</mat-icon>
        <div class="mx-2">Radiology-Report</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <hr>

      <div class="ItemS" (click)="showItem='66'" [ngClass]="{'active': showItem =='66'}">
        <mat-icon mat-list-icon>visibility</mat-icon>
        <div class="mx-2">Procedure Test Result</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS" (click)="showItem='67'" [ngClass]="{'active': showItem =='76'}">
        <mat-icon mat-list-icon>visibility</mat-icon>
        <div class="mx-2">Nerve Study Result</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS" (click)="showItem='70'" [ngClass]="{'active': showItem =='70'}">
        <mat-icon mat-list-icon>visibility</mat-icon>
        <div class="mx-2">Electromyography Result</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <hr>







      <!-- <div class="ItemS" (click)="showItem='32'" [ngClass]="{'active': showItem =='32'}">
        <mat-icon mat-list-icon>analytics</mat-icon>
        <div class="mx-2">Nursing Assessment</div>
        <mat-icon>navigate_next</mat-icon>
      </div> -->


      <hr>
      <div class="ItemS" (click)="showItem='39'" [ngClass]="{'active': showItem =='39'}">
        <mat-icon mat-list-icon>show_chart</mat-icon>
        <div class="mx-2">Upload MRI</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS" (click)="showItem='40'" [ngClass]="{'active': showItem =='40'}">
        <mat-icon mat-list-icon>view_array</mat-icon>
        <div class="mx-2">View MRI images</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <hr>
      <hr>
      <div class="ItemS" (click)="showItem='58'" [ngClass]="{'active': showItem =='58'}">
        <mat-icon mat-list-icon>show_chart</mat-icon>
        <div class="mx-2">Upload Radiology Doc</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS" (click)="showItem='59'" [ngClass]="{'active': showItem =='59'}">
        <mat-icon mat-list-icon>view_array</mat-icon>
        <div class="mx-2">View Radiology Doc</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <hr>
      <div class="ItemS" (click)="showItem='56'" [ngClass]="{'active': showItem =='56'}">
        <mat-icon mat-list-icon>show_chart</mat-icon>
        <div class="mx-2">Upload Lab Doc</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS" (click)="showItem='57'" [ngClass]="{'active': showItem =='57'}">
        <mat-icon mat-list-icon>view_array</mat-icon>
        <div class="mx-2">View Lab Doc</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <hr>





      <!-- <div class="ItemS" (click)="showItem='42'" [ngClass]="{'active': showItem =='42'}">
          <mat-icon mat-list-icon>title</mat-icon>
          <div class="mx-2">Radiology Order Subjects</div>
          <mat-icon>navigate_next</mat-icon>
        </div> -->
      <div class="ItemS">
        <mat-icon mat-list-icon>undo</mat-icon>
        <div class="mx-2" [routerLink]="['/doctorDash/']"> Doctor Dashboard</div>
        <!-- <mat-icon>navigate_next</mat-icon> -->
      </div>
    </mat-menu>
    <h2 class="ml-5 text-white text-center"><img class="nurseimg " src="{{doctor?.DoctorImage}}" alt="">
      {{doctor?.DoctorName}} <span class="text-white "> </span></h2>
    <!-- 
    <table>

      <tr>
        <td>Patient Name:{{patient.PatientFirstName}} {{patient.PatientLastName}}</td>
        <td>D.O.B: {{patient.PatientDOB}},</td>
        <td>Sex: {{patient.PatientGender}},</td>
        <td> Address: {{patient.PatientAddress}}</td>
      </tr>
    </table> -->
    <div class="row ml-5">
      <div class="col-lg-4 ">
        MRN : {{patient.PatientId}}, <br>

        Patient Name : {{patient.PatientFirstName}}
        {{patient.PatientLastName}},<br>


        D.O.B : {{patient.PatientDOB}},<br>
        Sex : {{patient.PatientGender}},
        Address : {{patient.PatientAddress}},


      </div>
    </div>


    <div class="main ">
      <!--  <div class="toolbar mat-elevation-z6" ></div> -->


      <div [ngSwitch]="showItem">



        <p *ngSwitchCase="'1'">
          <app-patientsummary></app-patientsummary>
        </p>
        <p *ngSwitchCase="'2'">
          <app-add-emergency-contact></app-add-emergency-contact>
        </p>

        <p *ngSwitchCase="'3'">
          <app-add-patient-insurance></app-add-patient-insurance>
        </p>
        <p *ngSwitchCase="'4'">
          <app-add-patient-visit></app-add-patient-visit>
        </p>
        <p *ngSwitchCase="'5'">
          <app-add-appointment-by-doctor></app-add-appointment-by-doctor>
        </p>
        <p *ngSwitchCase="'6'">
          <app-draw-pad></app-draw-pad>
        </p>
        <p *ngSwitchCase="'7'">
          <app-vew-doctor-note></app-vew-doctor-note>
        </p>
        <p *ngSwitchCase="'8'">
          <app-add-prescription></app-add-prescription>
        </p>
        <p *ngSwitchCase="'9'">
          <app-prescription-display></app-prescription-display>
        </p>
        <!-- <p *ngSwitchCase="'9'">
      <app-prescription-display></app-prescription-display>
    </p> -->
        <p *ngSwitchCase="'10'">
          <app-add-labreport></app-add-labreport>
        </p>
        <p *ngSwitchCase="'11'">
          <app-view-labreport></app-view-labreport>
        </p>
        <p *ngSwitchCase="'12'">
          <app-add-allergy></app-add-allergy>
        </p>
        <p *ngSwitchCase="'13'">
          <app-view-allergy></app-view-allergy>
        </p>

        <p *ngSwitchCase="'14'">
          <app-add-surgical-history></app-add-surgical-history>
        </p>
        <p *ngSwitchCase="'15'">
          <app-view-surgical-history></app-view-surgical-history>
        </p>
        <p *ngSwitchCase="'16'">
          <app-add-invoice></app-add-invoice>
        </p>
        <p *ngSwitchCase="'17'">
          <app-view-invoice></app-view-invoice>
        </p>
        <p *ngSwitchCase="'18'">
          <app-vew-doctor-note></app-vew-doctor-note>
        </p>
        <p *ngSwitchCase="'19'">
          <app-patientdetails></app-patientdetails>
        </p>


        <p *ngSwitchCase="'21'">
          <app-view-nursereport></app-view-nursereport>
        </p>



        <p *ngSwitchCase="'35'">
          <app-add-radiology-report></app-add-radiology-report>
        </p>


        <p *ngSwitchCase="'26'">
          <app-patient></app-patient>
        </p>
        <p *ngSwitchCase="'27'">
          <app-doctor-patient-visit></app-doctor-patient-visit>

        </p>
        <p *ngSwitchCase="'28'">
          <app-add-lab-order></app-add-lab-order>

        </p>
        <p *ngSwitchCase="'29'">
          <app-add-radiology-order></app-add-radiology-order>

        </p>
        <p *ngSwitchCase="'30'">
          <app-view-lab-order></app-view-lab-order>

        </p>
        <p *ngSwitchCase="'31'">
          <app-view-radiology-order></app-view-radiology-order>

        </p>

        <!-- <p *ngSwitchCase="'32'">
      <app-view-nursing-assessment-plan></app-view-nursing-assessment-plan>

    </p> -->



        <p *ngSwitchCase="'34'">
          <app-view-radiology-report></app-view-radiology-report>
        </p>

        <p *ngSwitchCase="'36'">
          <app-view-patient-insurance></app-view-patient-insurance>
        </p>

        <p *ngSwitchCase="'37'">
          <app-add-patient-idproof></app-add-patient-idproof>
        </p>

        <p *ngSwitchCase="'38'">
          <app-view-patient-idproof></app-view-patient-idproof>
        </p>
        <p *ngSwitchCase="'39'">
          <app-add-mri></app-add-mri>
        </p>

        <p *ngSwitchCase="'40'">
          <app-view-mri></app-view-mri>
        </p>

        <p *ngSwitchCase="'41'">
          <app-vitals-chart></app-vitals-chart>
        </p>



        <p *ngSwitchCase="'46'">
          <app-view-communication-assessment></app-view-communication-assessment>
        </p>



        <p *ngSwitchCase="'48'">
          <app-add-doctor-report></app-add-doctor-report>
        </p>


        <p *ngSwitchCase="'49'">
          <app-view-doctor-report></app-view-doctor-report>
        </p>


        <p *ngSwitchCase="'50'">
          <app-add-prescription-array></app-add-prescription-array>
        </p>


        <p *ngSwitchCase="'52'">
          <app-view-nurse-note></app-view-nurse-note>
        </p>
        <p *ngSwitchCase="'53'">
          <app-add-doctor-initial-evaluation></app-add-doctor-initial-evaluation>
        </p>
        <p *ngSwitchCase="'54'">
          <app-view-doctor-initial-evaluation></app-view-doctor-initial-evaluation>
        </p>

        <p *ngSwitchCase="'55'">
          <app-lab-test-chart></app-lab-test-chart>

        </p>
        <p *ngSwitchCase="'56'">
          <app-add-upload-lab-repor></app-add-upload-lab-repor>

        </p>
        <p *ngSwitchCase="'57'">
          <app-view-upload-lab-report></app-view-upload-lab-report>

        </p>
        <p *ngSwitchCase="'58'">
          <app-add-upload-radiology-report></app-add-upload-radiology-report>

        </p>
        <p *ngSwitchCase="'59'">
          <app-view-upload-radiology-report></app-view-upload-radiology-report>

        </p>
        <p *ngSwitchCase="'60'">
          <app-add-refferal-by-doctor></app-add-refferal-by-doctor>
        </p>
        <p *ngSwitchCase="'61'">
          <app-view-refferal></app-view-refferal>

        </p>
        <p *ngSwitchCase="'62'">
          <app-view-patient-prescriptiion></app-view-patient-prescriptiion>

        </p>
        <p *ngSwitchCase="'63'">
          <app-add-procedure></app-add-procedure>

        </p>
        <p *ngSwitchCase="'64'">
          <app-view-procedure></app-view-procedure>

        </p>
        <p *ngSwitchCase="'65'">
          <app-add-test-results></app-add-test-results>

        </p>
        <p *ngSwitchCase="'66'">
          <app-view-test-results></app-view-test-results>

        </p>
        <p *ngSwitchCase="'67'">
          <app-view-nerve-study-results></app-view-nerve-study-results>

        </p>
        <p *ngSwitchCase="'68'">
          <app-add-nerve-study-results></app-add-nerve-study-results>

        </p>
        <p *ngSwitchCase="'69'">
          <app-add-electromyography></app-add-electromyography>

        </p>
        <p *ngSwitchCase="'70'">
          <app-view-electromyography></app-view-electromyography>

        </p>
        <!-- <p *ngSwitchCase="'42'">
     <app-add-radiology-order-subject></app-add-radiology-order-subject>
     </p> -->


        <p *ngSwitchDefault>

          <app-doctor-patient-visit></app-doctor-patient-visit>
        </p>









        <!-- </div>
     </div> -->
      </div>
    </div>