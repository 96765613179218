import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { DoctorService } from 'src/app/services/doctor.service';
import { EmailService } from 'src/app/services/email.service';
import { LabmanagerService } from 'src/app/services/labmanager.service';
import { PatientService } from 'src/app/services/patient.service';
import { PatientvisitService } from 'src/app/services/patientvisit.service';
import { PharmacyMemberService } from 'src/app/services/pharmacy-member.service';
import { PhysicalTherapyDoctorService } from 'src/app/services/physical-therapy-doctor.service';
import { RadiologyMemberService } from 'src/app/services/radiology-member.service';
import { UserService } from 'src/app/services/user.service';
import * as XLSX from 'xlsx';
@Component({
  selector: 'app-bulk-import-patient',
  templateUrl: './bulk-import-patient.component.html',
  styleUrls: ['./bulk-import-patient.component.css'],
})
export class BulkImportPatientComponent implements OnInit {
  selectedFile: any;
  helper = new JwtHelperService();
  myToken = window.localStorage.getItem('token');
  id = 0;
  selectedPatientFile: any;
  selectedDoctorFile: any;
  selectedPhysicalTherapistFile: any;
  selectedLabManagerFile: any;
  selectedPharmacistFile: any;
  selectedRadiologyMemberFile: any;
  selectedPatientVisitFile: any;
  selectedNeurologyVisitFile: any;
  constructor(
    private patientService: PatientService,
    private route: ActivatedRoute,
    private userService: UserService,
    private emailService: EmailService,
    private fb: FormBuilder,
    private doctorService: DoctorService,
    private physicalTherapyDoctorService: PhysicalTherapyDoctorService,
    private pharmacyMemberService: PharmacyMemberService,
    private labManagerService: LabmanagerService,
    private radiolologyMemberService: RadiologyMemberService,
    private patientVisitService: PatientvisitService
  ) {}

  ngOnInit(): void {
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.id = parseInt(decodedToken.nameid);
  }

  onFileSelected(event) {
    this.selectedFile = event.target.files[0];
  }

  onFileDoctorSelected(event) {
    this.selectedDoctorFile = event.target.files[0];
  }
  onFilePhysicalTherapistSelected(event) {
    this.selectedPhysicalTherapistFile = event.target.files[0];
  }
  onFilePharmacistSelected(event) {
    this.selectedPharmacistFile = event.target.files[0];
  }
  onFileLabManagerSelected(event) {
    this.selectedLabManagerFile = event.target.files[0];
  }
  onFileRadiologyMemberSelected(event) {
    this.selectedRadiologyMemberFile = event.target.files[0];
  }
  onFilePatientVisitSelected(event) {
    this.selectedPatientVisitFile = event.target.files[0];
  }
  onFileNeurologyVisitSelected(event) {
    this.selectedNeurologyVisitFile = event.target.files[0];
  }
  excelData: any[];

  onFileChange(event: any) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const workbook = XLSX.read(e.target.result, { type: 'binary' });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      this.excelData = XLSX.utils.sheet_to_json(worksheet, { raw: true });
      console.log('Excel data:', this.excelData);
    };
    reader.readAsBinaryString(file);
  }
  uploadFile() {
    const fileReader = new FileReader();
    fileReader.readAsText(this.selectedFile, 'UTF-8');
    fileReader.onload = () => {
      const fileContent = fileReader.result;
      try {
        const upladodedData = JSON.parse(fileContent as string);

        if (!Array.isArray(upladodedData.Patients)) {
          alert('Invalid upladodedData structure');
        }
        upladodedData.Patients.forEach((ph) => {
          if (typeof ph.Email !== 'string') {
            alert('Invalid data structure');
          }
        });

        upladodedData.Patients.forEach((data) => {
          let userBody = {
            Email: data.Email,
            Password: data.Password,
            UserName: data.Email,
            PhoneNumber: data.PhoneNumber,
          };
          this.userService.registerUser(userBody).subscribe((res) => {
            let body = {
              PatientId: res.Id,
              DoctorId: data.DoctorId,
              WardId: data.WardId,
              WardName: data.WardName,
              RoomNo: data.RoomNo,
              BedNumber: data.BedNumber,
              DepartmentId: data.DepartmentId,
              DepartmentName: data.DepartmentName,
              PatientFirstName: data.PatientFirstName,
              PatientLastName: data.PatientLastName,
              PatientDOB: data.PatientDOB,
              PatientGender: data.PatientGender,
              Email: data.Email,
              Password: data.Password,
              PhoneNumber: data.PhoneNumber,
              PatientAddress: data.PatientAddress,
              PatientHeight: data.PatientHeight,
              PatientWeight: data.PatientWeight,
              NameOfParent: data.NameOfParent,
              DoctorName: data.DoctorName,
              PatientNationalId: data.PatientNationalId,
              MaritalStatus: data.MaritalStatus,
              Occupation: data.Occupation,
              BloodGroup: data.BloodGroup,
              Status: 'Active',
              HospitalId: data.HospitalId,
            };

            this.patientService.registerPatient(body).subscribe({
              next: (res) => {
                // this.isSuccess = true;
                alert('Upload With success');

                this.emailService
                  .sendEmail({
                    Email: body.Email,
                    Subject: 'Patient Login Credentials',
                    Template: `<h3>Your Username ${userBody.UserName} please keep this safe.Temporary Password ${userBody.Password} You may reset password now at https://binsera.net. </h3> `,
                  })
                  .subscribe();

                const smsBodyDetails =
                  'Your Username ' +
                  userBody.UserName +
                  'Temporary Password' +
                  userBody.Password +
                  ' You may reset password now at https://binsera.net';
                let smsBody = {
                  PhoneNumber: data.PhoneNumber,
                  Body: smsBodyDetails,
                };
                this.emailService.sendSms(smsBody).subscribe();

                setTimeout(() => {}, 2000);
              },
            });
          });
        });
      } catch (error) {
        console.error('Invalid JSON format:', error);
      }
    };

    fileReader.onerror = (error) => console.log(error);
  }
  uploadFileDoctors() {
    const fileReader = new FileReader();
    fileReader.readAsText(this.selectedDoctorFile, 'UTF-8');
    fileReader.onload = () => {
      const fileContent = fileReader.result;
      try {
        const upladodedData = JSON.parse(fileContent as string);

        //    Validate the data structure
        if (!Array.isArray(upladodedData.Doctors)) {
          alert('Invalid upladodedData structure');
        }
        upladodedData.Doctors.forEach((dr) => {
          if (typeof dr.Email !== 'string') {
            alert('Invalid data structure');
          }
        });

        upladodedData.Doctors.forEach((data) => {
          let userBody = {
            Email: data.Email,
            Password: data.Password,
            UserName: data.Email,
            PhoneNumber: data.PhoneNumber,
          };
          console.log(userBody);
          this.userService.registerDoctor(userBody).subscribe((res) => {
            let body = {
              HospitalId: data.HospitalId,
              DoctorId: res.Id,
              DepartmentId: data.DepartmentId,
              DepartmentName: data.DepartmentName,
              DoctorName: data.DoctorName,
              NpiNumber: data.NpiNumber,
              LicenceNumber: data.LicenceNumber,
              DoctorQualification: data.DoctorQualification,
              DoctorSpecialization: data.DoctorSpecialization,
              DoctorDOB: data.DoctorDOB,
              Email: data.Email,
              PhoneNumber: data.PhoneNumber,
              DoctorAddress: data.DoctorAddress,
              StartDate: data.StartDate,
              Status: 'Active',
            };

            this.doctorService.registerDoctor(body).subscribe({
              next: (res) => {
                // this.isSuccess = true;
                alert('Upload With success');

                this.emailService
                  .sendEmail({
                    Email: body.Email,
                    Subject: ' Login Credentials',
                    Template: `<h3>Your Username ${userBody.UserName} please keep this safe.Temporary Password ${userBody.Password}. You may reset password now at https://binsera.net. </h3> `,
                  })
                  .subscribe();

                const smsBodyDetails =
                  'Your Username ' +
                  userBody.UserName +
                  'Temporary Password' +
                  userBody.Password +
                  ' You may reset password now at https://binsera.net';
                let smsBody = {
                  PhoneNumber: data.PhoneNumber,
                  Body: smsBodyDetails,
                };
                this.emailService.sendSms(smsBody).subscribe();

                setTimeout(() => {}, 2000);
              },
            });
          });
        });
      } catch (error) {
        console.error('Invalid JSON format:', error);
      }
    };

    fileReader.onerror = (error) => console.log(error);
  }

  uploadFileRadilogyMembers() {
    const fileReader = new FileReader();
    fileReader.readAsText(this.selectedRadiologyMemberFile, 'UTF-8');
    fileReader.onload = () => {
      const fileContent = fileReader.result;
      try {
        const upladodedData = JSON.parse(fileContent as string);

        //    Validate the data structure
        if (!Array.isArray(upladodedData.RadiologyMembers)) {
          alert('Invalid upladodedData structure');
        }
        upladodedData.RadiologyMembers.forEach((dr) => {
          if (typeof dr.Email !== 'string') {
            alert('Invalid data structure');
          }
        });

        upladodedData.RadiologyMembers.forEach((data) => {
          let userBody = {
            Email: data.Email,
            Password: data.Password,
            UserName: data.Email,
            PhoneNumber: data.PhoneNumber,
          };
          console.log(userBody);
          this.userService
            .registerRadiologyMember(userBody)
            .subscribe((res) => {
              let body = {
                RadiologyMemberId: res.Id,
                NpiNumber: data.NpiNumber,
                LicenseNumber: data.LicenseNumber,
                FullName: data.FullName,
                Email: data.Email,
                PhoneNumber: data.PhoneNumber,
                NationalId: data.NationalId,
                Address: data.Address,
                DepartmentId: data.DepartmentId,
                Status: data.Status,
                // HospitalId: this.id,
              };

              this.radiolologyMemberService
                .registerRadiologyMember(body)
                .subscribe({
                  next: (res) => {
                    // this.isSuccess = true;
                    alert('Upload With success');

                    this.emailService
                      .sendEmail({
                        Email: body.Email,
                        Subject: ' Login Credentials',
                        Template: `<h3>Your Username ${userBody.UserName} please keep this safe.Temporary Password ${userBody.Password}. You may reset password now at https://binsera.net. </h3> `,
                      })
                      .subscribe();

                    const smsBodyDetails =
                      'Your Username ' +
                      userBody.UserName +
                      'Temporary Password' +
                      userBody.Password +
                      ' You may reset password now at https://binsera.net';
                    let smsBody = {
                      PhoneNumber: data.PhoneNumber,
                      Body: smsBodyDetails,
                    };
                    this.emailService.sendSms(smsBody).subscribe();

                    setTimeout(() => {}, 2000);
                  },
                });
            });
        });
      } catch (error) {
        console.error('Invalid JSON format:', error);
      }
    };

    fileReader.onerror = (error) => console.log(error);
  }
  //processed at front end
  uploadFilePatientVisits() {
    const fileReader = new FileReader();
    fileReader.readAsText(this.selectedPatientVisitFile, 'UTF-8');
    fileReader.onload = () => {
      const fileContent = fileReader.result;
      try {
        const upladodedData = JSON.parse(fileContent as string);

        //    Validate the data structure
        if (!Array.isArray(upladodedData.PatientVisits)) {
          alert('Invalid upladodedData structure');
        }
        upladodedData.PatientVisits.forEach((dr) => {
          if (typeof dr.DoctorName !== 'string') {
            alert('Invalid data structure');
          }
        });

        upladodedData.PatientVisits.forEach((data) => {
          let body = {
            PatientId: data.PatientId,
            DoctorSpecialization: data.DoctorSpecialization,
            VisitType: data.VisitType,
            VistitDate: data.VistitDate,
            VistTime: data.VistTime,
            Status: data.Status,
            NurseName: data.NurseName,
            Height: data.Height,
            Weight: data.Weight,
            BloodPressure: data.BloodPressure,
            Pulse: data.Pulse,
            Temparature: data.Temparature,
            HeartRate: data.HeartRate,
            ChiefComplaint: data.ChiefComplaint,
            PresentIllness: data.PresentIllness,
            Medical: data.Medical,
            Surgical: data.Surgical,
            Allergies: data.Allergies,
            Medications: data.Medications,
            SocialHistory: data.SocialHistory,
            FamilyHistory: data.FamilyHistory,
            ReviewOfSymptoms: data.ReviewOfSymptoms,
            VitalSigns: data.VitalSigns,
            HENT: data.HENT,
            Neck: data.Neck,
            Chest: data.Chest,
            Heart: data.Heart,
            Abdomen: data.Abdomen,
            Extermities: data.Extermities,
            Neurological: data.Neurological,
            Radiology: data.Radiology,
            Impression: data.Impression,
            Recomendation: data.Recomendation,
            DoctorName: data.DoctorName,
            Signature: data.Signature,
            Date: data.Date,
            CptCode: data.CptCode,
            SoftwareFee: data.SoftwareFee,
            createdDate: data.createdDate,
            HospitalId: data.HospitalId,
          };

          this.patientVisitService.registerPatientVisit(body).subscribe({
            next: (res) => {
              // this.isSuccess = true;
              alert('Upload With success');
            },
          });
        });
      } catch (error) {
        console.error('Invalid JSON format:', error);
      }
    };

    fileReader.onerror = (error) => console.log(error);
  }
  //processed at backend
  uploadPatientVisit() {
    const fileReader = new FileReader();
    fileReader.readAsText(this.selectedNeurologyVisitFile, 'UTF-8');
    fileReader.onload = () => {
      const fileContent = fileReader.result;
      try {
        const data = JSON.parse(fileContent as string);

        // Validate the data structure
        if (!Array.isArray(data.PatientVisits)) {
          alert('Invalid data structure');
        }
        data.PatientVisits.forEach((sub) => {
          if (typeof sub.DoctorName !== 'string') {
            alert('Invalid data structure');
          }
          // Add more checks as necessary...
        });

        this.patientVisitService.getPatientVisitJsonData(data).subscribe(
          (res) => {
            alert('Upload With success');
          },
          (err) => {
            alert('Error uploading the data');
          }
        );
        // this.http.post('https://your-api-url.com/upload', data)
        // .subscribe(
        //     response => console.log(response),
        //     error => console.log(error)
        // );
      } catch (error) {
        console.error('Invalid JSON format:', error);
      }
    };
    fileReader.onerror = (error) => console.log(error);
  }
}
