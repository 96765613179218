import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Patient } from 'src/app/Models/patient.model';
import { PatientVisit } from 'src/app/Models/patientvisit.model';
import { PatientService } from 'src/app/services/patient.service';
import { PatientvisitService } from 'src/app/services/patientvisit.service';
import { CptcodeService } from '../../services/cptcode.service';
import { CptCode } from 'src/app/Models/cptcode.model';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog-component';
import { DoctorService } from 'src/app/services/doctor.service';
import { Doctor } from 'src/app/Models/doctor.model';

@Component({
  selector: 'app-doctor-patient-visit',
  templateUrl: './doctor-patient-visit.component.html',
  styleUrls: ['./doctor-patient-visit.component.css'],
})
export class DoctorPatientVisitComponent implements OnInit {
  @Output() navigation = new EventEmitter<any>();

  helper = new JwtHelperService();
  myToken = window.localStorage.getItem('token');
  id = 0;
  patient: Patient;
  patientVisitList: PatientVisit[] = [];
  isAdmitted = false;

  pvId = 0;
  patientVisitId: PatientVisit[];
  showItem = '';
  CurrentDoctorId: number;
  filteredList;
  cptCodeLlist: CptCode[];
  isSuccess: boolean;
  visitData: {};
  Doctor: Doctor;

  constructor(
    private patientService: PatientService,
    private route: ActivatedRoute,
    private router: Router,
    private ptVisitService: PatientvisitService,
    private cptCodeService: CptcodeService,
    private clipBoard: Clipboard,
    private dialog: MatDialog,
    private doctorService: DoctorService
  ) {}

  ngOnInit(): void {
    this.getCptCode();

    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    this.getPatientVisit();
  }
  getPatientVisit() {
    this.patientService.getPatientvisits(this.id).subscribe((res) => {
      this.patient = res;
      let decodedToken = this.helper.decodeToken(this.myToken);
      this.CurrentDoctorId = parseInt(decodedToken.nameid);
      this.getDoctor(this.CurrentDoctorId);

      this.filteredList = res.PatientVisits.sort(function (x, y) {
        return y.PatientVisitId - x.PatientVisitId;
      });
      this.patientVisitList = res.PatientVisits.sort((a, b) => {
        return b.PatientVisitId - a.PatientVisitId;
      });
    });
  }
  printPage() {
    window.print();
  }
  copy(e: PatientVisit) {
    this.visitData = {
      DoctorSpecialization: e.DoctorSpecialization,
      VisitType: e.VisitType,
      VistitDate: e.VistitDate,
      VistTime: e.VistTime,
      Status: e.Status,
      NurseName: e.NurseName,
      Height: e.Height,
      Weight: e.Weight,
      BloodPressure: e.BloodPressure,
      Pulse: e.Pulse,
      Temparature: e.Temparature,
      HeartRate: e.HeartRate,
      ChiefComplaint: e.ChiefComplaint,
      PresentIllness: e.PresentIllness,
      Medical: e.Medical,
      Surgical: e.Surgical,
      Allergies: e.Allergies,
      Medications: e.Medications,
      SocialHistory: e.SocialHistory,
      FamilyHistory: e.FamilyHistory,
      ReviewOfSymptoms: e.ReviewOfSymptoms,
      VitalSigns: e.VitalSigns,
      HENT: e.HENT,
      Neck: e.Neck,
      Chest: e.Chest,
      Heart: e.Heart,
      Abdomen: e.Abdomen,
      Extermities: e.Extermities,
      Neurological: e.Neurological,
      Radiology: e.Radiology,
      Impression: e.Impression,
      Recomendation: e.Recomendation,
      NextFollowUp: e.NextFollowUp,
      DoctorName: this.Doctor.DoctorName,
      Signature: e.Signature,
      Date: new Date().toLocaleDateString(),
      CptCode: e.CptCode,
      IcdCode: e.IcdCode,
      SoftwareFee: e.SoftwareFee,
      createdDate: e.createdDate,
      PatientId: e.PatientId,
      HospitalId: e.HospitalId,
      DoctorId: this.CurrentDoctorId,
    };

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Copy Visit Data',
        content: 'Are you sure you want to copy data from this visit?',
      },
    });
    // this.clipBoard.copy(e);

    dialogRef.afterClosed().subscribe({
      next: (res) => {
        if (res === true) {
          this.ptVisitService
            .registerPatientVisit(this.visitData)
            .subscribe((res) => {
              this.isSuccess = true;
              // window.location.reload();
              this.getPatientVisit();
            });

          setTimeout(() => {
            this.navigation.emit();
          }, 2000);
        }
      },
    });
  }

  getDoctor(id) {
    this.doctorService.getDoctorById(id).subscribe((res) => {
      this.Doctor = res;
    });
  }

  deleteVisit(id) {
    let C_confirm = confirm('Are you Sure you want to delete ?');
    if (C_confirm == true) {
      this.ptVisitService.deletePatientVisit(id).subscribe((res) => {
        this.filteredList = this.patientVisitList.filter(
          (x) => x.PatientVisitId != id
        );
      });
    }
  }
  search(searchValue) {
    if (searchValue.value != null) {
      this.filteredList = this.patientVisitList.filter((x) =>
        x.Date.toLowerCase().includes(searchValue.value.toLowerCase())
      );
    }
  }
  getCptCode() {
    this.cptCodeService.getCptCodes().subscribe((res) => {
      this.cptCodeLlist = res;
    });
  }
  itemsToShow = 2;

  // Function to load more items
  loadMore() {
    this.itemsToShow += 1; // Increase the number of items to show by 3
  }

  // Function to check if there are more items to load
  hasMoreItems() {
    return this.itemsToShow < this.filteredList.length;
  }
}
