import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-tabs-component',
  templateUrl: './tabs-component.component.html',
  styleUrls: ['./tabs-component.component.css'],
})
export class TabsComponentComponent implements OnInit {
  @Input() tabsArray: string[] = [];
  @Output() onTabChange = new EventEmitter<number>();
  activatedTab: number = 0;

  constructor() {}

  ngOnInit(): void {}
  setTab(index: number) {
    this.activatedTab = index;
    // debugger;
    this.onTabChange.emit(this.activatedTab);
  }
}
