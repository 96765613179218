<mat-tab-group (selectedTabChange)="tabChanged($event)">

    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">health_and_safety</mat-icon>

            In Patient MRI Screening
        </ng-template>

        <div class="row">
            <div class="col-md-6" *ngFor="let item of mriScreeningList">

                <mat-card class="mat-elevation-z8 mt-3 ">
                    <button type="button" (click)="print()" class="btn btn-primary float-right" data-toggle="modal"
                        data-target=".bd-example-modal-lg"> Print </button>
                    <h2 class="clr">IN PATIENT MRI Screening</h2>

                    <p>Nurse Name : {{item?.NurseName}}</p>
                    <p class="float-right">Date : {{item?.createdDate | date: 'medium' }}</p>

                    <hr>
                    <mat-card-content>
                        <mat-accordion>
                            <mat-expansion-panel hideToggle>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        Surgical
                                    </mat-panel-title>

                                </mat-expansion-panel-header>
                                <tr>
                                    <th> Have You Had Prior Surgery : </th>

                                    <td>{{item?.HaveYouHadPriorSurgery ?'Yes':'No'}}</td>
                                </tr>
                                <tr>
                                    <th> Date Of Surgery : </th>
                                    <td>{{item?.DateOfSurgery}}</td>

                                </tr>
                                <tr>
                                    <th> Type Of Surgery : </th>
                                    <td>{{item?.TypeOfSurgery}}</td>

                                </tr>
                                <hr>
                                <tr>
                                    <th> Have You Ever Had MRI before : </th>
                                    <td>{{item?.HaveYouEverHadMRIbefore ?'Yes':'No'}}</td>

                                </tr>
                                <tr>
                                    <th> When Was Last MRI : </th>
                                    <td>{{item?.WhenWasLastMRI}}</td>

                                </tr>
                                <tr>
                                    <th> Have You Experienced Any Problems Related T oPreviuos MRI : </th>
                                    <td>{{item?.HaveYouExperiencedAnyProblemsRelatedToPreviuosMRI ?'Yes':'No'}}</td>

                                </tr>
                                <tr>
                                    <th> Describe Problems Related To Previuos MRI : </th>
                                    <td>{{item?.DescribeProblemsRelatedToPreviuosMRI}}</td>

                                </tr>

                            </mat-expansion-panel>
                            <hr>
                            <mat-expansion-panel hideToggle>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        Cancer,Stend,Clip,Staples
                                    </mat-panel-title>

                                </mat-expansion-panel-header>
                                <tr>
                                    <th> Have You Been Diagnosed With Cancer : </th>
                                    <td>{{item?.HaveYouBeenDiagnosedWithCancer ?'Yes':'No'}}</td>

                                </tr>
                                <tr>
                                    <th> What Type Of Cancer : </th>
                                    <td>{{item?.WhatTypeOfCancer}}</td>

                                </tr>
                                <tr>
                                    <th> Have You Had Radiation : </th>
                                    <td>{{item?.HaveYouHadRadiation}}</td>

                                </tr>
                                <tr>
                                    <th> Chemotherapy : </th>
                                    <td>{{item?.Chemotherapy}}</td>

                                </tr>
                                <tr>
                                    <th> Do You Have History Of Renal Disease : </th>
                                    <td>{{item?.DoYouHaveHistoryOfRenalDisease ?'Yes':'No'}}</td>

                                </tr>
                                <tr>
                                    <th> Ist here Any Possibility That You Are pregnant : </th>
                                    <td>{{item?.IsthereAnyPossibilityThatYouArepregnant ?'Yes':'No'}}</td>

                                </tr>
                                <tr>
                                    <th> BreastFeeding : </th>
                                    <td>{{item?.BreastFeeding ?'Yes':'No'}}</td>

                                </tr>
                                <tr>
                                    <th> Any Surgery In The Past 6 Weeks : </th>
                                    <td>{{item?.AnySurgeryInThePastSixWeeks ?'Yes':'No'}}</td>

                                </tr>
                                <tr>
                                    <th> Brain Surgery: </th>
                                    <td>{{item?.BrainSurgery ?'Yes':'No'}}</td>

                                </tr>
                                <tr>
                                    <th> Cardiac Pacemaker : </th>
                                    <td>{{item?.CardiacPacemaker ?'Yes':'No'}}</td>

                                </tr>
                                <tr>
                                    <th> Metal In Your Eyes : </th>
                                    <td>{{item?.MetalInYourEyes ?'Yes':'No'}}</td>

                                </tr>
                                <tr>
                                    <th> Aneurysm Clip : </th>
                                    <td>{{item?.AneurysmClip ?'Yes':'No'}}</td>

                                </tr>
                                <tr>
                                    <th> Cardiac Stent : </th>
                                    <td>{{item?.CardiacStent ?'Yes':'No'}}</td>

                                </tr>
                                <tr>
                                    <th> Wire Or Staples: </th>
                                    <td>{{item?.WireOrStaples ?'Yes':'No'}}</td>

                                </tr>
                                <tr>
                                    <th> Harrington Rod : </th>
                                    <td>{{item?.HarringtonRod ?'Yes':'No'}}</td>

                                </tr>
                                <tr>
                                    <th> Prosthetic Limbs : </th>
                                    <td>{{item?.ProstheticLimbs ?'Yes':'No'}}</td>

                                </tr>
                                <tr>
                                    <th> InsulinPump : </th>
                                    <td>{{item?.InsulinPump ?'Yes':'No'}}</td>

                                </tr>


                            </mat-expansion-panel>

                            <hr>

                            <mat-expansion-panel hideToggle>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        Implants , Metal, Rods, Plates
                                    </mat-panel-title>

                                </mat-expansion-panel-header>

                                <tr>
                                    <th> Electronic Implant : </th>
                                    <td>{{item?.ElectronicImplant ?'Yes':'No'}}</td>

                                </tr>
                                <tr>
                                    <th> Heart Valve Replacement : </th>
                                    <td>{{item?.HeartValveReplacement ?'Yes':'No'}}</td>

                                </tr>
                                <tr>
                                    <th> Metal, Rods, Plates, Pins : </th>
                                    <td>{{item?.MetalRodsPlatesPins}}</td>

                                </tr>
                                <tr>
                                    <th> Neuro Stimulator / Tens : </th>
                                    <td>{{item?.NeuroStimulatorOrTens ?'Yes':'No'}}</td>

                                </tr>
                                <tr>
                                    <th> Vascular Porter Or Catheter: </th>
                                    <td>{{item?.VascularPorterOrCatheter ?'Yes':'No'}}</td>

                                </tr>
                                <tr>
                                    <th> Stent ,Shunt, Filter, Coil : </th>
                                    <td>{{item?.StentShuntFilterCoil ?'Yes':'No'}}</td>

                                </tr>


                                <tr>
                                    <th> When Was Stent Placed : </th>
                                    <td>{{item?.WhenWasStentPlaced}}</td>

                                </tr>

                            </mat-expansion-panel>

                            <hr>
                            <mat-expansion-panel hideToggle>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        Pump, Shrapnel,Jwellary,Magnet,Stimulator

                                    </mat-panel-title>

                                </mat-expansion-panel-header>

                                <hr>
                                <tr>
                                    <th> Implanted Drug Pump : </th>
                                    <td>{{item?.ImplantedDrugPump ?'Yes':'No'}}</td>

                                </tr>
                                <tr>
                                    <th> Foriegn Object Or Shrapnel : </th>
                                    <td>{{item?.ForiegnObjectOrShrapnel ?'Yes':'No'}}</td>

                                </tr>
                                <tr>
                                    <th> Eye Prosthesis Or Implant : </th>
                                    <td>{{item?.EyeProsthesisOrImplant ?'Yes':'No'}}</td>

                                </tr>
                                <tr>
                                    <th> Hearing Aids : </th>
                                    <td>{{item?.HearingAids ?'Yes':'No'}}</td>

                                </tr>
                                <tr>
                                    <th> Magnetically Activated Implant : </th>
                                    <td>{{item?.MagneticallyActivatedImplant ?'Yes':'No'}}</td>

                                </tr>
                                <tr>
                                    <th> Medication Patch : </th>
                                    <td>{{item?.MedicationPatch ?'Yes':'No'}}</td>

                                </tr>
                                <tr>
                                    <th> Swanganz Or Thermodilution Cath : </th>
                                    <td>{{item?.SwanganzOrThermodilutionCath ?'Yes':'No'}}</td>

                                </tr>
                                <tr>
                                    <th> Penelie Implant : </th>
                                    <td>{{item?.PenelieImplant ?'Yes':'No'}}</td>

                                </tr>
                                <tr>
                                    <th> Cochlear Imaplant : </th>
                                    <td>{{item?.CochlearImaplant ?'Yes':'No'}}</td>

                                </tr>
                                <tr>
                                    <th> Bone Fusion Stimulator : </th>
                                    <td>{{item?.BoneFusionStimulator ?'Yes':'No'}}</td>

                                </tr>
                                <tr>
                                    <th> Tattoo Permanant Make Up : </th>
                                    <td>{{item?.TattooPermanantMakeUp ?'Yes':'No'}}</td>

                                </tr>

                            </mat-expansion-panel>

                            <hr>
                            <mat-expansion-panel hideToggle>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        Nursing Section

                                    </mat-panel-title>

                                </mat-expansion-panel-header>

                                <hr>
                                <tr>
                                    <th> Claustrophobic : </th>
                                    <td>{{item?.Claustrophobic ?'Yes':'No'}}</td>

                                </tr>
                                <tr>
                                    <th> Can Patient Lie Still For Exam : </th>
                                    <td>{{item?.CanPatientLieStillForExam ?'Yes':'No'}}</td>

                                </tr>
                                <tr>
                                    <th> Is Patient On Event : </th>
                                    <td>{{item?.IsPatientOnEvent ?'Yes':'No'}}</td>

                                </tr>
                                <tr>
                                    <th> Patient Signature: </th>
                                    <td>{{item?.PatientSignature}}</td>

                                </tr>
                                <tr>
                                    <th> Date: </th>
                                    <td>{{item?.Date}}</td>

                                </tr>
                                <tr>
                                    <th> Time: </th>
                                    <td>{{item?.Time}}</td>

                                </tr>
                                <tr>
                                    <th> Patient Representative: </th>
                                    <td>{{item?.PatientRepresentative}}</td>

                                </tr>


                            </mat-expansion-panel>

                            <hr>

                        </mat-accordion>

                    </mat-card-content>
                    <button class="float-right" [routerLink]="['/updateMriScreening/',item?.InPatientScreeningMRIId]"
                        color="primary" mat-mini-fab><mat-icon>edit</mat-icon> </button>
                    <button class="float-right" (click)="delete(item?.InPatientScreeningMRIId)" color="accent"
                        mat-mini-fab><mat-icon>delete</mat-icon> </button>

                </mat-card>
            </div>
        </div>
    </mat-tab>


    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">add</mat-icon>
            NEW MRI Screening
        </ng-template>

        <app-add-mri-screening></app-add-mri-screening>
    </mat-tab>


</mat-tab-group>