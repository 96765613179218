import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-tabs2-component',
  templateUrl: './tabs2-component.component.html',
  styleUrls: ['./tabs2-component.component.css'],
})
export class Tabs2ComponentComponent implements OnInit {
  @Input() tabsArray: string[] = [];
  @Output() onTabChange = new EventEmitter<number>();
  activatedTab: number = 0;

  constructor() {}

  ngOnInit(): void {}
  setTab(index: number) {
    this.activatedTab = index;
    // debugger;
    this.onTabChange.emit(this.activatedTab);
  }
}
