import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  UntypedFormBuilder,
  Validators,
  UntypedFormArray,
  FormControl,
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Doctor } from 'src/app/Models/doctor.model';
import { Medicine } from 'src/app/Models/medicine.model';
import { Prescription } from 'src/app/Models/prescription.model';
import { DoctorService } from 'src/app/services/doctor.service';
import { HospitalService } from 'src/app/services/hospital.service';
import { MedicineService } from 'src/app/services/medicine.service';
import { NurseService } from 'src/app/services/nurse.service';
import { PatientPrescriptionService } from 'src/app/services/patient-prescription.service';
import { PatientService } from 'src/app/services/patient.service';
import { PrescriptionService } from 'src/app/services/prescription.service';

@Component({
  selector: 'app-add-prescription-by-nurse',
  templateUrl: './add-prescription-by-nurse.component.html',
  styleUrls: ['./add-prescription-by-nurse.component.css'],
})
export class AddPrescriptionByNurseComponent implements OnInit {
  id = 0;
  nurseId = 0;
  helper = new JwtHelperService();
  myToken = window.localStorage.getItem('token');
  states;
  isSuccess = false;
  doctor: string;
  QTimes: string[] = [
    'Q1-Every Hour',
    'Q2-Every 2 hours',
    'Q3-Every 3 hours',
    'Q4-Every 4 hours',
    'Q5-Every 5 hours',
    'Q4-Every 6 hours',
    'Q4-Every 6 hours',
    'Q8-Every 8 hours',
    'Q12-Every 12 hours',
  ];

  DrugsList: string[] = [
    'Calpol 200mg',
    'Paracetamol 200mg',
    'Co-codomol 200mg',
    'Ibuprofen 200mg',
  ];
  instructionList: string[] = [
    'Take 1 tablet in the Morning',
    'Take 2 tablet in the Morning',
    'Take 3 tablet in the Morning',
    'Take 1 tablet in the Afternoon',
    'Take 2 tablet in the Afternoon',
    'Take 3 tablet in the Afternoon',
    'Take 1 tablet in the Evening',
    'Take 2 tablet in the Evening',
    'Take 3 tablet in the Evening',
    'Take 1 tablet at bedtime',
    'Take 2 tablet at bedtime',
    'Take 3 tablet at bedtime',
    'Take 1 tablet in the Morning and 1 tablet at bedtime',
    'Take 2 tablet in the Morning and 2 tablet at bedtime',
    'Take 3 tablet in the Morning and 3 tablet at bedtime',
    'Take 1 tablet in the Morning ,ake 1 tablet in the Afternoon and Take 1 tablet in the Evening ',
    'Take 2 tablet in the Morning ,ake 2 tablet in the Afternoon and Take 2 tablet in the Evening ',
    'Take 3 tablet in the Morning ,ake 3 tablet in the Afternoon and Take 3 tablet in the Evening ',
    'Take 1 tablet in the Morning ,ake 1 tablet in the Afternoon and Take 1 tablet in the bedtime ',
    'Take 2 tablet in the Morning ,ake 2 tablet in the Afternoon and Take 2 tablet in the bedtime ',
    'Take 3 tablet in the Morning ,ake 3 tablet in the Afternoon and Take 3 tablet in the bedtime ',
    'Take 1 tablet b.i.d as needed',
    'Take 2 tablet b.i.d as needed',
    'Take 3 tablet b.i.d as needed',
    'Take 1 tablet t.i.d as needed',
    'Take 2 tablet t.i.d as needed',
    'Take 3 tablet t.i.d as needed',
    'Take 1 tablet every hour',
    'Take 1 tablet every 2 hours',
    'Take 1 tablet every 4 hours',
    'Take 1 tablet every 6 hours',
    'Take 1 tablet every 8 hours',
    'Take 1 tablet every 12 hours',
  ];
  prescriptionFrm: FormGroup;
  medicineList: Medicine[];
  hospitalId: number;
  doctorList: Doctor[];
  constructor(
    private fb: UntypedFormBuilder,
    private prescriptionService: PrescriptionService,
    private router: Router,
    private route: ActivatedRoute,
    private medicineService: MedicineService,
    private doctorService: DoctorService,
    private toastr: ToastrService,
    private nurseService: NurseService,
    private hospitalService: HospitalService,
    private patientService: PatientService
  ) {}

  ngOnInit(): void {
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.nurseId = parseInt(decodedToken.nameid);
    this.getNurse(this.nurseId);

    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });

    this.patientService.getpatienHospitalId(this.id).subscribe((res) => {
      this.hospitalId = res.Hospital.HospitalId;
      this.getDoctors(this.hospitalId);
    });
    this.getDoctorId;
    this.getMedicine();
    this.prescriptionFrm = this.fb.group({
      Prescription: this.fb.array([]),
    });
    this.addItem();
  }

  public addItem(): void {
    const item = this.fb.group({
      DrugName: ['', Validators.required],
      Strength: ['', Validators.required],
      BrandName: [''],
      Quantity: ['', Validators.required],
      DatePrescribed: ['', Validators.required],
      Instruction: ['', Validators.required],
      MorningTime: [''],
      AfternoonTime: [''],
      EveningTime: [''],
      NightTime: [''],
      QTime: [''],
      Status: [true],
      StartDate: new FormControl(new Date().toISOString().substring(0, 10)),
      EndDate: [''],
      RefillDuration: [''],
      Numberofrefills: [''],
      PatientId: this.id,
      NurseId: this.nurseId,
      DoctorId: [],
      DoctorName: [''],
      NurseName: [''],
      Note: [''],
      // DoctorSignature: [''],
    });
    this.Prescription.push(item);
  }

  public removeItem(i: number): void {
    this.Prescription.removeAt(i);
  }

  get Prescription(): UntypedFormArray {
    return this.prescriptionFrm.get('Prescription') as UntypedFormArray;
  }

  get Note() {
    return this.prescriptionFrm.get('Note');
  }
  get Numberofrefills() {
    return this.prescriptionFrm.get('Numberofrefills');
  }
  get RefillDuration() {
    return this.prescriptionFrm.get('RefillDuration');
  }

  get NurseId() {
    return this.prescriptionFrm.get('NurseId');
  }
  get NurseName() {
    return this.prescriptionFrm.get('NurseName');
  }
  get DoctorName() {
    return this.prescriptionFrm.get('DoctorName');
  }

  get StartDate() {
    return this.prescriptionFrm.get('StartDate');
  }

  get EndDate() {
    return this.prescriptionFrm.get('EndDate');
  }

  get Status() {
    return this.prescriptionFrm.get('Status');
  }
  get MorningTime() {
    return this.prescriptionFrm.get('MorningTime');
  }
  get AfternoonTime() {
    return this.prescriptionFrm.get('AfternoonTime');
  }
  get EveningTime() {
    return this.prescriptionFrm.get('EveningTime');
  }
  get NightTime() {
    return this.prescriptionFrm.get('NightTime');
  }
  get QTime() {
    return this.prescriptionFrm.get('QTime');
  }
  get DoctorId() {
    return this.prescriptionFrm.get('DoctorId');
  }
  get DoctorSignature() {
    return this.prescriptionFrm.get('DoctorSignature');
  }
  get Quantity() {
    return this.prescriptionFrm.get('Quantity');
  }
  get DrugName() {
    return this.prescriptionFrm.get('DrugName');
  }
  get Dosage() {
    return this.prescriptionFrm.get('Strength');
  }

  get BrandName() {
    return this.prescriptionFrm.get('BrandName');
  }
  get DatePrescribed() {
    return this.prescriptionFrm.get('DatePrescribed');
  }

  get Instruction() {
    return this.prescriptionFrm.get('Instruction');
  }
  getNurse(id) {
    this.nurseService.getNurseById(id).subscribe((res) => {});
  }
  getMedicine() {
    this.medicineService.getMedicines().subscribe((res) => {
      this.medicineList = res;
    });
  }
  getDoctors(id) {
    this.hospitalService.getDoctors(id).subscribe((res) => {
      this.doctorList = res.Doctors;
    });
  }
  getDoctorId() {
    this.doctorService.getDoctorById(this.DoctorId.value).subscribe((res) => {
      this.Prescription.push(res);
    });
  }
  formSubmit() {
    let prescriptions: Prescription[] = this.prescriptionFrm.get('Prescription')
      ?.value as Prescription[];
    // if (prescriptions?.length > 0) {
    //   prescriptions[0].DoctorName = this.doctor.DoctorName;
    //   prescriptions.forEach((percep, index) => {
    //     if (index > 0) {
    //       delete percep.DoctorName;
    //       delete percep.NurseName;
    //     }
    //   });
    // }
    this.prescriptionService
      .saveMultiplePrescriptions(prescriptions)
      .subscribe((res) => {
        this.isSuccess = true;
        this.prescriptionFrm.reset();
        // alert('Form submited with success');
      });
  }
}
