import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Patient } from 'src/app/Models/patient.model';
import { DoctorService } from '../../services/doctor.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-view-doctor-patients',
  templateUrl: './view-doctor-patients.component.html',
  styleUrls: ['./view-doctor-patients.component.css'],
})
export class ViewDoctorPatientsComponent implements OnInit {
  displayedColumns: string[] = [
    'FirstName',
    'LastName',
    'Gender',
    'PatientNationalId',
    'Email',
    'PhoneNumber',
    'PrimaryDoctor',
    'WardName',
    'visit',
    'Detail',
  ];
  patientList: Patient[] = [];
  id = 0;
  helper = new JwtHelperService();

  myToken = window.localStorage.getItem('token');
  filteredList;
  dataSource: MatTableDataSource<any>;

  constructor(private doctorService: DoctorService, private router: Router) {}

  ngOnInit(): void {
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.id = parseInt(decodedToken.nameid);

    this.getDoctorPatients(this.id);
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  // ngAfterViewInit() {
  //   this.dataSource.paginator = this.paginator;
  //   this.dataSource.sort = this.sort;
  // }

  getDoctorPatients(id) {
    this.doctorService.getDoctorPatients(id).subscribe((res) => {
      this.filteredList = res.Patients;
      this.dataSource = new MatTableDataSource(res.Patients);
      this.dataSource.paginator = this.paginator;

      this.patientList = res.Patients;
    });
  }

  search(searchValue) {
    // console.log(searchValue);
    if (searchValue.value != null) {
      this.filteredList = this.patientList.filter((x) =>
        x.PatientNationalId.toLowerCase().includes(
          searchValue.value.toLowerCase()
        )
      );
    }
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openWindow(rowId: number) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/splitScreenDctorPatient/${rowId}`])
    );
    window.open(url, '_blank');
  }
}
