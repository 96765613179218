<div class="container">
  <div class="row">
    <div class="col-lg regFrm mat-elevation-z8">
      <h1>Update Nurse Report</h1>
      <form [formGroup]="medFrm" (ngSubmit)="formSubmit()" class="example-form">





        <div class="row">

          <div class="col-lg-4">
            <mat-form-field class="example-full-width">
              <mat-label>Date</mat-label>
              <input type="text" formControlName="Date" matInput>
            </mat-form-field>

          </div>
          <div class="col-lg-4">
            <mat-form-field class="example-full-width">
              <mat-label>Time</mat-label>
              <input type="text" matInput formControlName="Time">
            </mat-form-field>
          </div>
          <div class="col-lg-4">

            <mat-form-field class="example-full-width">
              <mat-label>NurseName</mat-label>
              <input type="text" matInput formControlName="NurseName">
            </mat-form-field>

          </div>
        </div>

        <div class="row">

          <div class="col-lg-3">
            <mat-form-field class="example-full-width">
              <mat-label>Respiration</mat-label>
              <input type="text" formControlName="Respiration" matInput>
            </mat-form-field>

          </div>
          <div class="col-lg-3">
            <mat-form-field class="example-full-width">
              <mat-label>Temparature</mat-label>
              <input type="text" matInput formControlName="Temparature">
            </mat-form-field>
          </div>
          <div class="col-lg-3">

            <mat-form-field class="example-full-width">
              <mat-label>Heart Beat </mat-label>
              <input type="text" matInput formControlName="HeartBeat">
            </mat-form-field>

          </div>
          <div class="col-lg-3">

            <mat-form-field class="example-full-width">
              <mat-label>Blood Pressure</mat-label>
              <input type="text" matInput formControlName="BloodPressure">
            </mat-form-field>

          </div>
        </div>
        <div class="row">

          <div class="col-lg-8">
            <mat-form-field appearance="fill" class="example-full-width"
              [class]="NurseId && CurrentNurseId && NurseId != CurrentNurseId ? 'strict-disabled' : '' ">
              <mat-label>Nurse Notes/Comments </mat-label>
              <!-- <mat-icon matPrefix>event</mat-icon> -->
              <!-- <input matInput type="text" class="txt_height" formControlName="TodoText"> -->
              <textarea matInput formControlName="Comments" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="1" cdkAutosizeMaxRows="100"></textarea>

              <!--                  
                <mat-error *ngIf="PatientNote.invalid && PatientNote.touched">Please enter PatientNote's</mat-error> -->
            </mat-form-field>
            <!-- <mat-form-field class="example-full-width">
                <mat-label>Comments</mat-label>
                <input type="text" matInput formControlName="Comments" >
              </mat-form-field> -->
          </div>
          <div class="col-lg-4">
            <mat-form-field class="example-full-width">
              <mat-label>Signature/Initial</mat-label>
              <input type="text" matInput formControlName="Signature">
            </mat-form-field>
          </div>






        </div>













        <button class="float-right lgBtCl" mat-button>
          <mat-icon>save</mat-icon>
          Save</button>
      </form>

    </div>
  </div>

</div>