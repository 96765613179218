import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable, pipe, Subject } from 'rxjs';
import {
  auditTime,
  debounceTime,
  distinctUntilChanged,
  map,
  share,
  startWith,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import { Doctor } from 'src/app/Models/doctor.model';
import { DoctorReport } from 'src/app/Models/doctorreport.model';
import { DoctorTemplate } from 'src/app/Models/doctortemplate.model';
import { NurseReport } from 'src/app/Models/nursereport.model';
import { Patient } from 'src/app/Models/patient.model';
import { DoctorReportService } from 'src/app/services/doctor-report.service';
import { DoctorService } from 'src/app/services/doctor.service';
import { PatientService } from 'src/app/services/patient.service';
import { VoiceRecognitionService } from 'src/app/services/voice-recognition.service';

@Component({
  selector: 'app-add-doctor-report',
  templateUrl: './add-doctor-report.component.html',
  styleUrls: ['./add-doctor-report.component.css'],
})
export class AddDoctorReportComponent implements OnInit {
  displayedColumns: string[] = ['Name'];
  templates: DoctorTemplate[] = [];

  reportNames: string[] = [
    'Consult note',
    'Progress note',
    'Procedure note',
    'Office visit note',
    'Telephone Note',
  ];
  id = 0;
  reportList: DoctorReport[] = [];
  isSuccess = false;
  helper = new JwtHelperService();
  myToken = window.localStorage.getItem('token');
  UserId: number = 0;
  doctor: Doctor;
  listening = false;
  patient: Patient;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: any;
  filteredReportName: Observable<string[]>;

  constructor(
    private fb: UntypedFormBuilder,
    private doctorReportService: DoctorReportService,
    private route: ActivatedRoute,
    public service: VoiceRecognitionService,
    private doctorService: DoctorService,
    private patientService: PatientService
  ) {
    this.service.init();
  }
  private unsubscribe = new Subject<void>();
  ngOnInit(): void {
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.UserId = parseInt(decodedToken.nameid);
    this.loadTemplates(this.UserId);

    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    this.getPatient(this.id);
    this.getDoctor(this.UserId);

    this.filteredReportName = this.ReportName.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter5(value || ''))
    );

    if (window.close) {
      this.formSubmit();
    }
    this.Frm.valueChanges
      .pipe(debounceTime(1500), distinctUntilChanged())
      .subscribe((v) => this.formSubmit());
  }
  Frm = this.fb.group({
    ReportName: ['', Validators.required],
    ReportDescription: ['', Validators.required],
  });
  get ReportName() {
    return this.Frm.get('ReportName');
  }

  get ReportDescription() {
    return this.Frm.get('ReportDescription');
  }

  tabChanged(event: MatTabChangeEvent): void {
    if (event.index === 0) {
      // this.getReportName();
    }
  }

  startService() {
    this.service.start();
    this.listening = true;
  }

  stopService() {
    this.service.stop();
    this.listening = false;
  }

  onSubjectiveChange(e) {
    this.service.message1 = e.target.value;
  }
  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode == KEY_CODE.DOWN_ARROW) {
      // Your row selection code
      // console.log(event);
      this.stopService();
    }
  }
  onSubjectiveBlur() {
    this.stopService();
  }

  getDoctor(id) {
    this.doctorService.getDoctorById(id).subscribe((res) => {
      this.doctor = res;
    });
  }
  getPatient(id) {
    this.patientService.getPatientById(id).subscribe((res) => {
      this.patient = res;
    });
  }
  formSubmit() {
    let ReportName = this.Frm.value['ReportName'];
    let reportDescription = this.Frm.value['ReportDescription'];

    let body = {
      PatientId: this.id,
      DoctorId: this.UserId,
      ReportName: ReportName,
      ReportDescription: reportDescription,
      DoctorName: this.doctor.DoctorName,
      Status: true,
    };

    this.doctorReportService.registerDoctorReport(body).subscribe((res) => {
      this.isSuccess = true;
      this.Frm.reset();
    });
  }
  loadTemplates(id) {
    this.doctorService.getDoctorTemplates(id).subscribe((data) => {
      // console.log(data);
      this.templates = data.DoctorTemplates;
      this.dataSource = new MatTableDataSource(data.DoctorTemplates);
      this.dataSource.paginator = this.paginator;
    });
  }

  textBoxContent: string = '';
  // templates = this.templates;

  selectTemplate(templateContent: string) {
    this.textBoxContent = templateContent;
  }
  // This method is triggered on every keystroke or text area change
  onTextInput(event: any): void {
    const value = event.target.value;

    // Loop through templates and replace shortcut with the actual content
    this.templates.forEach((template) => {
      if (value.includes(template.shortcut)) {
        this.textBoxContent = value.replace(
          template.shortcut,
          template.Content
        );
      }
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  delete(id) {
    this.doctorReportService.deleteDoctorReport(id).subscribe((res) => {
      this.dataSource(this.id);
    });
  }

  private _filter5(value: string): string[] {
    const ds = value.toLowerCase();

    return this.reportNames.filter((aa) => aa.toLowerCase().includes(ds));
  }
}

export enum KEY_CODE {
  UP_ARROW = 38,
  DOWN_ARROW = 40,
  RIGHT_ARROW = 39,
  LEFT_ARROW = 37,
}
// templates = {
//   '@greeting': 'Dear [Narendra],\n\nThank you for reaching out to us.',
//   '@closing':
//     'Please do not hesitate to reach out to me if any help required.Best regards,\n[Krishna]',
// };
// selectedTemplate: string = '';

// selectTemplate(templateContent: string) {
//   this.selectedTemplate = templateContent;
// }

// onTextChange(event: any) {
//   this.detectShortcut();
// }

// detectShortcut() {
//   for (const shortcut in this.templates) {
//     if (this.selectedTemplate.includes(shortcut)) {
//       this.replaceShortcut(shortcut);
//     }
//   }
// }

// replaceShortcut(shortcut: string) {
//   this.selectedTemplate = this.selectedTemplate.replace(
//     shortcut,
//     this.templates[shortcut]
//   );
// }
