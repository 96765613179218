<div class="conatiner mx-5 my-5">
    <button type="button" (click)="print()" class="btn btn-primary float-right" data-toggle="modal"
        data-target=".bd-example-modal-lg"> Print </button>
    <div class="row">
        <div class="col-md-12" *ngFor="let item of evaluationList">
            <button class="" [routerLink]="['/updateDctorEvaluation/',item?.DoctorInitialEvaluationId,this.id]"
                [disabled]="CurrentDoctorId != item?.DoctorId" color="primary" mat-mini-fab><mat-icon>edit</mat-icon>
            </button>
            <button class=" ml-3" (click)="delete(item?.DoctorInitialEvaluationId)" color="accent"
                [disabled]="CurrentDoctorId != item?.DoctorId" mat-mini-fab><mat-icon>delete</mat-icon> </button>
            <mat-card class="mat-elevation-z8 mt-3 ">

                <mat-card-content id="printable">
                    <h2 class="clr">Doctor Initial Evaluation</h2>
                    <p>{{item?.DoctorName}}</p>
                    <p class="float-right">Date : {{item?.CreatedDate | date: 'short' }}</p>

                    <hr>
                    <div class="row">
                        <div class="col-md-3">
                            Patient Name <p>{{item?.PatientName}}</p>
                        </div>
                        <div class="col-md-3">
                            Patient D.O.B <p>{{item?.PatientDOB}}</p>

                        </div>
                        <div class="col-md-3">
                            Patient Height <p>{{item?.PatientHeight}}</p>

                        </div>
                        <div class="col-md-3">
                            Patient Height <p>{{item?.PatientWeight}}</p>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-md-3">
                            Blood Pressure <p>{{item?.BloodPressure}}</p>
                        </div>
                        <div class="col-md-3">
                            Pulse <p>{{item?.Pulse}}</p>

                        </div>
                        <div class="col-md-3">
                            Is Patient on Any Medication <p>{{item?.IsPatientonAnyMedication}}</p>

                        </div>
                        <div class="col-md-3">
                            Is Patient Under Any Medical Treatment <p>{{item?.IsPatientUnderAnyMedicalTreatment}}</p>
                        </div>
                    </div>
                    <hr>
                    <h2>Reffering Physician</h2>
                    <div class="row">
                        <div class="col-md-3">
                            Refferring Physician Name <p>{{item?.RefferringPhysicianName}}</p>
                        </div>
                        <div class="col-md-3">
                            Refferring Physician Address <p>{{item?.RefferringPhysicianAddress}}</p>

                        </div>
                        <div class="col-md-3">
                            Refferring Physician Phone <p>{{item?.RefferringPhysicianPhone}}</p>

                        </div>
                        <div class="col-md-3">
                            Primary Care Doctor Name <p>{{item?.PrimaryCareDoctorName}}</p>
                        </div>
                    </div>
                    <hr>
                    <h1>CHIEF COMPLAINT/REASON FOR VISIT</h1>

                    <div class="row">
                        <div class="col-md-6">
                            What is the reason for your visit today <p>{{item?.Whatisthereasonforyourvisittoday}}</p>

                        </div>
                        <div class="col-md-6">
                            Are you experiencing any pain <p>{{item?.Areyouexperiencinganypain}}</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            Is this visit work related injury <p>{{item?.Isthisvisitrelatedworkrelatedinjury}}</p>
                        </div>
                        <div class="col-md-6">
                            Is this visit related to a motor vehicle accident <p>
                                {{item?.Isthisvisitrelatedtoamotorvehicleaccident}}</p>

                        </div>

                    </div>
                    <div class="row">
                        <div class="col-md-3">
                            Pain Location <p>{{item?.PainLocation}}</p>
                        </div>
                        <div class="col-md-3">
                            Level Of Pain <p>
                                {{item?.LevelOfPain}}</p>

                        </div>
                        <div class="col-md-3">
                            Date Of Symtoms Began <p>{{item?.DateOfSymtomsBegan}}</p>

                        </div>
                        <div class="col-md-3">
                            Severity Of Symptoms <p>{{item?.SeverityOfSymptoms}}</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <h2> Paralysis / Blood Thinners </h2>
                            <tr>
                                <th> Paralysis : </th>

                                <td>{{item?.Paralysis ?'Yes':'No'}}</td>
                            </tr>
                            <tr>
                                <th> Are you on blood thinners : </th>
                                <td>{{item?.Areyouonbloodthinners ?'Yes':'No'}}</td>

                            </tr>
                        </div>
                        <div class="col-md-4">
                            <h2> Quality of Pain </h2>
                            <tr>
                                <th> Ache : </th>

                                <td>{{item?.Ache ?'Yes':'No'}}</td>
                            </tr>
                            <tr>
                                <th> Burning : </th>
                                <td>{{item?.Burning ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Deep : </th>
                                <td>{{item?.Deep ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Diffuse : </th>
                                <td>{{item?.Diffuse ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Discomforting : </th>
                                <td>{{item?.Discomforting ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Dull : </th>
                                <td>{{item?.Dull ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Localized : </th>
                                <td>{{item?.Localized ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Numbness : </th>
                                <td>{{item?.Numbness ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Throbbing : </th>
                                <td>{{item?.Throbbing ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Piercing : </th>
                                <td>{{item?.Piercing ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Sharp : </th>
                                <td>{{item?.Sharp ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Shooting : </th>
                                <td>{{item?.Shooting ?'Yes':'No'}}</td>

                            </tr>

                        </div>
                        <div class="col-md-4">
                            <h2> Radiation of Pain </h2>
                            <tr>
                                <th> Radiating Lower Back : </th>

                                <td>{{item?.RadiatingLowerBack ?'Yes':'No'}}</td>
                            </tr>
                            <tr>
                                <th> Radiating Neck : </th>
                                <td>{{item?.RadiatingNeck ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Radiating Left Arm : </th>
                                <td>{{item?.RadiatingLeftArm ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Radiating Right Arm : </th>
                                <td>{{item?.RadiatingRightArm ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Radiating Left Leg : </th>
                                <td>{{item?.RadiatingLeftLeg ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Radiating RightL eg : </th>
                                <td>{{item?.RadiatingRightLeg ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Radiating Left Hip : </th>
                                <td>{{item?.RadiatingLeftHip ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Radiating Right Hip : </th>
                                <td>{{item?.RadiatingRightHip ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Radiating Left Shoulder : </th>
                                <td>{{item?.RadiatingLeftShoulder ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Radiating Right Shoulder : </th>
                                <td>{{item?.RadiatingRightShoulder ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Radiating Other Pain : </th>
                                <td>{{item?.RadiatingOtherPain ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Radiating No Pain : </th>
                                <td>{{item?.RadiatingNoPain ?'Yes':'No'}}</td>

                            </tr>

                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-md-4">
                            <h2> Location of Pain </h2>
                            <tr>
                                <th> Lower Back : </th>

                                <td>{{item?.LowerBack ?'Yes':'No'}}</td>
                            </tr>
                            <tr>
                                <th> Neck : </th>
                                <td>{{item?.Neck ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Left Arm : </th>
                                <td>{{item?.LeftArm ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Right Arm : </th>
                                <td>{{item?.RightArm ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Left Leg : </th>
                                <td>{{item?.LeftLeg ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Right Leg : </th>
                                <td>{{item?.RightLeg ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Left Hip : </th>
                                <td>{{item?.LeftHip ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Right Hip : </th>
                                <td>{{item?.RightHip ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Left Shoulder : </th>
                                <td>{{item?.LeftShoulder ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Right Shoulder : </th>
                                <td>{{item?.RightShoulder ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> OtherPain : </th>
                                <td>{{item?.OtherPain}}</td>

                            </tr>
                            <tr>
                                <th> No Pain : </th>
                                <td>{{item?.NoPain ?'Yes':'No'}}</td>

                            </tr>
                        </div>
                        <div class="col-md-4">
                            <h2>ALLERGIES</h2>
                            <tr>
                                <th> Are you allergic to any medications : </th>

                                <td>{{item?.Areyouallergictoanymedications ?'Yes':'No'}}</td>
                            </tr>
                            <tr>
                                <th> Specify Medication Allergy : </th>
                                <td>{{item?.SpecifyMedicationAllergy}}</td>

                            </tr>
                            <tr>
                                <th> Are you allergic to intravenous contrast : </th>
                                <td>{{item?.Areyouallergictointravenouscontrast ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Specify intravenous contrast Allergy : </th>
                                <td>{{item?.SpecifyintravenouscontrastAllergy}}</td>

                            </tr>
                            <tr>
                                <th> Any Other Allergy : </th>
                                <td>{{item?.AnyOtherAllergy}}</td>

                            </tr>
                            <tr>
                                <th> Specify Any Other Allergy : </th>
                                <td>{{item?.SpecifyiAnyOtherAllergy}}</td>

                            </tr>
                        </div>
                        <div class="col-md-4">
                            <h2>
                                HEAD/ Associated Symptoms
                            </h2>
                            <tr>
                                <th> Clumsiness: </th>

                                <td>{{item?.Clumsiness ?'Yes':'No'}}</td>
                            </tr>
                            <tr>
                                <th> Confusion : </th>
                                <td>{{item?.Confusion ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Fever : </th>
                                <td>{{item?.Fever ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Incoordination : </th>
                                <td>{{item?.Incoordination ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Irritability : </th>
                                <td>{{item?.Irritability ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Loss of Consciousness : </th>
                                <td>{{item?.LossofConsciousness ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Loss of Sensation : </th>
                                <td>{{item?.LossofSensation ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Memory Difficulty : </th>
                                <td>{{item?.MemoryDifficulty ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Nausea : </th>
                                <td>{{item?.Nausea ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Personality Changes : </th>
                                <td>{{item?.PersonalityChanges ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Projectile Vomiting : </th>
                                <td>{{item?.ProjectileVomiting ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Prolonged Loss of Consciousness : </th>
                                <td>{{item?.ProlongedLossofConsciousness ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Restlessness : </th>
                                <td>{{item?.Restlessness ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Seizures : </th>
                                <td>{{item?.Seizures ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Speech Difficulty : </th>
                                <td>{{item?.SpeechDifficulty ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> StiffNeck : </th>
                                <td>{{item?.StiffNeck ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Unusual Behavior : </th>
                                <td>{{item?.UnusualBehavior ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Vision Changes : </th>
                                <td>{{item?.VisionChanges ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Vomiting : </th>
                                <td>{{item?.Vomiting ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> HeadOther : </th>
                                <td>{{item?.HeadOther ?'Yes':'No'}}</td>

                            </tr>

                            <tr>
                                <th> NoSymptoms : </th>
                                <td>{{item?.NoSymptoms ?'No Other Problems':'Other problems'}}</td>

                            </tr>
                            <tr>
                                <th> Other : </th>
                                <td>{{item?.OtherGeneralProblems}}</td>

                            </tr>
                        </div>
                    </div>


                    <div class="row">
                        <div class="col-md-4">
                            <h2>General</h2>
                            <tr>
                                <th> Recent Weight Changes : </th>

                                <td>{{item?.RecentWeightChanges ?'Yes':'No'}}</td>
                            </tr>
                            <tr>
                                <th> Chills : </th>
                                <td>{{item?.Chills ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Fever : </th>
                                <td>{{item?.Fever ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Weakness : </th>
                                <td>{{item?.Weakness ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> None : </th>
                                <td>{{item?.NoGeneralProblems ?'No Other Problems':'Other problems'}}</td>

                            </tr>
                            <tr>
                                <th> Other : </th>
                                <td>{{item?.OtherGeneralProblems}}</td>

                            </tr>
                        </div>
                        <div class="col-md-4">
                            <h2>Eyes</h2>
                            <tr>
                                <th> Vision Change : </th>
                                <td>{{item?.VisionChange ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Glassess/Contacts : </th>
                                <td>{{item?.GlassessOrContacts ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Catracts : </th>
                                <td>{{item?.Catracts ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Glaucoma : </th>
                                <td>{{item?.Glaucoma ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> None : </th>
                                <td>{{item?.NoEyeProblems ?'No Problemes':'Other Problems'}}</td>

                            </tr>
                            <tr>
                                <th>Other Problems : </th>
                                <td>{{item?.OtherEyeProblems}}</td>

                            </tr>
                        </div>
                        <div class="col-md-4">
                            <h2>Ear,Nose,Throat</h2>
                            <tr>
                                <th> HearingLoss : </th>
                                <td>{{item?.HearingLoss ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> EarAche/Infection : </th>
                                <td>{{item?.EarAcheOrInfection ?'Yes':'No'}}</td>

                            </tr>

                            <tr>
                                <th> Ringing In Ear : </th>
                                <td>{{item?.RingingInEar ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> None : </th>
                                <td>{{item?.NoEarProblems ?'No problems':'Other problems'}}</td>

                            </tr>
                            <tr>
                                <th> Other : </th>
                                <td>{{item?.OtherEarProblems}}</td>

                            </tr>
                        </div>
                    </div>
                    <hr>

                    <div class="row">
                        <div class="col-md-4">
                            <h2>Cardio Vascular </h2>
                            <tr>
                                <th> Chest Pain : </th>
                                <td>{{item?.ChestPain ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Swelling In Legs : </th>
                                <td>{{item?.SwellingInLegs ?'Yes':'No'}}</td>

                            </tr>

                            <tr>
                                <th> Palpitations : </th>
                                <td>{{item?.Palpitations ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> None : </th>
                                <td>{{item?.NoCadioVascularProblems ?'No problems':'Other problems'}}</td>

                            </tr>
                            <tr>
                                <th> Other : </th>
                                <td>{{item?.OtherCadioVascularProblems}}</td>

                            </tr>
                        </div>
                        <div class="col-md-4">
                            <h2>Respiratory </h2>
                            <tr>
                                <th> Shortness Of Breath : </th>
                                <td>{{item?.ShortnessOfBreath ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Wheezing Or Asthma : </th>
                                <td>{{item?.WheezingOrAsthma ?'Yes':'No'}}</td>

                            </tr>

                            <tr>
                                <th> Frequent Cough : </th>
                                <td>{{item?.FrequentCough ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> None : </th>
                                <td>{{item?.NoRespiratoryProblems ?'No problems':'Other problems'}}</td>

                            </tr>
                            <tr>
                                <th> Other : </th>
                                <td>{{item?.OtherRespiratoryProblems}}</td>

                            </tr>
                        </div>
                        <div class="col-md-4">
                            <h2>Gastrointestinal </h2>
                            <tr>
                                <th> Acid Reflex : </th>
                                <td>{{item?.AcidReflex ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Nausea Or Vomitimng : </th>
                                <td>{{item?.NauseaOrVomitimng ?'Yes':'No'}}</td>

                            </tr>

                            <tr>
                                <th> Abdominal Pain : </th>
                                <td>{{item?.AbdominalPain ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> None : </th>
                                <td>{{item?.OtherGastricProblems ?'No problems':'Other problems'}}</td>

                            </tr>
                            <tr>
                                <th> Other : </th>
                                <td>{{item?.OtherRespiratoryProblems}}</td>

                            </tr>
                        </div>
                    </div>
                    <hr>

                    <div class="row">
                        <div class="col-md-4">
                            <h2>Muskulatal</h2>
                            <tr>
                                <th> Muscle Aches : </th>
                                <td>{{item?.MuscleAches ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Swelling Of Joints : </th>
                                <td>{{item?.SwellingOfJoints ?'Yes':'No'}}</td>

                            </tr>

                            <tr>
                                <th> Stiffness In Joints : </th>
                                <td>{{item?.StiffnessInJoints ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> None : </th>
                                <td>{{item?.NoMuskularProblems ?'No problems':'Other problems'}}</td>

                            </tr>
                            <tr>
                                <th> Other : </th>
                                <td>{{item?.OtherMuskularProblems}}</td>

                            </tr>
                        </div>
                        <div class="col-md-4">
                            <h2>Skin</h2>
                            <tr>
                                <th> Rash : </th>
                                <td>{{item?.Rash ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Ulcers : </th>
                                <td>{{item?.Ulcers ?'Yes':'No'}}</td>

                            </tr>

                            <tr>
                                <th> Abnormal Scars : </th>
                                <td>{{item?.AbnormalScars ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> None : </th>
                                <td>{{item?.NoSkinProblems ?'No problems':'Other problems'}}</td>

                            </tr>
                            <tr>
                                <th> Other : </th>
                                <td>{{item?.OtherSkinProblems}}</td>

                            </tr>
                        </div>
                        <div class="col-md-4">
                            <h2>Neurological</h2>
                            <tr>
                                <th> Headaches : </th>
                                <td>{{item?.Headaches ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Dizziness : </th>
                                <td>{{item?.Dizziness ?'Yes':'No'}}</td>

                            </tr>

                            <tr>
                                <th> Numbness , Tingling , Loss of Sensation : </th>
                                <td>{{item?.NumbnessTinglingLossofSensation ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> None : </th>
                                <td>{{item?.NoNeurologicalProblems ?'No problems':'Other problems'}}</td>

                            </tr>
                            <tr>
                                <th> Other : </th>
                                <td>{{item?.OtherNeurologicalProblems}}</td>

                            </tr>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-md-4">
                            <h2>Psychiatric </h2>
                            <tr>
                                <th> Depression : </th>
                                <td>{{item?.Depression ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Nervousness : </th>
                                <td>{{item?.Nervousness ?'Yes':'No'}}</td>

                            </tr>

                            <tr>
                                <th> Anxiety : </th>
                                <td>{{item?.Anxiety ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Mood Swings : </th>
                                <td>{{item?.MoodSwings ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> None : </th>
                                <td>{{item?.NoPsychatricProblems ?'No problems':'Other problems'}}</td>

                            </tr>
                            <tr>
                                <th> Other : </th>
                                <td>{{item?.OtherPsychatricProblems}}</td>

                            </tr>
                        </div>
                        <div class="col-md-4">
                            <h2>Endocrine</h2>
                            <tr>
                                <th> Exessive Thirst Or Hunger : </th>
                                <td>{{item?.ExessiveThirstOrHunger ?'Yes':'No'}}</td>

                            </tr>
                            <tr>
                                <th> Hot Cold Intolerance : </th>
                                <td>{{item?.HotColdIntolerance ?'Yes':'No'}}</td>

                            </tr>

                            <tr>
                                <th> Hot Flashes : </th>
                                <td>{{item?.HotFlashes ?'Yes':'No'}}</td>

                            </tr>

                            <tr>
                                <th> None : </th>
                                <td>{{item?.NoEndocrine ?'No problems':'Other problems'}}</td>

                            </tr>
                            <tr>
                                <th> Other : </th>
                                <td>{{item?.OtherEndocrine}}</td>

                            </tr>
                        </div>
                        <div class="col-md-4"></div>
                    </div>


                </mat-card-content>


            </mat-card>
        </div>
    </div>
</div>

<!-- <mat-accordion>
    <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
            <mat-panel-title>
                Paralysis / Blood Thinners
            </mat-panel-title>

        </mat-expansion-panel-header>
       



    </mat-expansion-panel>
    <hr>
    <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
            <mat-panel-title>
                Quality of Pain
            </mat-panel-title>

        </mat-expansion-panel-header>
       
    </mat-expansion-panel>
    <hr>
    <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
            <mat-panel-title>
                Radiation of Pain
            </mat-panel-title>

        </mat-expansion-panel-header>
       
    </mat-expansion-panel>
    <hr>
    <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
            <mat-panel-title>
                Location of Pain
            </mat-panel-title>

        </mat-expansion-panel-header>
       

    </mat-expansion-panel>
    <hr>
    <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
            <mat-panel-title>
                ALLERGIES
            </mat-panel-title>

        </mat-expansion-panel-header>
       
    </mat-expansion-panel>
    <hr>
    <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
            <mat-panel-title>
                HEAD/ Associated Symptoms
            </mat-panel-title>

        </mat-expansion-panel-header>
       
    </mat-expansion-panel>
    <hr>
</mat-accordion> -->