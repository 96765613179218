import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { User } from 'src/app/Models/user.model';
import { UserService } from 'src/app/services/user.service';
import { PatientService } from '../../services/patient.service';
import { Patient } from '../../Models/patient.model';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, UntypedFormControl } from '@angular/forms';
import { DoctorService } from 'src/app/services/doctor.service';
import { PatientBooking } from 'src/app/Models/patientbooking.model';
import * as moment from 'moment';
import { AppointmentService } from 'src/app/services/appointment.service';
import { WardService } from 'src/app/services/ward.service';
import { Ward } from 'src/app/Models/ward.model';
import { HospitalService } from 'src/app/services/hospital.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-doctordashboard',
  templateUrl: './doctordashboard.component.html',
  styleUrls: ['./doctordashboard.component.css'],
})
export class DoctordashboardComponent implements OnInit {
  displayedColumns: string[] = [
    'FirstName',
    'LastName',
    'Gender',
    'PatientNationalId',
    'Email',
    'PhoneNumber',
    'PrimaryDoctor',
    'WardName',
    'Detail',
  ];
  dataSource: MatTableDataSource<any>;
  userList: Patient[] = [];
  id = 0;
  color = new UntypedFormControl('primary');

  helper = new JwtHelperService();
  myToken = window.localStorage.getItem('token');
  public todayAppointments: PatientBooking[];
  public showAllPatient = false;
  isUnderConsulation: any = {};
  DoctorName: string = '';
  showItem = '';
  DoctorId: string = '';
  doctorImage: string;
  drId = 0;
  Frm: FormGroup;
  wardList: Ward[];
  hospitalId: number;
  constructor(
    private patientService: PatientService,
    private doctorService: DoctorService,
    private fb: FormBuilder,
    private appointmentService: AppointmentService,
    private router: Router,
    private wardService: WardService,
    private hospitalService: HospitalService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    // this.reloadPage();
    const hasColor = localStorage.getItem('defaultThemeColor');
    if (hasColor?.length) {
      this.color.setValue(hasColor);
    }

    let decodedToken = this.helper.decodeToken(this.myToken);
    this.id = parseInt(decodedToken.nameid);

    this.DoctorId = this.id.toString();
    this.doctorService.getDoctorHospital(this.id).subscribe((res) => {
      this.hospitalId = res.Hospital.HospitalId;
      this.getWard(this.hospitalId);
    });
    this.doctorService.getDoctorById(this.DoctorId).subscribe({
      next: (res) => {
        this.DoctorName = res.DoctorName;
        this.doctorImage = res.DoctorImage;
        this.drId = res.DoctorId;
      },
    });

    setInterval(() => {}, 300e3);
    this.getDoctorBookings(this.id);

    this.Frm = this.fb.group({
      WardId: [''],
    });
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  get WardId() {
    return this.Frm.get('WardId');
  }

  getWard(id) {
    this.hospitalService.getHwards(id).subscribe((res) => {
      this.wardList = res.Wards;
    });
  }

  getPatients() {
    this.wardService.getWardPatients(this.WardId.value).subscribe(
      (res) => {
        this.dataSource = new MatTableDataSource(res.Patients);
        this.dataSource.paginator = this.paginator;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getDoctorBookings(id) {
    this.doctorService.getDoctorBooking(id).subscribe({
      next: (res) => {
        this.todayAppointments = res.PatientBookings.filter(
          (booking) =>
            moment(booking.VisitDate).isSame(moment(), 'day') &&
            (booking.Status === 'Confirmed' || booking.Status === 'Observing')
        ).sort((a, b) => a['Tocken'] - b['Tocken']);
        this.todayAppointments.forEach((appointment, id) => {
          if (appointment.Status === 'Observing')
            this.isUnderConsulation = appointment;
          this.patientService
            .getPatientById(appointment['PatientId'])
            .subscribe({
              next: (res) => {
                appointment[
                  'PatientName'
                ] = `${res.PatientFirstName} ${res.PatientLastName}`;
                appointment['DateOfBirth'] = `${res.PatientDOB}`;
              },
            });
        });
      },
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  // logout() {
  //   localStorage.removeItem('token');
  //   this.router.navigate(['/']);
  // }
  logout() {
    // Clear local session data
    localStorage.removeItem('authToken');
    // Set a flag to notify other tabs/windows
    // localStorage.setItem('logoutEvent', 'logout' + Math.random());
    window.localStorage.setItem('logout-event', Math.random().toString());
    // Redirect to login page
    this.router.navigate(['/']);
  }
  getValue() {
    localStorage.setItem('defaultThemeColor', this.color.value);
  }

  occupied(id, appointment) {
    this.appointmentService
      .updatePatientBooking(id, { ...appointment, Status: 'Observing' })
      .subscribe((res) => {
        this.getDoctorBookings(this.id);
        this.isUnderConsulation = appointment;
      });
  }

  complete(id, appointment) {
    this.appointmentService
      .updatePatientBooking(id, {
        ...appointment,
        Status: 'Completed',
        Tocken: '',
      })
      .subscribe((res) => {
        this.getDoctorBookings(this.id);
        this.isUnderConsulation = {};
      });
  }

  openRouteInNewWindow(route: string, id: number) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([route, id])
    );
    const newWindow = window.open(url, '_blank', 'width=800,height=600');

    if (newWindow) {
      newWindow.focus();
    }
  }
  public gotoProductDetails(url, id) {
    var myurl = `${url}/${id}`;
    this.router.navigateByUrl(myurl).then((e) => {
      if (e) {
        console.log('Navigation is successful!');
      } else {
        console.log('Navigation has failed!');
      }
    });
  }
  triggerAlert() {
    this.notificationService.showNotification('New message received!', 'Close');
  }

  reloadPage() {
    setTimeout(() => {
      window.location.reload();
    }, 100);
  }

  selectedTabIndex: number = 0;

  onTabChange(event: any): void {
    this.selectedTabIndex = event.index;
  }
  selectedView: string = '';
}
