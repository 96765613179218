import { Component, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import { PatientsPharmacy } from 'src/app/Models/patientspharmacy.model';
import { Prescription } from 'src/app/Models/prescription.model';
import { PatientService } from 'src/app/services/patient.service';
import { PrescriptionService } from 'src/app/services/prescription.service';

@Component({
  selector: 'app-card-view-prescription-patient-side',
  templateUrl: './card-view-prescription-patient-side.component.html',
  styleUrls: ['./card-view-prescription-patient-side.component.css'],
})
export class CardViewPrescriptionPatientSideComponent implements OnInit {
  prescriptions: Prescription[];
  id = 0;
  pharmacy: PatientsPharmacy[];
  constructor(
    private route: ActivatedRoute,
    private patientService: PatientService,
    private prescriptionService: PrescriptionService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    this.getPrescription(this.id);
    this.getPharmacy(this.id);
  }

  getPrescription(id) {
    this.patientService.getPatientPrescription(id).subscribe((res) => {
      this.prescriptions = res.Prescriptions.sort((x, y) => {
        return y.PrescriptionId - x.PrescriptionId;
      });
    });
  }
  tabChanged(event: MatTabChangeEvent): void {
    if (event.index === 0) {
      this.getPrescription(this.id);
    }
  }
  getPharmacy(id) {
    this.patientService.getPatientsPharmacy(id).subscribe((res) => {
      this.pharmacy = res.PatientsPharmacys;
    });
  }
  delete(id) {
    this.prescriptionService.deletePrescription(id).subscribe((res) => {
      this.getPrescription(this.id);
    });
  }
}
