import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { JwtHelperService } from '@auth0/angular-jwt';
import moment from 'moment';
import { Doctor } from 'src/app/Models/doctor.model';
import { DoctorService } from 'src/app/services/doctor.service';
import { DoctordatesService } from 'src/app/services/doctordates.service';
import { HospitalService } from 'src/app/services/hospital.service';

@Component({
  selector: 'app-add-my-off-days',
  templateUrl: './add-my-off-days.component.html',
  styleUrls: ['./add-my-off-days.component.css'],
})
export class AddMyOffDaysComponent implements OnInit {
  doctorList: Doctor[] = [];
  isSuccess = false;
  helper = new JwtHelperService();
  id = 0;
  myToken = window.localStorage.getItem('token');
  doctor: Doctor;
  constructor(
    private fb: UntypedFormBuilder,
    private doctorDatesService: DoctordatesService,
    private doctorService: DoctorService
  ) {}

  ngOnInit(): void {
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.id = parseInt(decodedToken.nameid);
    this.getDoctors(this.id);
  }

  timFrm = this.fb.group({
    AvailableDate: ['', Validators.required],
    // DoctorName: [''],
    // DoctorId: ['', Validators.required],
  });

  get DoctorName() {
    return this.timFrm.get('DoctorName');
  }
  get AvailableDate() {
    return this.timFrm.get('AvailableDate');
  }
  get DoctorId() {
    return this.timFrm.get('DoctorId');
  }

  getDoctors(id) {
    this.doctorService.getDoctorById(id).subscribe((res) => {
      this.doctor = res;
    });
  }

  formSubmit() {
    let availableDate = this.timFrm.value['AvailableDate'];
    availableDate = moment(availableDate).format('MM/DD/YYYY');
    // let doctorId = this.timFrm.value['DoctorId'];
    // let idoctorName = this.doctorList.filter((it) => it.DoctorId == doctorId)[0]
    //   .DoctorName;

    let body = {
      AvailableDate: availableDate,
      DoctorId: this.id,
      DoctorName: this.doctor.DoctorName,
    };
    this.doctorDatesService.registerDoctorDate(body).subscribe((res) => {
      this.isSuccess = true;
      this.timFrm.reset();
    });
  }
}
