import { ViewMedicationComponent } from './components/view-medication/view-medication.component';
import { PatientComponent } from './components/patient/patient.component';
import { PrescriptionDisplayComponent } from './components/prescription-display/prescription-display.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdmindashboardComponent } from './components/admindashboard/admindashboard.component';
import { DoctordashboardComponent } from './components/doctordashboard/doctordashboard.component';
import { LabdashboardComponent } from './components/labdashboard/labdashboard.component';
import { LoginComponent } from './components/login/login.component';
import { PatientdashboardComponent } from './components/patientdashboard/patientdashboard.component';

import { MedicationComponent } from './components/medication/medication.component';
import { ViewpatientComponent } from './components/viewpatient/viewpatient.component';
import { ViewDoctorComponent } from './components/view-doctor/view-doctor.component';
import { PatientdetailsComponent } from './components/patientdetails/patientdetails.component';
import { AddPatientVisitComponent } from './components/add-patient-visit/add-patient-visit.component';
import { NotepadComponent } from './components/notepad/notepad.component';
import { VewDoctorNoteComponent } from './components/vew-doctor-note/vew-doctor-note.component';
import { UpdatePatientVisitComponent } from './components/update-patient-visit/update-patient-visit.component';
import { ViewAllergyComponent } from './components/view-allergy/view-allergy.component';
import { AddAllergyComponent } from './components/add-allergy/add-allergy.component';
import { UpdateAllergyComponent } from './components/update-allergy/update-allergy.component';
import { AddpatientComponent } from './components/addpatient/addpatient.component';
import { AddPrescriptionComponent } from './components/add-prescription/add-prescription.component';
import { ViewSurgicalHistoryComponent } from './components/view-surgical-history/view-surgical-history.component';
import { AddSurgicalHistoryComponent } from './components/add-surgical-history/add-surgical-history.component';
import { AddHospitalComponent } from './components/add-hospital/add-hospital.component';
import { HospitaldetailsComponent } from './components/hospitaldetails/hospitaldetails.component';
import { HospitalDashboardComponent } from './components/hospital-dashboard/hospital-dashboard.component';
import { AddDoctorComponent } from './components/add-doctor/add-doctor.component';
import { AddDepartmentComponent } from './components/add-department/add-department.component';
import { AddNurseComponent } from './components/add-nurse/add-nurse.component';
import { AddWardComponent } from './components/add-ward/add-ward.component';
import { ViewDepartmentComponent } from './components/view-department/view-department.component';
import { DepartmentDashboardComponent } from './components/department-dashboard/department-dashboard.component';
import { ViewHospitalComponent } from './components/view-hospital/view-hospital.component';
import { AddAppointmentComponent } from './components/add-appointment/add-appointment.component';
import { AddInvoiceComponent } from './components/add-invoice/add-invoice.component';
import { AddLabreportComponent } from './components/add-labreport/add-labreport.component';
import { ViewInvoiceComponent } from './components/view-invoice/view-invoice.component';
import { ViewLabreportComponent } from './components/view-labreport/view-labreport.component';
import { ViewAllInvoicesComponent } from './components/view-all-invoices/view-all-invoices.component';
import { EmergencyContactComponent } from './components/emergency-contact/emergency-contact.component';
import { InsuranceComponent } from './components/insurance/insurance.component';
import { AddPatientInsuranceComponent } from './components/add-patient-insurance/add-patient-insurance.component';
import { AddEmergencyContactComponent } from './components/add-emergency-contact/add-emergency-contact.component';
import { PatientsummaryComponent } from './components/patientsummary/patientsummary.component';
import { PtmedicalrecordsComponent } from './components/ptmedicalrecords/ptmedicalrecords.component';
import { PtinvoicesComponent } from './components/ptinvoices/ptinvoices.component';
import { ViewAppointmentComponent } from './components/view-appointment/view-appointment.component';
import { PrintinvoiceComponent } from './components/printinvoice/printinvoice.component';
import { ViewAllAppointmentsComponent } from './components/view-all-appointments/view-all-appointments.component';
import { UpdateAppointmentComponent } from './components/update-appointment/update-appointment.component';
import { UpdateInvoiceComponent } from './components/update-invoice/update-invoice.component';
import { ViewDoctorAppointmentsComponent } from './components/view-doctor-appointments/view-doctor-appointments.component';
import { ViewDoctorPatientsComponent } from './components/view-doctor-patients/view-doctor-patients.component';
import { ViewNurseComponent } from './components/view-nurse/view-nurse.component';
import { AddMedicineComponent } from './components/add-medicine/add-medicine.component';
import { AddCptcodeComponent } from './components/add-cptcode/add-cptcode.component';
import { ViewMedicineComponent } from './components/view-medicine/view-medicine.component';
import { ViewCptcodeComponent } from './components/view-cptcode/view-cptcode.component';
import { DoctorPatientsComponent } from './components/doctor-patients/doctor-patients.component';
import { UpdateDoctorPatientVisitComponent } from './components/update-doctor-patient-visit/update-doctor-patient-visit.component';
import { UpdateNursePatientVisitComponent } from './components/update-nurse-patient-visit/update-nurse-patient-visit.component';
import { DoctorPatientVisitComponent } from './components/doctor-patient-visit/doctor-patient-visit.component';
import { NurseDashboardComponent } from './components/nurse-dashboard/nurse-dashboard.component';
import { NursePatientsComponent } from './components/nurse-patients/nurse-patients.component';
import { NursePatientDetailsComponent } from './components/nurse-patient-details/nurse-patient-details.component';
import { AddAppointmentPatientComponent } from './components/add-appointment-patient/add-appointment-patient.component';
import { AddMedicationComponent } from './components/add-medication/add-medication.component';
import { UpdatepatientComponent } from './components/updatepatient/updatepatient.component';
import { AddLabManagerComponent } from './components/add-lab-manager/add-lab-manager.component';
import { LabpatientComponent } from './components/labpatient/labpatient.component';
import { AddNursereportComponent } from './components/add-nursereport/add-nursereport.component';
import { UpdateNursereportComponent } from './components/update-nursereport/update-nursereport.component';
import { ViewNursereportComponent } from './components/view-nursereport/view-nursereport.component';
import { ViewPatientNursereportComponent } from './components/view-patient-nursereport/view-patient-nursereport.component';
import { UpdateMedicationComponent } from './components/update-medication/update-medication.component';
import { ViewNursePtMedicationComponent } from './components/view-nurse-pt-medication/view-nurse-pt-medication.component';
import { SetpasswordComponent } from './components/setpassword/setpassword.component';
import { ResetpasswordComponent } from './components/resetpassword/resetpassword.component';
import { ViewAppointmentsTodayComponent } from './components/view-appointments-today/view-appointments-today.component';
import { ViewDoctorAppointmentsTodayComponent } from './components/view-doctor-appointments-today/view-doctor-appointments-today.component';
import { DrawPadComponent } from './components/draw-pad/draw-pad.component';
import { ViewWardComponent } from './components/view-ward/view-ward.component';

import { ViewLabManagerComponent } from './components/view-lab-manager/view-lab-manager.component';

import { RoundbookComponent } from './components/roundbook/roundbook.component';
import { PtSummaryComponent } from './components/pt-summary/pt-summary.component';
import { AddTimeSlotComponent } from './components/add-time-slot/add-time-slot.component';
import { AddLabOrderComponent } from './components/add-lab-order/add-lab-order.component';
import { AddRadiologyOrderComponent } from './components/add-radiology-order/add-radiology-order.component';
import { ViewLabOrderComponent } from './components/view-lab-order/view-lab-order.component';
import { ViewRadiologyOrderComponent } from './components/view-radiology-order/view-radiology-order.component';
import { ViewAllLabOrdersComponent } from './components/view-all-lab-orders/view-all-lab-orders.component';
import { ViewAllRadiologyOrdersComponent } from './components/view-all-radiology-orders/view-all-radiology-orders.component';
import { AddNursingAssesmentComponent } from './components/add-nursing-assesment/add-nursing-assesment.component';
import { AddDoctorDatesComponent } from './components/add-doctor-dates/add-doctor-dates.component';

import { ViewNurseAssessmentComponent } from './components/view-nurse-assessment/view-nurse-assessment.component';
import { ViewNursingAssessmentPlanComponent } from './components/view-nursing-assessment-plan/view-nursing-assessment-plan.component';
import { VitalsChartComponent } from './components/vitals-chart/vitals-chart.component';
import { RadiologyDashboardComponent } from './components/radiology-dashboard/radiology-dashboard.component';
import { AddRadiologyMemberComponent } from './components/add-radiology-member/add-radiology-member.component';
import { ViewRadiologyMemberComponent } from './components/view-radiology-member/view-radiology-member.component';
import { RadiologyPatientComponent } from './components/radiology-patient/radiology-patient.component';
import { SigningPadComponent } from './components/signing-pad/signing-pad.component';
import { ViewPatientInsuranceComponent } from './components/view-patient-insurance/view-patient-insurance.component';
import { ViewPatientIdproofComponent } from './components/view-patient-idproof/view-patient-idproof.component';
import { AddPatientIdproofComponent } from './components/add-patient-idproof/add-patient-idproof.component';
import { AddMriComponent } from './components/add-mri/add-mri.component';
import { ViewMriComponent } from './components/view-mri/view-mri.component';
import { ScannerComponent } from './components/scanner/scanner.component';
import { SuperAdminDashboardComponent } from './components/super-admin-dashboard/super-admin-dashboard.component';
import { AddCompanyComponent } from './components/add-company/add-company.component';
import { CompanyComponent } from './components/company/company.component';
import { AuthguardGuardService } from './services/authguard-guard.service';
import { AdminGuard } from './admin/admin.guard';
import { UpdateRadiologyReportComponent } from './components/update-radiology-report/update-radiology-report.component';
import { AddRadiologyReportComponent } from './components/add-radiology-report/add-radiology-report.component';
import { UploadRadiologyReportComponent } from './components/upload-radiology-report/upload-radiology-report.component';
import { AllPatientsComponent } from './components/all-patients/all-patients.component';
import { PrintmedicationComponent } from './components/printmedication/printmedication.component';

import { UpdatePrescriptionComponent } from './components/update-prescription/update-prescription.component';
import { ViewDoctorAvailabilityComponent } from './components/view-doctor-availability/view-doctor-availability.component';
import { AddRadiologyOrderSubjectComponent } from './components/add-radiology-order-subject/add-radiology-order-subject.component';
import { AddLabOrderSubjectComponent } from './components/add-lab-order-subject/add-lab-order-subject.component';
import { ViewDischargeSummaryComponent } from './components/view-discharge-summary/view-discharge-summary.component';

import { AddVaccineComponent } from './components/add-vaccine/add-vaccine.component';
import { ViewNewLabOrderComponent } from './components/view-new-lab-order/view-new-lab-order.component';

import { AddCommunicationAssessmentComponent } from './components/add-communication-assessment/add-communication-assessment.component';
import { ViewIntakeAssessmentComponent } from './components/view-intake-assessment/view-intake-assessment.component';
import { ViewCommunicationAssessmentComponent } from './components/view-communication-assessment/view-communication-assessment.component';
import { NursePatientReportsComponent } from './components/nurse-patient-reports/nurse-patient-reports.component';
import { MedTrackerComponent } from './components/med-tracker/med-tracker.component';
import { UpdateIntakeAssessmentComponent } from './components/update-intake-assessment/update-intake-assessment.component';
import { UpdateCommunicationAssessmentComponent } from './components/update-communication-assessment/update-communication-assessment.component';
import { AddNurseNoteComponent } from './components/add-nurse-note/add-nurse-note.component';
import { AddDoctorReportComponent } from './components/add-doctor-report/add-doctor-report.component';
import { PatientIntakeItemsComponent } from './components/patient-intake-items/patient-intake-items.component';
import { TwofaComponent } from './components/twofa/twofa.component';
import { AddSocialhistoryComponent } from './components/add-socialhistory/add-socialhistory.component';
import { AddFamilyhistoryComponent } from './components/add-familyhistory/add-familyhistory.component';
import { ViewFamilyhistoryComponent } from './components/view-familyhistory/view-familyhistory.component';
import { ViewSocialhistoryComponent } from './components/view-socialhistory/view-socialhistory.component';
import { AddMedicalHistoryComponent } from './components/add-medical-history/add-medical-history.component';
import { ViewMedicalHistoryComponent } from './components/view-medical-history/view-medical-history.component';
import { ViewHippaComplianceComponent } from './components/view-hippa-compliance/view-hippa-compliance.component';
import { AddHippaComplianceComponent } from './components/add-hippa-compliance/add-hippa-compliance.component';

import { PrintlabreportComponent } from './components/printlabreport/printlabreport.component';
import { AddDoctorInitialEvaluationComponent } from './components/add-doctor-initial-evaluation/add-doctor-initial-evaluation.component';
import { ViewDoctorInitialEvaluationComponent } from './components/view-doctor-initial-evaluation/view-doctor-initial-evaluation.component';
import { UpdateDoctorInitialEvaluationComponent } from './components/update-doctor-initial-evaluation/update-doctor-initial-evaluation.component';
import { AddNurseDailyAssessmentComponent } from './components/add-nurse-daily-assessment/add-nurse-daily-assessment.component';
import { ViewNurseDailyAssessmentComponent } from './components/view-nurse-daily-assessment/view-nurse-daily-assessment.component';
import { UpdateNurseDailyAssessmentComponent } from './components/update-nurse-daily-assessment/update-nurse-daily-assessment.component';
import { UpdateNurseHandOffNoteComponent } from './components/update-nurse-hand-off-note/update-nurse-hand-off-note.component';
import { ViewNurseHandOffNoteByWardComponent } from './components/view-nurse-hand-off-note-by-ward/view-nurse-hand-off-note-by-ward.component';
import { ViewWardPatientsComponent } from './components/view-ward-patients/view-ward-patients.component';
import { UpdateSafetyManagementComponent } from './components/update-safety-management/update-safety-management.component';
import { AddDisclosureAndConsentComponent } from './components/add-disclosure-and-consent/add-disclosure-and-consent.component';
import { ViewDisclosureAndConsentComponent } from './components/view-disclosure-and-consent/view-disclosure-and-consent.component';
import { UpdateDisclosureAndConsentComponent } from './components/update-disclosure-and-consent/update-disclosure-and-consent.component';
import { AddMriScreeningComponent } from './components/add-mri-screening/add-mri-screening.component';
import { UpdateMriScreeningComponent } from './components/update-mri-screening/update-mri-screening.component';
import { ViewMriScreeningComponent } from './components/view-mri-screening/view-mri-screening.component';
import { AddPastmedicationComponent } from './components/add-pastmedication/add-pastmedication.component';
import { UpdatePastmedicationComponent } from './components/update-pastmedication/update-pastmedication.component';
import { ViewPastmedicationComponent } from './components/view-pastmedication/view-pastmedication.component';
import { ViewPatientLabreportComponent } from './components/view-patient-labreport/view-patient-labreport.component';
import { ViewVaccineComponent } from './components/view-vaccine/view-vaccine.component';
import { ViewPatientImmunizationComponent } from './components/view-patient-immunization/view-patient-immunization.component';
import { AddPatientPharmacyComponent } from './components/add-patient-pharmacy/add-patient-pharmacy.component';
import { ViewPatientPharmacyComponent } from './components/view-patient-pharmacy/view-patient-pharmacy.component';
import { UpdatePatientPharmacyComponent } from './components/update-patient-pharmacy/update-patient-pharmacy.component';
import { AddRefferalComponent } from './components/add-refferal/add-refferal.component';
import { ViewRefferalComponent } from './components/view-refferal/view-refferal.component';
import { AddUploadLabReporComponent } from './components/add-upload-lab-repor/add-upload-lab-repor.component';
import { ViewUploadLabReportComponent } from './components/view-upload-lab-report/view-upload-lab-report.component';
import { AddUploadRadiologyReportComponent } from './components/add-upload-radiology-report/add-upload-radiology-report.component';
import { ViewUploadRadiologyReportComponent } from './components/view-upload-radiology-report/view-upload-radiology-report.component';
import { PatientViewPastmedicationComponent } from './components/patient-view-pastmedication/patient-view-pastmedication.component';
import { PatientViewMedicalhistoryComponent } from './components/patient-view-medicalhistory/patient-view-medicalhistory.component';
import { PatientViewFamilyhistoryComponent } from './components/patient-view-familyhistory/patient-view-familyhistory.component';
import { PatientViewSocialhistoryComponent } from './components/patient-view-socialhistory/patient-view-socialhistory.component';
import { PatientViewIdproofComponent } from './components/patient-view-idproof/patient-view-idproof.component';
import { PatientViewHipaacomplianceComponent } from './components/patient-view-hipaacompliance/patient-view-hipaacompliance.component';
import { PatientViewNarcoticConsentComponent } from './components/patient-view-narcotic-consent/patient-view-narcotic-consent.component';
import { PatientViewGeneralConsentComponent } from './components/patient-view-general-consent/patient-view-general-consent.component';
import { PatientViewOfficePolicyComponent } from './components/patient-view-office-policy/patient-view-office-policy.component';
import { PatientViewPhotographicConsentComponent } from './components/patient-view-photographic-consent/patient-view-photographic-consent.component';
import { CompletedFormsComponent } from './components/completed-forms/completed-forms.component';
import { PatientDataScannerComponent } from './components/patient-data-scanner/patient-data-scanner.component';
import { DoctorPatientScannerComponent } from './components/doctor-patient-scanner/doctor-patient-scanner.component';
import { LabPatientScannerComponent } from './components/lab-patient-scanner/lab-patient-scanner.component';
import { ViewAllhospitalsComponent } from './components/view-allhospitals/view-allhospitals.component';
import { TwofaResetPassowrdComponent } from './twofa-reset-passowrd/twofa-reset-passowrd.component';
import { PrintInvoicePtSideComponent } from './components/print-invoice-pt-side/print-invoice-pt-side.component';
import { UpdateAppointmentByDoctorComponent } from './components/update-appointment-by-doctor/update-appointment-by-doctor.component';
import { ViewNursePatientsByHospitalComponent } from './components/view-nurse-patients-by-hospital/view-nurse-patients-by-hospital.component';
import { RadiologyPatientScannerComponent } from './components/radiology-patient-scanner/radiology-patient-scanner.component';
import { WritingPadComponent } from './components/writing-pad/writing-pad.component';
import { AddPaymentMethodComponent } from './components/add-payment-method/add-payment-method.component';
import { ViewPaymentMethodComponent } from './components/view-payment-method/view-payment-method.component';
import { UpdatePaymentMethodComponent } from './components/update-payment-method/update-payment-method.component';
import { PatientHasBalanceTopayComponent } from './components/patient-has-balance-topay/patient-has-balance-topay.component';
import { PatientHistoryComponent } from './components/patient-history/patient-history.component';
import { PharmacylistComponent } from './components/pharmacylist/pharmacylist.component';
import { UpdatePharmacylistComponent } from './components/update-pharmacylist/update-pharmacylist.component';
import { AddPaharmacyComponent } from './components/add-paharmacy/add-paharmacy.component';
import { ViewPrescriptionsTodayComponent } from './components/view-prescriptions-today/view-prescriptions-today.component';
import { AddPatientPrescriptionComponent } from './components/add-patient-prescription/add-patient-prescription.component';
import { ViewPatientPrescriptiionComponent } from './components/view-patient-prescriptiion/view-patient-prescriptiion.component';
import { UpdatePatientPrescriptiionComponent } from './components/update-patient-prescriptiion/update-patient-prescriptiion.component';
import { StaffViewPatientPrescriptionComponent } from './components/staff-view-patient-prescription/staff-view-patient-prescription.component';
import { PatientViewPatientPrescriptionComponent } from './components/patient-view-patient-prescription/patient-view-patient-prescription.component';
import { StaffViewPrescriptiondisplayComponent } from './components/staff-view-prescriptiondisplay/staff-view-prescriptiondisplay.component';
import { AddPrescriptionByNurseComponent } from './components/add-prescription-by-nurse/add-prescription-by-nurse.component';
import { ViewPrescriptionByNurseComponent } from './components/view-prescription-by-nurse/view-prescription-by-nurse.component';
import { AddRefillRequestComponent } from './components/add-refill-request/add-refill-request.component';
import { ViewRefillRequestComponent } from './components/view-refill-request/view-refill-request.component';
import { AppovePrescriptionComponent } from './components/appove-prescription/appove-prescription.component';
import { AppovePrescriptionByNurseComponent } from './components/appove-prescription-by-nurse/appove-prescription-by-nurse.component';
import { ViewRefillRequestByNurseComponent } from './components/view-refill-request-by-nurse/view-refill-request-by-nurse.component';
import { AddPatientPrescriptionByNurseComponent } from './components/add-patient-prescription-by-nurse/add-patient-prescription-by-nurse.component';
import { ViewPatientPrescriptionByNurseComponent } from './components/view-patient-prescription-by-nurse/view-patient-prescription-by-nurse.component';
import { CardViewPrescriptionComponent } from './components/card-view-prescription/card-view-prescription.component';
import { AddProcedureComponent } from './components/add-procedure/add-procedure.component';
import { ViewProcedureComponent } from './components/view-procedure/view-procedure.component';
import { UpdateProcedureComponent } from './components/update-procedure/update-procedure.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { AddTestResultsComponent } from './components/add-test-results/add-test-results.component';
import { ViewTestResultsComponent } from './components/view-test-results/view-test-results.component';
import { UpdateTestResultsComponent } from './components/update-test-results/update-test-results.component';
import { ViewNerveConductionStudyComponent } from './components/view-nerve-conduction-study/view-nerve-conduction-study.component';
import { UpdateNerveConductionStudyComponent } from './components/update-nerve-conduction-study/update-nerve-conduction-study.component';
import { AddNerveConductionStudyComponent } from './components/add-nerve-conduction-study/add-nerve-conduction-study.component';
import { AddNerveStudyResultsComponent } from './components/add-nerve-study-results/add-nerve-study-results.component';
import { ViewNerveStudyResultsComponent } from './components/view-nerve-study-results/view-nerve-study-results.component';
import { UpdateNerveStudyResultsComponent } from './components/update-nerve-study-results/update-nerve-study-results.component';
import { AddElectromyographyComponent } from './components/add-electromyography/add-electromyography.component';
import { UpdateElectromyographyComponent } from './components/update-electromyography/update-electromyography.component';
import { ViewElectromyographyComponent } from './components/view-electromyography/view-electromyography.component';
import { PatientHasInsurancePendingPaymentComponent } from './components/patient-has-insurance-pending-payment/patient-has-insurance-pending-payment.component';
import { PatientHasUnpaidAmountComponent } from './components/patient-has-unpaid-amount/patient-has-unpaid-amount.component';
import { BulkImportPatientComponent } from './components/bulk-import-patient/bulk-import-patient.component';
import { InvoiceHasABalanceComponent } from './components/invoice-has-a-balance/invoice-has-a-balance.component';
import { FaxComponent } from './components/fax/fax.component';
import { UpdatePrescriptionByNurseComponent } from './components/update-prescription-by-nurse/update-prescription-by-nurse.component';
import { ViewPatientPaymentComponent } from './components/view-patient-payment/view-patient-payment.component';
import { ViewPaymentHistoryComponent } from './components/view-payment-history/view-payment-history.component';
import { UpdateNurseNoteComponent } from './components/update-nurse-note/update-nurse-note.component';
import { ViewDoctorProfileComponent } from './components/view-doctor-profile/view-doctor-profile.component';
import { UpdateDoctorProfileComponent } from './components/update-doctor-profile/update-doctor-profile.component';
import { AddMyOffDaysComponent } from './components/add-my-off-days/add-my-off-days.component';
import { ViewMyOffDaysComponent } from './components/view-my-off-days/view-my-off-days.component';
import { ChatComponent } from './components/chat/chat.component';
import { ViewAppointmentStatusComponent } from './components/view-appointment-status/view-appointment-status.component';
import { ManualyConfirmAppointmentComponent } from './components/manualy-confirm-appointment/manualy-confirm-appointment.component';
import { DoctorPatientSplitScreenComponent } from './components/doctor-patient-split-screen/doctor-patient-split-screen.component';
import { TabsComponentComponent } from './components/tabs-component/tabs-component.component';
import { NewOrdersComponent } from './components/new-orders/new-orders.component';
import { ViewOrdersComponent } from './components/view-orders/view-orders.component';
import { AllTestResultsComponent } from './components/all-test-results/all-test-results.component';
import { CallEncounterComponent } from './components/call-encounter/call-encounter.component';
import { Tabs2ComponentComponent } from './components/tabs2-component/tabs2-component.component';
import { UpdateDoctorReportComponent } from './components/update-doctor-report/update-doctor-report.component';
import { UpdateRefferalComponent } from './components/update-refferal/update-refferal.component';
import { DoctorCustomTemplateComponent } from './components/doctor-custom-template/doctor-custom-template.component';
import { AddPrescriptionFreeTextComponent } from './components/add-prescription-free-text/add-prescription-free-text.component';
import { DoctorTemplateEditorComponent } from './components/doctor-template-editor/doctor-template-editor.component';
import { DoctorTemplateListComponent } from './components/doctor-template-list/doctor-template-list.component';
import { CalculatorComponent } from './components/calculator/calculator.component';
import { TemplateTextboxComponentComponent } from './components/template-textbox-component/template-textbox-component.component';
import { UserActivityLogComponent } from './components/user-activity-log/user-activity-log.component';
import { HospitalTemplateComponent } from './components/hospital-template/hospital-template.component';

const routes: Routes = [
  { path: '', component: LoginComponent },
  {
    path: 'medication',
    component: MedicationComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'patientDash/:id',
    component: PatientdashboardComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'doctorDash',
    component: DoctordashboardComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'nurseDash',
    component: NurseDashboardComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'labDash',
    component: LabdashboardComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'radiologyDash',
    component: RadiologyDashboardComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'adminDash',
    component: AdmindashboardComponent,
    canActivate: [AuthguardGuardService, AdminGuard],
  },
  {
    path: 'superAdminDash',
    component: SuperAdminDashboardComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'createPatient',
    component: AddpatientComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewPatient',
    component: ViewpatientComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'PharmacyList',
    component: PharmacylistComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'AddPharmacyList',
    component: AddPaharmacyComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'UpdatePharmacyList/:id',
    component: UpdatePharmacylistComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewAllNursePatient',
    component: ViewNursePatientsByHospitalComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewWardPatients/:id',
    component: ViewWardPatientsComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'nursePatient/:id',
    component: NursePatientsComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'labPatient/:id',
    component: LabpatientComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'radiologyPatient/:id',
    component: RadiologyPatientComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewDepartment',
    component: ViewDepartmentComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'patientDetails/:id',
    component: PatientdetailsComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'nursePatientDetails/:id',
    component: NursePatientDetailsComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'doctorPatientVisit/:id',
    component: DoctorPatientVisitComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'hospitalDash',
    component: HospitalDashboardComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'dptDash/:id',
    component: DepartmentDashboardComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewNurse',
    component: ViewNurseComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'viewDoctor',
    component: ViewDoctorComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewDoctorPatients/:id',
    component: ViewDoctorPatientsComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'addDepartment/:id',
    component: AddDepartmentComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addDoctor',
    component: AddDoctorComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addNurse',
    component: AddNurseComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addRadiologyMember',
    component: AddRadiologyMemberComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addWard/:id',
    component: AddWardComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'addAppointment/:id',
    component: AddAppointmentComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addAppointmentPT/:id',
    component: AddAppointmentPatientComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'addInvoice/:id',
    component: AddInvoiceComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addLabreport/:id',
    component: AddLabreportComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addLabOrder/:id',
    component: AddLabOrderComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addRadiologyOrder/:id',
    component: AddRadiologyOrderComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addRadiologyReport/:id',
    component: AddRadiologyReportComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'uploadRadiologyReport/:id',
    component: UploadRadiologyReportComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'ViewPaymentMethod/:id',
    component: ViewPaymentMethodComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'UpdatePaymentMethod/:id',
    component: UpdatePaymentMethodComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addPaymentMethod',
    component: AddPaymentMethodComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addMRI/:id',
    component: AddMriComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addPatient',
    component: AddpatientComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addLabManager',
    component: AddLabManagerComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'addHospital',
    component: AddHospitalComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addCompany',
    component: AddCompanyComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'allPatient/:id',
    component: AllPatientsComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewPatient/:id',
    component: ViewpatientComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewPatienthasBalanceToPay/:id',
    component: PatientHasBalanceTopayComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewPatienthasInsurancePendingPayment/:id',
    component: PatientHasInsurancePendingPaymentComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewPatienthasUnPaidBalance/:id',
    component: PatientHasUnpaidAmountComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addMedicine',
    component: AddMedicineComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addCptCode',
    component: AddCptcodeComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'addCommunicationAssessment/:id',
    component: AddCommunicationAssessmentComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addNurseNote/:id',
    component: AddNurseNoteComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addDoctorReport/:id',
    component: AddDoctorReportComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'updateDoctorReport/:id/:PatientId',
    component: UpdateDoctorReportComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addPatientIntakeItems/:id',
    component: PatientIntakeItemsComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addPatientHistory/:id',
    component: PatientHistoryComponent,
    canActivate: [AuthguardGuardService],
  },
  { path: 'scanner', component: ScannerComponent },
  {
    path: 'PatientDataScanner',
    component: PatientDataScannerComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'WritingPad',
    component: WritingPadComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'doctorPatientScanner',
    component: DoctorPatientScannerComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'labPatientScanner',
    component: LabPatientScannerComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'radiologyPatientScanner',
    component: RadiologyPatientScannerComponent,
    canActivate: [AuthguardGuardService],
  },

  { path: 'twofa', component: TwofaComponent },

  {
    path: 'addWard',
    component: AddWardComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'addRadiologyOrderSubject',
    component: AddRadiologyOrderSubjectComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addLabOrderSubject',
    component: AddLabOrderSubjectComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'addPatientVisit/:id',
    component: AddPatientVisitComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'updatePatientVisit/:id',
    component: UpdatePatientVisitComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'updatePatient/:id',
    component: UpdatepatientComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'updateNurseReport/:id',
    component: UpdateNursereportComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'updateNurseNote/:id',
    component: UpdateNurseNoteComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'updateDoctorPatientVisit/:id/:PatientId',
    component: UpdateDoctorPatientVisitComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'updateNursePatientVisit/:id',
    component: UpdateNursePatientVisitComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addAllergy/:id',
    component: AddAllergyComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addPharmacy/:id',
    component: AddPatientPharmacyComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewPharmacy/:id',
    component: ViewPatientPharmacyComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'updatePharmacy/:id',
    component: UpdatePatientPharmacyComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewDoctorProfile/:id',
    component: ViewDoctorProfileComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'updateDoctorProfile/:id',
    component: UpdateDoctorProfileComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addPastMedication/:id',
    component: AddPastmedicationComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'updatePastMedication/:id',
    component: UpdatePastmedicationComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewPastMedication/:id',
    component: ViewPastmedicationComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addVaccine/:id',
    component: AddVaccineComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'iewVaccine/:id',
    component: ViewVaccineComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewPateintImmunization/:id',
    component: ViewPatientImmunizationComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addPrescription/:id',
    component: AddPrescriptionComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addPrescriptionFreeText/:id',
    component: AddPrescriptionFreeTextComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addPrescriptionByNurse/:id',
    component: AddPrescriptionByNurseComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'cardViewPrescription/:id',
    component: CardViewPrescriptionComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewPrescriptionByNurse/:id',
    component: ViewPrescriptionByNurseComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addPatientPrescription/:id',
    component: AddPatientPrescriptionComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addPatientPrescriptionByNurse/:id',
    component: AddPatientPrescriptionByNurseComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewPatientPrescriptionByNurse/:id',
    component: ViewPatientPrescriptionByNurseComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'staffViewPatientPrescription/:id',
    component: StaffViewPatientPrescriptionComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'staffViewPrescriptionDisplay/:id',
    component: StaffViewPrescriptiondisplayComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'patientViewPatientPrescription/:id',
    component: PatientViewPatientPrescriptionComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewPatientPrescription/:id',
    component: ViewPatientPrescriptiionComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'updatePatientPrescription/:id',
    component: UpdatePatientPrescriptiionComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'todaysPrescriptions/:id',
    component: ViewPrescriptionsTodayComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addRefillRequest/:id',
    component: AddRefillRequestComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewRefillRequest/:id',
    component: ViewRefillRequestComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewRefillRequestByNurse/:id',
    component: ViewRefillRequestByNurseComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addMedication/:id',
    component: AddMedicationComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addNurseReport/:id',
    component: AddNursereportComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addSurgicalHistory/:id',
    component: AddSurgicalHistoryComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addFamilyHistory/:id',
    component: AddFamilyhistoryComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addSocialHistory/:id',
    component: AddSocialhistoryComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addMedicalHistory/:id',
    component: AddMedicalHistoryComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addHippaCompliance/:id',
    component: AddHippaComplianceComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'addEmergencyContact/:id',
    component: AddEmergencyContactComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addPatientInsurance/:id',
    component: AddPatientInsuranceComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addPatientIdProof/:id',
    component: AddPatientIdproofComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'addTimeSlot',
    component: AddTimeSlotComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addDoctorDate',
    component: AddDoctorDatesComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addMyOffDays',
    component: AddMyOffDaysComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'addDisclosureandConsent/:id',
    component: AddDisclosureAndConsentComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewDisclosureandConsent/:id',
    component: ViewDisclosureAndConsentComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'updateDisclosureAndConsent/:id',
    component: UpdateDisclosureAndConsentComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addMriScreening/:id',
    component: AddMriScreeningComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewMriScreening/:id',
    component: ViewMriScreeningComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'updateMriScreening/:id',
    component: UpdateMriScreeningComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addNursingAssessment/:id',
    component: AddNursingAssesmentComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'addDctorEvaluation/:id',
    component: AddDoctorInitialEvaluationComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewDctorEvaluation/:id',
    component: ViewDoctorInitialEvaluationComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'updateDctorEvaluation/:id/:PatientId',
    component: UpdateDoctorInitialEvaluationComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'editNurseDailyAssessment/:id',
    component: UpdateNurseDailyAssessmentComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addNurseDailyAssessment/:id',
    component: AddNurseDailyAssessmentComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewNurseDailyAssessment/:id',
    component: ViewNurseDailyAssessmentComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'viewNurseHandOffNoteByWard/:id',
    component: ViewNurseHandOffNoteByWardComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'updateNurseHandOffNote/:id',
    component: UpdateNurseHandOffNoteComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'patientInsurance/:id',
    component: ViewPatientInsuranceComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'patientIdProof/:id',
    component: ViewPatientIdproofComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'printMedication/:id',
    component: PrintmedicationComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'medTracker/:id',
    component: MedTrackerComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'reportsNp/:id',
    component: NursePatientReportsComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'editIntakeAssessment/:id',
    component: UpdateIntakeAssessmentComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'editCommunicationAssessment/:id',
    component: UpdateCommunicationAssessmentComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'editAllergy/:id',
    component: UpdateAllergyComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'editAppointment/:id',
    component: UpdateAppointmentComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'editAppointmentByDoctor/:id',
    component: UpdateAppointmentByDoctorComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'editInvoice/:id',
    component: UpdateInvoiceComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'editNursePtMedication/:id',
    component: UpdateMedicationComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'notePad',
    component: NotepadComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'drawPad/:id',
    component: DrawPadComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'roundBook/:id',
    component: RoundbookComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'viewIntakeAssessment/:id',
    component: ViewIntakeAssessmentComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewCommunicationAssessment/:id',
    component: ViewCommunicationAssessmentComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'company',
    component: CompanyComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewHospital/:id',
    component: ViewHospitalComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewAllHospital',
    component: ViewAllhospitalsComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'viewRadiologyMember',
    component: ViewRadiologyMemberComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewLabManager',
    component: ViewLabManagerComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewHospital',
    component: ViewHospitalComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewDoctorNote/:id',
    component: VewDoctorNoteComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewAllergy/:id',
    component: ViewAllergyComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewInvoice/:id',
    component: ViewInvoiceComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewLabReport/:id',
    component: ViewLabreportComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewPatientLabReport/:id',
    component: ViewPatientLabreportComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewLabOrder/:id',
    component: ViewLabOrderComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewNewLabOrder/:id',
    component: ViewNewLabOrderComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'editSafetyManagement/:id',
    component: UpdateSafetyManagementComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewAllLabOrder',
    component: ViewAllLabOrdersComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewRadiologyOrder/:id',
    component: ViewRadiologyOrderComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewAllRadiologyOrders',
    component: ViewAllRadiologyOrdersComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewPrescription/:id',
    component: PrescriptionDisplayComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewSurgicalHistory/:id',
    component: ViewSurgicalHistoryComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewFamliyHistory/:id',
    component: ViewFamilyhistoryComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewSocialHistory/:id',
    component: ViewSocialhistoryComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewMedicalHistory/:id',
    component: ViewMedicalHistoryComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewHippaCompliance/:id',
    component: ViewHippaComplianceComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'viewAllInvoices',
    component: ViewAllInvoicesComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewAllAppointments',
    component: ViewAllAppointmentsComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewTodaysAppointments',
    component: ViewAppointmentsTodayComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewPTinsurance',
    component: InsuranceComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewMedicine',
    component: ViewMedicineComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewMedication',
    component: ViewMedicationComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewNursePtMedication',
    component: ViewNursePtMedicationComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewNurseReport',
    component: ViewNursereportComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewPatientNurseReport',
    component: ViewPatientNursereportComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewCptCode',
    component: ViewCptcodeComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'viewNursingAssessmentPlan',
    component: ViewNursingAssessmentPlanComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'viewNursingAssessmentPlanById/:id',
    component: ViewNurseAssessmentComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'vitalsChart/:id',
    component: VitalsChartComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewDischargeSummary/:id',
    component: ViewDischargeSummaryComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'viewMri/:id',
    component: ViewMriComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'viewWard',
    component: ViewWardComponent,
    canActivate: [AuthguardGuardService],
  },

  { path: 'signature', component: SigningPadComponent },

  {
    path: 'updateRadiologyReport/:id',
    component: UpdateRadiologyReportComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'updatePrescription/:id/:PatientId',
    component: UpdatePrescriptionComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'updatePrescriptionNurse/:id',
    component: UpdatePrescriptionByNurseComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'appovePrescription/:id',
    component: AppovePrescriptionComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'appovePrescriptionByNurse/:id',
    component: AppovePrescriptionByNurseComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'viewDoctorAvaialabilty',
    component: ViewDoctorAvailabilityComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewDoctorOffDays',
    component: ViewMyOffDaysComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewEmergencyContact',
    component: EmergencyContactComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'patientSummary/:id',
    component: PatientsummaryComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'ptMedicalRecords/:id',
    component: PtmedicalrecordsComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'ptInvoices/:id',
    component: PtinvoicesComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'ptAppointment/:id',
    component: ViewAppointmentComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addRefferal/:id',
    component: AddRefferalComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'updateRefferal/:id/:PatientId',
    component: UpdateRefferalComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'uploadLabreport/:id',
    component: AddUploadLabReporComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewUploadLabreport/:id',
    component: ViewUploadLabReportComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'uploadRadiolgyreport/:id',
    component: AddUploadRadiologyReportComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewUploadRadiologyreport/:id',
    component: ViewUploadRadiologyReportComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewRefferal/:id',
    component: ViewRefferalComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'drAppointment/:id',
    component: ViewDoctorAppointmentsComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'drTodaysAppointment/:id',
    component: ViewDoctorAppointmentsTodayComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'patient/:id',
    component: PatientComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'doctorPatient/:id',
    component: DoctorPatientsComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'printInvoice/:id',
    component: PrintinvoiceComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'printInvoicePatient/:id',
    component: PrintInvoicePtSideComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'printlabreport/:id',
    component: PrintlabreportComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'patientViewPastMedication/:id',
    component: PatientViewPastmedicationComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'patientViewMedicalHistory/:id',
    component: PatientViewMedicalhistoryComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'patientViewfamilyhistory/:id',
    component: PatientViewFamilyhistoryComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'patientViewsocialhistory/:id',
    component: PatientViewSocialhistoryComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'patientViewIdProof/:id',
    component: PatientViewIdproofComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'patientViewHippa/:id',
    component: PatientViewHipaacomplianceComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'patientViewNarcotic/:id',
    component: PatientViewNarcoticConsentComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'patientViewGeneralConsent/:id',
    component: PatientViewGeneralConsentComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'patientViewOfficePolicy/:id',
    component: PatientViewOfficePolicyComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'patientViewPhotographicConsent/:id',
    component: PatientViewPhotographicConsentComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addProcedure/:id',
    component: AddProcedureComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewProcedure/:id',
    component: ViewProcedureComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'updateProcedure/:id',
    component: UpdateProcedureComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addProcedureTestResults/:id',
    component: AddTestResultsComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewProcedureTestResults/:id',
    component: ViewTestResultsComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'updateProcedureTestResults/:id',
    component: UpdateTestResultsComponent,
    canActivate: [AuthguardGuardService],
  },

  {
    path: 'addNerveStudyResults/:id',
    component: AddNerveStudyResultsComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewNerveStudyResults/:id',
    component: ViewNerveStudyResultsComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'updateNerveStudyResults/:id',
    component: UpdateNerveStudyResultsComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addElectromyograpgy/:id',
    component: AddElectromyographyComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewElectromyograpgy/:id',
    component: ViewElectromyographyComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'updateElectromyograpgy/:id',
    component: UpdateElectromyographyComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'addNerveConductionStudies',
    component: AddNerveConductionStudyComponent,
    // canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewNerveConductionStudies',
    component: ViewNerveConductionStudyComponent,
    // canActivate: [AuthguardGuardService],
  },
  {
    path: 'updateNerveConductionStudies/:id',
    component: UpdateNerveConductionStudyComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'newOrders/:id',
    component: NewOrdersComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewOrders/:id',
    component: ViewOrdersComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewAllTestResults/:id',
    component: AllTestResultsComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'completedForms/:id',
    component: CompletedFormsComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'bulkImportDatas',
    component: BulkImportPatientComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'invoiceBalances/:id',
    component: InvoiceHasABalanceComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewPatientPayment/:id',
    component: ViewPatientPaymentComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewPaymentHistory/:id',
    component: ViewPaymentHistoryComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'AppointmentStatus/:id',
    component: ViewAppointmentStatusComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'manualyConfirmAppointment/:id',
    component: ManualyConfirmAppointmentComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'splitScreenDctorPatient/:id',
    component: DoctorPatientSplitScreenComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'callEncounter/:id',
    component: CallEncounterComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'doctorPersonalTmplte/:id',
    component: DoctorCustomTemplateComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'doctorTemplte/:id',
    component: DoctorTemplateEditorComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'viewDoctorTemplte/:id',
    component: DoctorTemplateListComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'tabs',
    component: TabsComponentComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'tabs2',
    component: Tabs2ComponentComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'fax',
    component: FaxComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'ActivityLogs',
    component: UserActivityLogComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'chat/:id',
    component: ChatComponent,
    canActivate: [AuthguardGuardService],
  },
  {
    path: 'calculator',
    component: CalculatorComponent,
    // canActivate: [AuthguardGuardService],
  },
  {
    path: 'h-template',
    component: HospitalTemplateComponent,
    // canActivate: [AuthguardGuardService],
  },
  {
    path: 'templateText',
    component: TemplateTextboxComponentComponent,
    // canActivate: [AuthguardGuardService],
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
  },
  { path: 'setpassword', component: SetpasswordComponent },
  { path: 'resetpassword', component: ResetpasswordComponent },
  { path: 'resetTwofa', component: TwofaResetPassowrdComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
