import { Component, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import { InPatientScreeningMRI } from 'src/app/Models/inpatientscreeningmri.model';
import { InpatientScreeningMriService } from 'src/app/services/inpatient-screening-mri.service';
import { PatientService } from 'src/app/services/patient.service';

@Component({
  selector: 'app-view-mri-screening',
  templateUrl: './view-mri-screening.component.html',
  styleUrls: ['./view-mri-screening.component.css'],
})
export class ViewMriScreeningComponent implements OnInit {
  panelOpenState = false;
  id = 0;
  mriScreeningList: InPatientScreeningMRI[] = [];
  constructor(
    private route: ActivatedRoute,
    private patientService: PatientService,
    private mriScreeningService: InpatientScreeningMriService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    this.getmriScreening(this.id);
  }

  getmriScreening(id) {
    this.patientService.getInPatientScreeningMRI(id).subscribe((res) => {
      this.mriScreeningList = res?.InPatientScreeningMRIs;
    });
  }

  print() {
    window.print();
  }
  tabChanged(event: MatTabChangeEvent): void {
    if (event.index === 0) {
      this.getmriScreening(this.id);
    }
  }

  delete(id) {
    this.mriScreeningService
      .deleteInPatientScreeningMRI(id)
      .subscribe((res) => {
        this.getmriScreening(this.id);
      });
  }
}
