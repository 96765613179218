import { Component } from '@angular/core';

@Component({
  selector: 'app-view-appointment-status',
  templateUrl: './view-appointment-status.component.html',
  styleUrls: ['./view-appointment-status.component.css']
})
export class ViewAppointmentStatusComponent {

}
