import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { Doctor } from 'src/app/Models/doctor.model';
import { Medicine } from 'src/app/Models/medicine.model';
import { Patient } from 'src/app/Models/patient.model';
import { DoctorService } from 'src/app/services/doctor.service';
import { EmailService } from 'src/app/services/email.service';
import { MedicineService } from 'src/app/services/medicine.service';
import { PatientService } from 'src/app/services/patient.service';
import { PrescriptionService } from 'src/app/services/prescription.service';

@Component({
  selector: 'app-appove-prescription',
  templateUrl: './appove-prescription.component.html',
  styleUrls: ['./appove-prescription.component.css'],
})
export class AppovePrescriptionComponent implements OnInit {
  id = 0;
  doctorId = 0;
  helper = new JwtHelperService();
  myToken = window.localStorage.getItem('token');
  states;
  isSuccess = false;
  doctorList: Doctor[] = [];
  patient: Patient;
  flteredmedicineList: Observable<any>;

  QTimes: string[] = [
    'Q1-Every Hour',
    'Q2-Every 2 hours',
    'Q3-Every 3 hours',
    'Q4-Every 4 hours',
    'Q5-Every 5 hours',
    'Q4-Every 6 hours',
    'Q4-Every 6 hours',
    'Q8-Every 8 hours',
    'Q12-Every 12 hours',
  ];

  DrugsList: string[] = [
    'Calpol 200mg',
    'Paracetamol 200mg',
    'Co-codomol 200mg',
    'Ibuprofen 200mg',
  ];
  instructionList: string[] = [
    'Take 1 tablet in the Morning',
    'Take 2 tablet in the Morning',
    'Take 3 tablet in the Morning',
    'Take 1 tablet in the Afternoon',
    'Take 2 tablet in the Afternoon',
    'Take 3 tablet in the Afternoon',
    'Take 1 tablet in the Evening',
    'Take 2 tablet in the Evening',
    'Take 3 tablet in the Evening',
    'Take 1 tablet at bedtime',
    'Take 2 tablet at bedtime',
    'Take 3 tablet at bedtime',
    'Take 1 tablet in the Morning and 1 tablet at bedtime',
    'Take 2 tablet in the Morning and 2 tablet at bedtime',
    'Take 3 tablet in the Morning and 3 tablet at bedtime',
    'Take 1 tablet in the Morning ,ake 1 tablet in the Afternoon and Take 1 tablet in the Evening ',
    'Take 2 tablet in the Morning ,ake 2 tablet in the Afternoon and Take 2 tablet in the Evening ',
    'Take 3 tablet in the Morning ,ake 3 tablet in the Afternoon and Take 3 tablet in the Evening ',
    'Take 1 tablet in the Morning ,ake 1 tablet in the Afternoon and Take 1 tablet in the bedtime ',
    'Take 2 tablet in the Morning ,ake 2 tablet in the Afternoon and Take 2 tablet in the bedtime ',
    'Take 3 tablet in the Morning ,ake 3 tablet in the Afternoon and Take 3 tablet in the bedtime ',
    'Take 1 tablet b.i.d as needed',
    'Take 2 tablet b.i.d as needed',
    'Take 3 tablet b.i.d as needed',
    'Take 1 tablet t.i.d as needed',
    'Take 2 tablet t.i.d as needed',
    'Take 3 tablet t.i.d as needed',
    'Take 1 tablet every hour',
    'Take 1 tablet every 2 hours',
    'Take 1 tablet every 4 hours',
    'Take 1 tablet every 6 hours',
    'Take 1 tablet every 8 hours',
    'Take 1 tablet every 12 hours',
  ];
  medicineList: Medicine[];
  constructor(
    private fb: UntypedFormBuilder,
    private prescriptionService: PrescriptionService,
    private router: Router,
    private route: ActivatedRoute,
    private medicineService: MedicineService,
    private doctorService: DoctorService,
    private emailService: EmailService,
    private patientService: PatientService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });

    let decodedToken = this.helper.decodeToken(this.myToken);
    this.doctorId = parseInt(decodedToken.nameid);

    this.getMedicine();

    this.prescriptionService.getPrescriptionById(this.id).subscribe((res) => {
      this.prescriptionFrm.patchValue(res);
      this.RefillDuration.setValue(res.RefillDuration);
      this.getPatient(res.PatientId);
    });
    this.getDoctors();
  }

  prescriptionFrm = this.fb.group({
    DrugName: ['', Validators.required],
    Strength: ['', Validators.required],
    BrandName: [''],
    Quantity: ['', Validators.required],
    DatePrescribed: ['', Validators.required],
    Instruction: ['', Validators.required],
    Note: [''],
    DoctorId: ['', Validators.required],
    DoctorSignature: ['', Validators.required],
    MorningTime: [''],
    AfternoonTime: [''],
    EveningTime: [''],
    NightTime: [''],
    QTime: [''],
    Status: [],
    StartDate: [''],
    EndDate: [''],
    RefillDuration: [''],
    Numberofrefills: [''],
    DoctorName: [''],
    PatientId: [],
  });

  get Note() {
    return this.prescriptionFrm.get('Note');
  }
  get Numberofrefills() {
    return this.prescriptionFrm.get('Numberofrefills');
  }
  get PatientId() {
    return this.prescriptionFrm.get('PatientId');
  }
  get RefillDuration() {
    return this.prescriptionFrm.get('RefillDuration');
  }

  get DoctorName() {
    return this.prescriptionFrm.get('DoctorName');
  }

  get StartDate() {
    return this.prescriptionFrm.get('StartDate');
  }

  get EndDate() {
    return this.prescriptionFrm.get('EndDate');
  }
  get Status() {
    return this.prescriptionFrm.get('Status');
  }
  get MorningTime() {
    return this.prescriptionFrm.get('MorningTime');
  }
  get AfternoonTime() {
    return this.prescriptionFrm.get('AfternoonTime');
  }
  get EveningTime() {
    return this.prescriptionFrm.get('EveningTime');
  }
  get NightTime() {
    return this.prescriptionFrm.get('NightTime');
  }
  get QTime() {
    return this.prescriptionFrm.get('QTime');
  }
  get DoctorId() {
    return this.prescriptionFrm.get('DoctorId');
  }
  get DoctorSignature() {
    return this.prescriptionFrm.get('DoctorSignature');
  }
  get Quantity() {
    return this.prescriptionFrm.get('Quantity');
  }
  get DrugName() {
    return this.prescriptionFrm.get('DrugName');
  }
  get Dosage() {
    return this.prescriptionFrm.get('Strength');
  }

  get BrandName() {
    return this.prescriptionFrm.get('BrandName');
  }
  get DatePrescribed() {
    return this.prescriptionFrm.get('DatePrescribed');
  }

  get Instruction() {
    return this.prescriptionFrm.get('Instruction');
  }

  getMedicine() {
    this.medicineService.getMedicines().subscribe((res) => {
      this.medicineList = res;
    });
  }
  getDoctors() {
    this.doctorService.getDoctors().subscribe((res) => {
      this.doctorList = res;
    });
  }
  getPatient(id) {
    this.patientService.getPatientById(id).subscribe((res) => {
      this.patient = res;
    });
  }

  formSubmit() {
    let patientId = this.prescriptionFrm.value['PatientId'];
    let note = this.prescriptionFrm.value['Note'];
    let status = this.prescriptionFrm.value['Status'];
    let qTime = this.prescriptionFrm.value['QTime'];
    let nightTime = this.prescriptionFrm.value['NightTime'];
    let eveningTime = this.prescriptionFrm.value['EveningTime'];
    let afternoonTime = this.prescriptionFrm.value['AfternoonTime'];
    let morningTime = this.prescriptionFrm.value['MorningTime'];
    let DoctorId = this.prescriptionFrm.value['DoctorId'];
    let doctorSignature = this.prescriptionFrm.value['DoctorSignature'];
    let drugName = this.prescriptionFrm.value['DrugName'];
    let strength = this.prescriptionFrm.value['Strength'];
    let brandName = this.prescriptionFrm.value['BrandName'];
    let quantity = this.prescriptionFrm.value['Quantity'];
    let startDate = this.prescriptionFrm.value['StartDate'];
    let endDate = this.prescriptionFrm.value['EndDate'];
    let refillDuration = this.prescriptionFrm.value['RefillDuration'];
    let numberofrefills = this.prescriptionFrm.value['Numberofrefills'];

    let datePrescribed = this.prescriptionFrm.value['DatePrescribed'];
    datePrescribed = moment(datePrescribed).format('MM/DD/YYYY');

    let instruction = this.prescriptionFrm.value['Instruction'];

    let doctorId = this.prescriptionFrm.value['DoctorId'];
    let idoctorName = this.doctorList.filter((it) => it.DoctorId == doctorId)[0]
      .DoctorName;

    let body = {
      DrugName: drugName,
      BrandName: brandName,
      Quantity: quantity,
      Strength: strength,
      DatePrescribed: datePrescribed,
      Instruction: instruction,
      Note: note,
      DoctorName: idoctorName,
      DoctorId: this.doctorId,
      DoctorSignature: doctorSignature,
      MorningTime: morningTime,
      AfternoonTime: afternoonTime,
      EveningTime: eveningTime,
      NightTime: nightTime,
      QTime: qTime,
      Status: status,
      StartDate: startDate,
      EndDate: endDate,
      RefillDuration: refillDuration,
      Numberofrefills: numberofrefills,
      PatientId: patientId,
    };
    this.prescriptionService.registerPrescription(body).subscribe((res) => {
      // this.isSuccess = true;
      this.emailService
        .sendEmail({
          Email: this.patient.Email,
          Subject: 'Refill Reuqest Approved',
          Template: `<h3>Your refill request has been approved </h3> `,
        })
        .subscribe();

      const smsBodyDetails =
        'Message to ' +
        this.patient.PatientFirstName +
        this.patient.PatientLastName +
        ' Your refill request has been approved ';
      let smsBody = {
        PhoneNumber: this.patient.PhoneNumber,
        Body: smsBodyDetails,
      };
      this.emailService.sendSms(smsBody).subscribe();
      this.isSuccess = true;

      setTimeout(() => {}, 2000);
      // this.prescriptionFrm.reset();

      this.router.navigate(['/doctorDash/']);
    });
  }
}
