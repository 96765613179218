<div class="container mt-5 ">

    <div class="col-lg regFrm mat-elevation-z8">

        <form [formGroup]="addVisitFrm" (ngSubmit)="formSubmit()" class="example-form">
            <div class="row mt-5">
                <div class="col-lg-6">
                    <mat-form-field class="example-full-width">
                        <mat-label>Doctor Name</mat-label>
                        <!-- <input type="text" formControlName="DoctorName" matInput >
                  <mat-error >Please enter Doctor Name</mat-error> -->

                        <mat-select formControlName="DoctorId" (selectionChange)="getTimeSlots()">
                            <mat-option *ngFor="let doctor of doctorList" [value]="doctor.DoctorId">{{ doctor.DoctorName
                                }}</mat-option>
                        </mat-select>
                        <mat-error>Select a Doctor</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-lg-6">

                    <p class="text-primary">Please select a doctor to view Off Days</p>
                    <mat-form-field class="example-full-width" appearance="fill"
                        [ngClass]="datePickerDisabled ? 'strict-disabled': '' ">
                        <mat-label>Select to View </mat-label>
                        <input matInput formControlName="VisitDate" [matDatepicker]="picker"
                            [disabled]="!UnAvailableDates.length" [matDatepickerFilter]="boundDateStart" />
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                        <mat-error>Select a Date </mat-error>

                    </mat-form-field>
                </div>



            </div>




            <!-- </div> -->