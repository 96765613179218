import { Component, OnInit } from '@angular/core';
import { RadiologyReportService } from '../../services/radiology-report.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Doctor } from 'src/app/Models/doctor.model';
import { DoctorService } from 'src/app/services/doctor.service';
import { environment } from 'src/environments/environment';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Patient } from 'src/app/Models/patient.model';
import { PatientService } from 'src/app/services/patient.service';

@Component({
  selector: 'app-add-radiology-report',
  templateUrl: './add-radiology-report.component.html',
  styleUrls: ['./add-radiology-report.component.css'],
})
export class AddRadiologyReportComponent implements OnInit {
  id = 0;
  imageUrl: string;
  doctorList: Doctor[];
  isSuccess = false;
  patient: Patient;

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private radiologyReportService: RadiologyReportService,
    private router: Router,
    private doctorService: DoctorService,
    private patientService: PatientService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    this.getPatient(this.id);

    this.getDoctors();
  }

  labFrm = this.fb.group({
    ReportType: ['', Validators.required],
    Document: [''],
    Description: [''],
    Date: ['', Validators.required],
    OrderedBy: ['', Validators.required],
    LabName: ['', Validators.required],
    ReportedBy: ['', Validators.required],
    VerifiedBy: [''],
    Examination: [''],
    Technique: [''],
    Comparison: [''],
    Impression: [''],
    Indication: [''],
  });

  get VerifiedBy() {
    return this.labFrm.get('VerifiedBy');
  }
  get Indication() {
    return this.labFrm.get('Indication');
  }
  get Examination() {
    return this.labFrm.get('Examination');
  }
  get Technique() {
    return this.labFrm.get('Technique');
  }
  get Comparison() {
    return this.labFrm.get('Comparison');
  }
  get Impression() {
    return this.labFrm.get('Impression');
  }
  get ReportType() {
    return this.labFrm.get('ReportType');
  }

  get Document() {
    return this.labFrm.get('Document');
  }
  get Description() {
    return this.labFrm.get('Description');
  }
  get Date() {
    return this.labFrm.get('Date');
  }
  get OrderedBy() {
    return this.labFrm.get('OrderedBy');
  }
  get LabName() {
    return this.labFrm.get('LabName');
  }
  get ReportedBy() {
    return this.labFrm.get('ReportedBy');
  }

  image(e) {
    let formData = new FormData();
    let files = e.target.files;
    formData.append('file', files[0], files[0].name);

    this.radiologyReportService.imageUpload(formData).subscribe(
      (res) => {
        let stImg = environment.imgurl + res['path'];
        // this.imageUrl = stImg
        this.imageUrl = `<p><img src="${stImg}"/></p>`;
      },
      (err) => console.log(err)
    );
  }

  getDoctors() {
    this.doctorService.getDoctors().subscribe((res) => {
      this.doctorList = res;
    });
  }
  getPatient(id) {
    this.patientService.getPatientById(id).subscribe((res) => {
      this.patient = res;
    });
  }
  formSubmit() {
    let indication = this.labFrm.value['Indication'];
    let verifiedBy = this.labFrm.value['VerifiedBy'];
    let examination = this.labFrm.value['Examination'];
    let technique = this.labFrm.value['Technique'];
    let comparison = this.labFrm.value['Comparison'];
    let impression = this.labFrm.value['Impression'];
    let reportType = this.labFrm.value['ReportType'];
    let document = this.labFrm.value['Document'];
    let description = this.labFrm.value['Description'];
    let date = this.labFrm.value['Date'];
    date = moment(date).format('MM/DD/YYYY');
    let orderedBy = this.labFrm.value['OrderedBy'];
    let labName = this.labFrm.value['LabName'];
    let reportedBy = this.labFrm.value['ReportedBy'];

    let body = {
      PatientId: this.id,
      ReportType: reportType,
      Document: this.imageUrl,
      Description: description,
      Date: date,
      OrderedBy: orderedBy,
      LabName: labName,
      ReportedBy: reportedBy,
      VerifiedBy: verifiedBy,
      Examination: examination,
      Technique: technique,
      Comparison: comparison,
      Impression: impression,
      Indication: indication,
    };
    this.radiologyReportService
      .registerRadiologyReport(body)
      .subscribe((res) => {
        this.isSuccess = true;
        this.labFrm.reset();
      });
  }

  tabChanged(event: MatTabChangeEvent): void {
    if (event.index === 0) {
    }
  }
}
