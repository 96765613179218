<div class="container">
    <!-- style="width: 18rem;" -->
    <div class="row">
        <div class="col-lg-4">
            <div class="card" style="width: 18rem;">
                <ul class="list-group list-group-flush">
                    <img src="{{doctor.DoctorImage}}" class="doctor-image" alt="">
                    <li class="list-group-item divBg text-white"> <span
                            style="font-size: large;">{{doctor.DoctorName}}</span></li>
                    <li class="list-group-item">NPI Number: {{doctor.NpiNumber}}</li>
                    <li class="list-group-item">Licence Number: {{doctor.LicenceNumber}}</li>
                    <li class="list-group-item">Qulification: {{doctor.DoctorQualification}}</li>
                    <li class="list-group-item">Specility: {{doctor.DoctorSpecialization}}</li>
                    <li class="list-group-item">Doctor DOB: {{doctor.DoctorDOB}}</li>
                    <li class="list-group-item">Phone: {{doctor.PhoneNumber}}</li>
                    <li class="list-group-item">Email: {{doctor.Email}}</li>
                    <li class="list-group-item">StartDate: {{doctor.StartDate}}</li>
                    <li class="list-group-item">Status: {{doctor.Status? 'Active' : 'Block'}}</li>
                    <li class="list-group-item">Doctor Address: {{doctor.DoctorAddress}}</li>
                </ul>
            </div>
            <button type="button" [routerLink]="['/updateDoctorProfile/',doctor.DoctorId]" mat-mini-fab color="primary">
                <mat-icon matPrefix>edit</mat-icon>
            </button>
        </div>
    </div>
    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>

</div>